import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import YearSlider from './year-slider';
import { AboveTitle, FlexWrapper, FlexWrapperStart, SmallTitle, Span, Text, Title, Wrapper } from '../../../global/new-global-components';
import { CoverageSectionWrapper, TextWrapper } from './coverage.styles';
import { gsap } from 'gsap';
import { YearSliderWrapper } from './year-slider.styles';
import EuropeMapSvg from './europeMapSvg';

export const CoverageSection = (props: any) => {
  const { customTitle, customSubTitle } = props;
  const { t } = useTranslation();
  const tl = gsap.timeline();
  const [firstCount, setFirstCount] = useState(0);
  const [secondCount, setSecondCount] = useState(0);
  const [thirdCount, setThirdCount] = useState(0);
  const [fourthCount, setFourthCount] = useState(0);
  const counter1 = useRef<any>(null);
  const counter2 = useRef<any>(null);
  const counter3 = useRef<any>(null);
  const counter4 = useRef<any>(null);
  const startFirstCount = 0;
  const startSecondCount = 0;
  const startThirdCount = 0;
  const startFourthCount = 0,
    num = { first: startFirstCount, second: startSecondCount, third: startThirdCount, fourth: startFourthCount };

  useEffect(() => {
    const changeFirstNumber = () => {
      if (counter1.current) {
        counter1.current.innerHTML = num.first.toFixed();
      }
    };
    const changeSecondNumber = () => {
      if (counter2.current) {
        counter2.current.innerHTML = num.second.toFixed();
      }
    };
    const changeThirdNumber = () => {
      if (counter3.current) {
        counter3.current.innerHTML = num.third.toFixed();
      }
    };
    const changeFourthNumber = () => {
      if (counter4.current) {
        counter4.current.innerHTML = num.fourth.toFixed();
      }
    };
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '#coverage-section',
          start: 'top top',
          end: '+=200',
          scrub: false,
        },
      })
      .to(num, {
        first: 1500,
        duration: 1,
        ease: 'none',
        onUpdate: changeFirstNumber,
      })
      .to(num, {
        second: 9570,
        duration: 1,
        ease: 'none',
        onUpdate: changeSecondNumber,
      })
      .to(num, {
        third: 100,
        duration: 1,
        ease: 'none',
        onUpdate: changeThirdNumber,
      })
      .to(num, {
        fourth: 200,
        duration: 1,
        ease: 'none',
        onUpdate: changeFourthNumber,
      });
  }, []);

  return (
    <CoverageSectionWrapper>
      <FlexWrapperStart id="coverage-section" backgroundColor="#f4f4f4" position="relative" className="coverage-section section">
        <TextWrapper className="coverage-section-text-wrapper">
          <AboveTitle textAlign="left">{t('home_TimelineSectionSubHeading')}</AboveTitle>
          <Title textAlign="left">
            {customTitle ? (
              <Trans i18nKey={customTitle}>
                part 1<span className="break-on-md-and-lg">part 2</span>
              </Trans>
            ) : (
              <Trans i18nKey={'home_TimelineSectionHeading'}>
                part 1<span className="break-on-md-and-lg">part 2</span>
              </Trans>
            )}
          </Title>
          <SmallTitle fontSize="20px" fontWeight="400" marginTop="10px" textAlign="left">
            {customSubTitle ? t(customSubTitle) : t('home_TimelineSectionSubHeadingExtra')}
          </SmallTitle>
          <Wrapper className="coverage-section-numbers" marginTop="130px" width="412px">
            <FlexWrapper className="number-div" style={{ borderBottom: '1px solid rgba(0,0,0,.1)' }}>
              <Text className="number-inner-text" id="stat1" fontSize="56px" fontWeight="800">
                <Span ref={counter1} id="result1" className="counter">
                  {firstCount}
                </Span>
                +
              </Text>
              <Text className="counter-description" maxWidth="180px" fontWeight="500" color="1e1e23" fontSize="18px" opacity="0.6">
                {t('home_TimelineSectionPartnersCounter')}
              </Text>
            </FlexWrapper>
            <FlexWrapper className="number-div" style={{ borderBottom: '1px solid rgba(0,0,0,.1)' }}>
              <Text className="number-inner-text" id="stat2" fontSize="56px" fontWeight="800">
                <Span ref={counter2} id="result2" className="counter">
                  {secondCount}
                </Span>
                +
              </Text>
              <Text className="counter-description" maxWidth="180px" fontWeight="500" color="1e1e23" fontSize="18px" opacity="0.6">
                {t('home_TimelineSectionAssistanceCounter')}
              </Text>
            </FlexWrapper>
            <FlexWrapper className="number-div" style={{ borderBottom: '1px solid rgba(0,0,0,.1)' }}>
              <Text className="number-inner-text" id="stat3" fontSize="56px" fontWeight="800">
                <Span ref={counter3} id="result3" className="counter">
                  {thirdCount}
                </Span>
                k+
              </Text>
              <Text className="counter-description" maxWidth="180px" fontWeight="500" color="1e1e23" fontSize="18px" opacity="0.6">
                {t('home_TimelineSectionRegisteredUsersCounter')}
              </Text>
            </FlexWrapper>
            <FlexWrapper className="number-div">
              <Text className="number-inner-text" fontSize="56px" fontWeight="800">
                <Span ref={counter4} id="result4" className="counter">
                  {fourthCount}
                </Span>
                k+
              </Text>
              <Text className="counter-description" maxWidth="180px" fontWeight="500" color="1e1e23" fontSize="18px" opacity="0.6">
                {t('home_TimelineSectionInstalledAppCounter')}
              </Text>
            </FlexWrapper>
          </Wrapper>
        </TextWrapper>

        <Wrapper className="europe-img">
          <EuropeMapSvg />
        </Wrapper>

        <YearSliderWrapper id="year-slider-wrapper">
          <YearSlider />
        </YearSliderWrapper>
      </FlexWrapperStart>
    </CoverageSectionWrapper>
  );
};
