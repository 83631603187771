import styled from '@emotion/styled';

export const Slide = styled.div`
  width: 150px;
`;

export const YearSliderWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  .point {
    padding: 15px !important;
    height: auto !important;
    width: 412px;
    padding-bottom: 25px !important;
    position: absolute;
    background-color: white !important;
    border-radius: 20px !important;
    box-shadow: 0px 32px 43px rgba(30, 30, 35, 0.1) !important;
    z-index: 9;
    bottom: -55px;

    @media screen and (min-width: 1600px) {
      width: 480px;
    }

    @media screen and (min-width: 1800px) {
      width: 480px;
    }
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    .point {
      width: 412px !important;
    }

    .first-year-slider {
      padding-left: 160px !important;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    .first-year-slider {
      padding-left: 50px !important;
    }
    .second-year-slider {
      padding-left: 260px !important;
    }
  }

  .point-text {
    font-size: 12px;
  }

  .point:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: #000000 transparent;
    border-width: 20px 20px 0;
    bottom: -10px;
    left: 50%;
    margin-left: -20px;
  }

  .point * {
    color: black;
  }

  .ruler-btn {
    background-color: white;
    width: 92px;
    height: 92px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: absolute;
    right: 40%;
    top: -56px;
    box-shadow: 0px 10px 50px -5px rgb(5 3 32 / 20%);
  }

  .ruler-line {
    margin-top: -30px !important;
  }

  .line-text {
    font-family: 'Anek Latin' !important;
    opacity: 0.4 !important;
    color: #1e1e23 !important;
    font-size: 20px !important;
    top: -35px !important;
    margin-left: -20px !important;
  }

  .ruler-btn .inner {
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .react-ruler-wrapper .ruler-list {
    border: none !important;
  }

  .react-ruler-wrapper .ruler-list .rule-mark .line {
    border-left: 1px solid #1e1e23;
    opacity: 0.4;
    height: 50px;
  }

  .react-ruler-wrapper .ruler-list .line {
    height: 15px;
    opacity: 0.4;
    border-left: 1px solid #1e1e23;
  }

  .ruler-btn button {
    width: 30px;
    height: 92px;
    background-color: white;
    border: none;
    z-index: 99999;
  }

  .point::after {
    border-color: white transparent transparent transparent !important;
  }

  .slide-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #1e1e23;
    border-radius: 30px;
    padding: 10px;
    margin-bottom: 20px;
  }

  .slide-wrapper .img {
    height: 24px;
    width: 24px;
  }

  .slide-wrapper p {
    color: white;
    margin-left: 10px;
  }

  .ruler-line {
    opacity: 0.4;
    background-color: #1e1e23 !important;
  }

  .second-year-slider {
    padding-left: 370px;
    padding-top: 40px;
    background-color: #ebebeb;
    position: relative;
  }

  .second-year-slider .slick-list {
    overflow: visible;
  }

  .second-year-slider .slick-track {
    display: flex;
  }

  .first-year-slider p,
  .second-year-slider p {
    color: #1e1e23;
    opacity: 0.4;
  }

  .point-title {
    color: white !important;
  }

  .slick-current p {
    opacity: 1;
  }

  .first-year-slider {
    padding-left: 120px;
  }

  .first-year-slider .slick-list {
    padding: 42px 5px;
    overflow: initial;
    position: relative;
    z-index: 10;
  }

  .first-year-slider .slick-track {
    display: flex;
  }

  .big-number {
    font-style: normal;
    font-weight: 800 !important;
    font-size: 64px !important;
    line-height: 130% !important;
    color: #1e1e23 !important;
  }

  .regular-text {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.03em;
    color: #1e1e23;
    opacity: 0.6;
    max-width: 194px;
  }

  .coverage-list {
    margin-top: 130px;
    width: 412px;
  }

  .slick-slide img {
    outline: none !important;
  }

  .slick-slide * {
    outline: none !important;
  }

  .svg {
    width: 100%;
    height: 99%;
    margin: 0;
    padding: 0;
    transition: transform 0.5s;
  }

  #europe-svg path {
    transition: fill 0.4s ease;
  }

  .map {
    margin: 0;
    padding: 0;
    transition: transform 0.5s;
  }

  .btn-ruler {
    display: none;
    height: 180px;
    width: 180px;
    position: fixed;
    top: 0;
    opacity: 1;
    z-index: 999;
    pointer-events: none;
    left: 0;
  }

  .year-slide-inner {
    left: -60px;
    width: 120px;
    position: relative;
    text-align: center;
    font-size: 22px;
    font-weight: 800;
  }
  .year-slide-wrapper {
    img {
      width: 100%;
      outline: none;
    }
  }

  @media screen and (max-width: 370px) {
    .year-slide-inner {
      font-size: 16px;
    }
    .point-title,
    .point > h4 {
      font-size: 10px !important;
    }
  }

  @media screen and (max-width: 768px) {
    position: relative;

    .second-year-slider {
      padding-top: 10px;
      padding-left: 0;
      .btn-ruler {
        display: none !important;
      }
    }
    .first-year-slider {
      padding-left: 0;
    }

    .point {
      max-width: 100%;
      width: auto;
      margin-bottom: 40px;
    }

    .point p {
      font-size: 12px;
    }

    .point-title,
    .point > h4 {
      font-size: 14px;
    }
    .first-year-slider .slick-list {
      padding: 0px;
    }

    .first-year-slider .slick-slide {
      padding: 20px;
    }
    .coverage-section .ruler-btn {
      display: none;
    }
  }

  @media screen and (min-width: 1400px) {
    .first-year-slider {
      padding-left: 50px;
    }
    .second-year-slider {
      padding-left: 296px;
    }
  }

  @media screen and (min-width: 1500px) {
    .first-year-slider {
      padding-left: 150px;
    }
    .second-year-slider {
      padding-left: 506px;
    }
  }

  @media screen and (min-width: 1600px) {
    .first-year-slider {
      padding-left: 200px;
    }
    .second-year-slider {
      padding-left: 446px;
    }
  }

  @media screen and (min-width: 2000px) {
    .first-year-slider {
      padding-left: 200px;
    }
    .point p {
      font-size: 16px;
    }
    .point-title,
    .point > h4 {
      font-size: 18px;
    }
    .second-year-slider {
      padding-left: 445px;
    }
  }

  @media screen and (min-width: 2200px) {
    .second-year-slider {
      padding-left: 605px;
    }

    .year-slide-inner {
      font-size: 28px;
    }

    .point p {
      font-size: 24px;
    }
    .point {
      width: 800px;
      padding: 40px !important;
    }
    .point-title,
    .point > h4 {
      font-size: 32px;
    }
    .point-title {
      font-size: 26px;
    }
    .btn-ruler {
      height: 250px;
      width: 250px;
    }
  }
`;
