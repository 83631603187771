import React from 'react';
import { Modal } from '../Modal/Modal.component';
import { css } from '@emotion/core';
import { COUNTRY } from '../../../configs/env';

const video = css`
  @media screen and (max-width: 320px) {
    height: 170px;
  }
  @media screen and (min-width: 350px) and (max-width: 520px) {
    height: 200px;
  }

  @media screen and (min-width: 520px) and (max-width: 832px) {
    height: 300px;
  }

  @media screen and (min-width: 832px) {
    height: 641px;
  }
`;

const modal = css`
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-top: 10%;

  @media screen and (max-width: 868px) {
    margin-top: 50%;
  }
`;

export const VideoModal = ({ show, onHide, videoSrc, subtitleSrc }: any) => (
  <Modal show={show} onHide={onHide}>
    <div css={modal}>
      <video controls autoPlay css={video}>
        <source src={videoSrc} type="video/mp4" />
        {COUNTRY === 'bg' ? (
          <track label="Bulgarian" kind="subtitles" srcLang="bg" src={subtitleSrc} id="bulgarianTitle" default></track>
        ) : null}
        {COUNTRY === 'ro' ? (
          <track label="Romanian" kind="subtitles" srcLang="ro" src={subtitleSrc} id="romanianTitle" default></track>
        ) : null}
        {COUNTRY === 'mk' ? (
          <track label="Macedonian" kind="subtitles" srcLang="mk" src={subtitleSrc} id="macedonianTitle" default></track>
        ) : null}
        {COUNTRY === 'si' ? (
          <track label="Slovenian" kind="subtitles" srcLang="si" src={subtitleSrc} id="slovenianTitle" default></track>
        ) : null}
        {COUNTRY === 'rs' ? (
          <track label="Serbian" kind="subtitles" srcLang="rs" src={subtitleSrc} id="serbianTitle" default></track>
        ) : null}
        {COUNTRY === 'eu' ? (
          <track label="English" kind="subtitles" srcLang="eu" src={subtitleSrc} id="englishTitle" default></track>
        ) : null}
        {COUNTRY === 'me' ? (
          <track label="Montenegro" kind="subtitles" srcLang="me" src={subtitleSrc} id="montenegroTitle" default></track>
        ) : null}
        {COUNTRY === 'ba' ? (
          <track label="Bosnian" kind="subtitles" srcLang="ba" src={subtitleSrc} id="bosnianTitle" default></track>
        ) : null}
      </video>
    </div>
  </Modal>
);
