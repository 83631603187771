import styled from '@emotion/styled';

export const CoverageSectionWrapper = styled.div`
  .coverage-section {
    .coverage-section-numbers {
      margin-bottom: 80px;
      @media screen and (min-width: 1600px) {
        margin-top: 60px;
      }
    }

    .europe-img {
      padding: 200px 0px;
      width: 50%;
    }

    .europe-img svg {
      width: 100%;
    }

    .counter-description {
      min-width: 180px;
    }
  }

  @media screen and (max-width: 768px) {
    .coverage-section {
      flex-direction: row;
      padding: 0px;

      h2 {
        margin-top: 10px;
      }

      h3 {
        margin-top: 10px;
        font-size: 20px;
      }

      .coverage-section-numbers {
        margin: 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .number-div {
          width: 48%;
          flex: 0 0 48%;
          flex-direction: row;
          padding: 20px 0px;

          &:last-child {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }
        }

        .counter {
          font-size: 20px;
        }

        .counter-description {
          max-width: 95px;
          font-size: 12px;
        }
      }

      .number-inner-text {
        font-size: 20px;
      }

      .europe-img {
        overflow: hidden;
        width: 90%;
        height: auto;
        margin: 0 auto;
        padding: 0px;
        padding-bottom: 150px;
        position: relative;
        left: 0;
        right: 0;
        float: none;

        svg {
          width: 100%;
          height: 100%;
          border-radius: 20px;
        }
      }
    }
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    .coverage-section-numbers {
      width: 340px !important;

      .number-inner-text {
        font-size: 42px !important;
      }
    }
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    .coverage-section-text-wrapper {
      padding: 60px 0px 100px 50px;
    }

    .counter,
    .number-inner-text {
      font-size: 30px !important;
    }
  }

  @media screen and (min-width: 900px) and (max-width: 1199px) {
    .number-inner-text {
      font-size: 36px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1199px) {
    .coverage-section {
      .europe-img {
        padding: 30px 0 130px 0 !important;
      }
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    .coverage-section {
      .europe-img {
        padding: 150px 0;
      }

      .coverage-section-numbers {
        margin-top: 30px;
        margin-bottom: 160px;
      }
    }

    .coverage-section-text-wrapper {
      padding: 100px 0px 100px 50px;
    }
  }

  @media screen and (min-width: 1400px) {
    .coverage-section-text-wrapper {
      padding: 100px 0px 200px 100px;
    }
  }

  @media screen and (min-width: 1500px) {
    .coverage-section-text-wrapper {
      padding: 120px 0px 200px 150px;
    }
  }

  @media screen and (min-width: 1600px) {
    .coverage-section-text-wrapper {
      padding: 200px 0px 200px 200px;
    }

    .europe-img svg {
      transform: scale(1.2);
    }
  }

  @media screen and (min-width: 2000px) {
    .coverage-section h2 {
      max-width: 900px;
    }

    .coverage-section-numbers {
      margin-top: 30px;
      margin-bottom: 0px;
    }

    .coverage-section-text-wrapper {
      padding: 0px 0px 360px 200px;
    }

    .europe-img svg {
      height: 900px;
      margin-bottom: 100px;
    }
  }

  @media screen and (min-width: 2200px) {
    .number-inner-text {
      font-size: 64px;
    }

    .counter-description {
      font-size: 24px;
    }
  }

  .coverage-section {
    position: relative;
    flex-wrap: wrap;
  }
`;

export const TextWrapper = styled.div`
  width: 50%;
  /* position: absolute;
  left: 200px;
  top: 0; */
  padding: 200px 0px 200px 220px;

  @media screen and (max-width: 768px) {
    position: relative;
    width: 100%;
    left: 0;
    padding: 40px 20px;
  }
`;
