import styled from '@emotion/styled';
import css from '@emotion/css';

export const WriteToUsSectionWrapper = styled.div<any>`
  max-width: ${(props: any) => props.maxWidth};
  margin: 0 auto;

  .write-to-us-text {
    max-width: 800px;
    font-weight: 500;
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    button {
      height: 50px;
      font-size: 16px;
      border-radius: 15px;
      margin-bottom: 0px;
      margin-top: 20px;
    }

    .write-to-us-text {
      margin-bottom: 20px;
    }
  }

  @media screen and (min-width: 2000px) {
    .write-to-us-section {
      margin-top: 60px;
    }

    .write-to-us-text {
      font-size: 24px;
      width: 100%;
    }

    .write-to-us-text {
      br {
        display: none;
      }
    }
  }

  @media screen and (min-width: 2200px) {
    .write-to-us-section {
      h2 {
        font-size: 44px !important;
        max-width: initial !important;
      }

      .write-to-us-text {
        font-size: 28px !important;
        max-width: initial !important;
      }
    }
  }
`;

export const WriteToUsWrapper = styled.div<any>`
  position: relative;
  padding: 40px 30px;
  background-color: #e9e9eb;
  background: ${(props: any) => `url(${props.backgroundImage}) no-repeat`};
  background-size: cover;
  overflow: hidden;
  width: 1033px;
  height: 194px;
  align-items: center;
  border-radius: 20px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 769px) {
    max-width: 100% !important;
    h2 {
      text-align: left;
      max-width: 700px;
    }
    .write-to-us-text {
      max-width: 700px;
    }
  }

  @media screen and (min-width: 1200px) {
    width: 100%;
    h2 {
      text-align: left;
      font-size: 24px !important;
      margin-bottom: 0px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    display: block;
    background: ${(props: any) => `url(${props.backgroundMobileImage}) no-repeat`};
    background-size: cover;
    button {
      height: 50px;
      font-size: 16px;
      border-radius: 15px;
      margin-bottom: 0px;
      margin-top: 20px;
    }
    h2 {
      text-align: left;
      font-size: 16px !important;
      margin-bottom: 0px;
    }
    p {
      opacity: 0.8;
      font-size: 14px;
    }
  }

  @media screen and (min-width: 2000px) {
    width: 100%;
  }
`;
