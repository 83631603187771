import { Img, Text, Title, VozziButton, Wrapper } from '../../../global/new-global-components';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { WriteToUsSectionWrapper, WriteToUsWrapper } from './writeToUsSection.styles';
import { navigateTo } from '../../../components/functional/Navigate';
import FaqSendQuestionForm from '../../../components/layout/faqSendQuestionModal/FaqSendQuestionForm.component';

export const WriteToUsSection = (props: any) => {
  const {
    customTitle,
    customSubTitle,
    disableFAQButton = false,
    maxWidth = 'auto',
    backgroundImage = '/new-images/write-to-us-background.png',
    backgroundMobileImage = '/new-images/write-to-us-mobile.png',
  } = props;
  const { t } = useTranslation();
  const [showPopupForm, setShowPopupForm] = useState(false);

  return (
    <WriteToUsSectionWrapper maxWidth={maxWidth}>
      <Wrapper className="write-to-us-section section">
        <WriteToUsWrapper backgroundImage={backgroundImage} backgroundMobileImage={backgroundMobileImage}>
          <Wrapper>
            <Title color="white" fontSize="24px">
              {customTitle ? customTitle : t('home_WriteToUsSectionHeading')}
            </Title>
            <Text className="write-to-us-text" marginTop="10px" color="white" opacity="0.8" fontSize="18px">
              {customSubTitle ? (
                t(customSubTitle)
              ) : (
                <Trans i18nKey={'home_WriteToUsSectionSubheading'}>
                  part 1<span className="break-on-md-and-lg">part 2</span>
                </Trans>
              )}
            </Text>
          </Wrapper>
          <VozziButton onClick={() => setShowPopupForm(true)} className="mobile-left">
            <Img src="/icons/write.svg" /> {t('home_WriteToUsCTAButton')}
          </VozziButton>
        </WriteToUsWrapper>
        {!disableFAQButton && (
          <VozziButton
            onClick={() => {
              navigateTo(t('link_FAQ'));
            }}
            margin="50px auto"
            marginBottom="0"
          >
            <Img src="/icons/arrow-right.svg" /> {t('home_FaqMoreQuestions')}
          </VozziButton>
        )}
        <FaqSendQuestionForm show={showPopupForm} onHide={() => setShowPopupForm(false)} />
        {/* <TestimonialFormSection show={showPopupForm} onHide={() => setShowPopupForm(false)} /> */}
      </Wrapper>
    </WriteToUsSectionWrapper>
  );
};
