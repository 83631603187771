const EuropeMapSvg = () => {
  return (
    <svg id="europe-svg" width="649" height="769" viewBox="0 0 649 769" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="andorra"
        d="M142.7,650.6l-0.3,0.1l-1.1,0.6l-0.6,0.2l-0.6,0.1l-0.4,0l-0.2-0.4l0-0.6l-0.1-0.5l-0.1-0.3
      l0.2-0.8l0.4-0.4l0.5-0.3l0.8,0.1l1.7,0.5l0.3,0.5l0,0.3l-0.3,0.5L142.7,650.6z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="albania"
        d="M357.8,649.9l0.5,0.4l1,1.6l0.6,1.4l1.3,0.5l0.7,0.5l0.9,0.8l0.4,0.8l0.6,2.5l0.1,1.5l-0.2,0.7
      l-0.1,0.2l-0.6,2.4l0.1,1.2l0,0.8l-0.5,0.3l-0.3,0.5l0.5,2l-0.1,0.9l0,1l0.9,2.2l0.5,0.7l0.5,0.3l0.6,2.1l0.4,0.4l1.5-0.2l0.7,0.2
      l0.3,0.5l0.1,0.3l-0.1,1.1l0.4,0.9l0.5,0.9v0.6l-0.3,0.9l-0.6,1.1l-0.8,0.4l-0.9,0.3l-0.4,0.8l-0.2,0.9l-0.4,0.6l-0.2,0.7l-0.4,1.4
      l-0.1,0.5l-0.6,0.5l-0.9,0.2l-0.8,0l-0.6,0.2l-0.3,0.5l-0.5,0.4l-0.3,0.2v0.4l0.4,0.9l0.4,0.7l0,0.6l-0.2,0.2l-0.7-0.1l-0.1,0.2
      l-0.1,0.7l-0.2,0.6l-0.3,0.3l-0.5,0.4l-0.9-0.1l-0.8-0.6l-0.4-0.2l-0.2,0l-0.1-1.4l-0.4-1.1l-1.3-2.6l-4.3-2.5l-1-1.1l-0.4-1
      l-0.4-0.9l0.4,0l0.4,0.2l0.5,0.3l0.2-0.5l-0.2-1l-1.1-2.3l-0.1-0.6l0.5-1.9l0.9-2.2l-0.1-2.7l0.3-2l-0.3-1.3l-0.1-1.6l0.7-2.1
      l0.6-0.5l0.4-0.7l0-2.3l-1.3-1.1l-1.5-0.2l0-0.8l0.2-1.2l-0.1-0.4l0.1-0.7l-0.4-0.9l-0.6-0.7l0.6-1.2l0.8-1.5l0.8-1.2l0.9-1.2
      l0.6-1.2l0.7-1l0.6-0.3l0.3,0.2l0.2,0.4l0,1.3l0.2,0.4l0.4,0.3l0.8-0.2l0.9-0.3l1.3-0.7L357.8,649.9z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="austria"
        d="M321.4,549.2l0,0.2l-0.1,0.7l-0.5,0.8l-0.5,1.1l0,1l1.3,3.3l1.1,2l0.2,0.8l0.7,0.6l-0.7,0.7
      l-0.1,1.1l-0.4,0.5l-0.1,0.6l0.2,0.6l0,0.7l0.2,1l-1.1,0.2l-1.3,0l-0.5,0.1l-0.4,0.3l-0.4-0.1l-1.2-0.9l-0.7-0.2l-0.5,0.1l-0.4,0.4
      l-0.6,0.5l-0.6,0.4l0.1,0.3l2.4,0.8l0.4,1.3l-0.5,1l-0.2,0.5l-0.6,0.4l-0.7,0.3l-0.8,0.1l-0.1,0.6l0.3,1.6l-0.3,0.3l-0.3,0.5
      l0.2,1.3l0.5,0.1l0.1,0.3l-0.1,0.5l-0.1,0.6l-0.2,0.6l-0.1,0.3l-0.3,0.2l-1.1-0.1l-0.9,0.5l-1.9,1.8l-0.7,0.3l-0.7,0.7l0,1.6
      l-0.1,0.1l-0.2,0.3l-2.2-0.6l-0.1,0l-1.5,0.2l-1,0.7l-1.2,0.4l-2.6-0.2l-2.5,0.3l-0.6,0.2l-0.7,0.1l-0.6,0.4l-0.4,0.6l-0.6,0.8
      l-0.9,0.6l-1,0.5l-0.2,0.4l-0.3,0.2l-0.5-0.3l-0.4,0l-0.5-0.2l-1.8-0.2l-2-0.4l-0.9-0.3l-1.1-0.3l-1.1-0.2l-1,0l-0.5-0.1l-2.5-0.6
      l-1.6,0l-2.1-0.2l-4.3-0.9l-1.2-0.4l-1.2-0.1l-1.4-0.3l-1.1-0.5l-0.7-1l-0.7-1.3l-1.3-1.7l-0.3-0.8l0.4-0.7l0.4-0.6l0-0.2l-0.3-0.1
      l-2.3,0.7l-2.3,0.9l-0.9,0L259,577l-1.2,0l-1.1,0.2l-2.2,0.1l-1.3,0.7l-0.8,1.3l-0.4,1.1l-0.4,0.3l-0.8,0.1l-1.2-0.1l-0.8-0.3
      l-0.8-0.9l-1.3-0.1l-1.2,0l-0.3-0.2l0-0.6l-0.5-1.1l-0.8-0.3l-2,2.1l-0.5,0.2l-1.6-0.6l-1.4-0.9l-0.2-0.7l-0.2-0.5l-1.2-0.5
      l-1.5-0.3H235l0.2-0.3l0.2-0.5l-0.1-0.4l-0.3-0.4l-0.2-0.5l-0.1-0.5l-0.1-0.4l-0.1-0.4l-0.1-0.3l1-2.1l0.2-1.3l-0.8-0.8l-0.4-0.2
      l0.3-0.2l1.2,0.1l0.8-0.4l0.4-0.4l1.1,0.4l1.6,0.8l0.7,0.5l0.3,0.4l0.2,0.4l-0.1,0.6l0.4,0.2l0.7,0.1l0.5,0.2l-0.2,0.8l0,0.7
      l0.7-0.1l0.8-0.5l0.7-0.9l0.4-0.9l0.3-2.1l0.1-0.2l0.5,0.2l2.1-0.1l1,0.4l1.5,0.1l0,0.3l0.3,0.5l0.7,0.8l0.3,0.5l0.7,0.1l1.1-0.3
      l0.6-0.3l0.2,0.2l1-0.2l0.9-0.6l0.2-0.5l0.9-0.3l1.2-0.8l1.7-0.6l5.5-0.6l0.2-0.5l-0.1-1.1l0.1-0.2l0.7,0.3l1.1,0.3l0.9,0.4l0.5,0.5
      l0.5,0l0.8-0.3l1.1-0.2l1,0.5l0.3,0.6l-0.2,0.3l0,0.5l0.3,0.4l0.8,0.6l1,0.5l0.5,0l0.2-0.5l0.2-1.2l0.1-1.3l-0.2-0.8l-0.6-0.2
      l-0.7-0.1l-0.4-0.2l0.1-0.4l0.5-1.1l0-1.4l-1.2-1.6l-1.1-1.6l0-0.5l0.6-0.9l1-0.7l2.2-1.2l0.7-0.3l0.9-0.2l1.3-0.5l0.6-0.5l0.4-0.6
      l0.6-3l0.1-0.1l0.2-0.2l2.2,1l0.2-0.2l0.4-0.2l0.7-0.8l0.2-0.6l0-1.1l0.1-1.1l0.1-0.3l0.3,0.1l1,0.6l0.8,0.6l0.7,1.6l1.6,0.4l2.1,0
      l0.7-0.7l0.7-0.2l0.8,0.2l1.6,0.2l0.2-1.3l0.9-1.3l0.4-0.5l1.2,0l0.3-1l0.3-2.8l0.2-0.3l0.9,0.1l0.9,0.5l0.3,0.4l0.4,0l0.6-0.3
      l0.7-0.2l1.1,0.3l2.3,1.3l1.2,0.5l0.8-0.1l0.7,0l2.7,1.9l1.9,0.3l1.7,0l0.6-0.6l0.7-0.5l0.8,0.1l0.7,0.3l1.3,0.8l0.6,0.2l0.8,0.1
      l0.6,0.2l0.5,1.5L321.4,549.2z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        d="M357,311.8l0.4,0l0.2-0.2l0.6,0.1l0.9,0.9l0.2,0.5l0.6,0.3l0.2,0.5l-0.8,1.6l-0.5,0l-0.3-0.2l-0.6,0.2l-0.4,0.3
      l-0.1,0.7l0,1.4l-2.7,0.3l-0.6-0.4l-0.9-3.1l0.2-0.8l0.6-0.3l0.5-0.1l0.1,1.7l0.7-0.2l0.2-1.1l0-0.8l-0.2-0.4l-0.5-0.3l-0.3-0.5
      l0.4-0.9l0.8-0.4l0.7,1.1L357,311.8z M353.1,315.6l0.1,0.5l-0.4-0.1l-0.3,0.2l-0.2,0.6l-0.5-0.2l-0.2-0.9l0.4-1.4l0.9-0.1
      L353.1,315.6z M364.3,319l-0.1,0.6l-1,0.1l-0.4-0.5l-0.9,0.1l-0.2-0.2l0.4-0.5l0.7-0.3l0.9,0.1L364.3,319z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="bosnia-and-herzegovina"
        d="M321,605.8l2.4,1.7l1,0.3l0.6-0.2l0.8,0.1l1.7,0.5l0.4,0.2l0.5,0l1.3-0.7l0.4,0.1
      l1.4,1.3l0.7,0l0.9-0.6l0.6-0.5l1.7,0.4l0.9-0.2l0.8,0l0.9,0.2l0.8,0.3l0.8,0.3l2,0.1l1,0.8l0.4,0.8l0,0.5l0.1,0.5l0.6,0.5l1.2,0.3
      l0.8-0.1l0.4,0l1-0.5l1.2-0.2l0.9,0.3l0.4,0.3l0.1,0.4l-0.3,1.3l-0.5,1.4l-0.8,1.4l-0.8,1.3l-0.2,0.7l-0.1,1.1l-0.1,0.9l0.1,0.5
      l0.3,0.5l0.9,0.4l1.3,0.9l1.1,1.2l1.4,1.3l0.4,0.5v0.5l-0.4,0.4l-1.2,0.1l-1.2-0.1l-0.5-0.1l-0.4,0.2l-0.3,0.3l0.1,0.4l1.2,1.6
      l1.5,2.3l0.1,1l-0.2,0.8l-0.3,0.5l-0.6-0.1l-0.5-0.4l-0.7,0l-0.5,0.1l-0.7,0.8l-0.4,0l-0.6,0.1l-0.4,0.2l-0.6-0.2l-0.6-0.2l-0.3,0.3
      l-0.1,0.5l0.4,0.9l0.7,1.4l-0.1,1l-0.6,0.1l-0.5-0.9l-0.5-0.1l-0.5,0l-1.2,1l-0.9,0.8l-0.2,0.6l-0.3,0.6l-0.1,0.5l0,1.5l-1.6,0.2
      l-0.3,0.2l-0.2,0.5l0.1,2l0.1,1.1l0.9,1.6l0,0.5l-0.1,0.3l-0.6,0.6l-0.3,0.2l-0.2,0.1l-1.1-0.4l-0.5-0.2l-2.1-1.4l-0.9-0.8l-1.5-1
      l-0.9-0.6l-0.5-0.9l-0.7-0.2l-0.9,0.3l-1-0.7l0.7-0.3l0.2-0.3l-0.1-0.4l-0.3-0.6l-2.6-2.5l-1.3-1.7l-0.2-0.6l0-1.6l-0.3-0.4
      l-1.9-0.7l-2.1-2.1l-2.2-2.1l-0.3-0.6l-1.1-1.6l-1.4-1.4l-1.1-0.9l-0.9-1l-1-1.5l-0.5-2.2l-0.5-2l-0.3-0.8l-0.6-0.3l-2-2.4l-1.7-1.4
      l0-1.5l0.3-2.5l0.3-2.8l0.4-0.4l0.8-0.2l0.9,0.1l0.8,0.4l1.5,1.9l0.9,0.8l0.7,0.3l0.8-0.8l1-1.7l0.9-0.9l3,0.3L321,605.8z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="belgium"
        d="M179.2,497.9l0.1,0.4l0.3,0.2l1.1-0.1l0.6-0.7l0.4-0.4l0.3,0.3l0.2,0.9l0.3,1.1l1.3,1.3l1.1,0.4
      l1.4-0.2l0.6-0.2l0.4,0.2l0.4,0.7l0.8,0.8l1.7,0.5l0.5,0.3l0.4,0.5l-0.1,0.7l-0.8,1.8l-0.1,0.5l0.1,0.2l-0.2,0.3l-1,1.2l-0.1,0.4
      l0.3,0.7l0.3,0.6l0-0.1l1.6-0.5l0.7,1l1.2,0l0.1,0.3l1.3,1l0.4,0.8l1,0.7l-0.8,0.9l0.1,0.4l0.3,0.4l1.1,0.2l0.5,0.6l0,0.9l0.2,1.5
      l-2.2,1.5l-0.6,1.7l-0.1,0.3l-0.1-0.1l-0.2-0.6l-0.4,0l-0.9-0.2l-1.3,1.5l-0.6,1.3l-0.3,0.9l-0.5,0.8l-0.1,0.8l0.1,0.3l-0.2,0.4
      l0,0.5l0.7,0.9l0.2,0.5l0.9,1.6l-0.3,0.6l-0.2,0.6l-0.3,0.4l-0.3,0.3l-0.9,0l-1.2,0.2l-0.8,0.3h-0.4l-0.9-0.8l-1-1.2l-0.6-0.6
      l-0.3-0.5l-0.7-0.2l-1.1-0.6l-0.7-0.6l-0.6-0.4l-0.9-0.2l-0.7,0l-0.2-1.1l-0.1-1.2l-0.6-0.8l0.8-3.2l-0.5-0.3l-0.5,0.3l-0.8,0.8
      l-0.4,0.9l-0.2,0.8L176,525l-2.1,0.3l-2.3-0.3l-0.3-0.2l-0.1-0.2v-0.3l0.2-0.4l0.4-0.5l0.1-0.7l-0.4-0.6l-0.3-0.3l0.1-0.6l0.3-0.8
      l0.1-0.5l-1.5-1.4l-1.1-0.3l-1.1,0l-0.8-0.2l-0.5,0.1l-0.3,0.4l-0.3,0.3l-0.3-0.3l-0.5-2.4l-0.4-0.4l-1.4-0.4l-1.9-0.1l-0.5-0.4
      l-0.3-1.1l-0.2-1.3l-0.6-1.3l-0.3-0.3l-0.6-0.6l-1,0.2L157,511l-0.7,0.2l-0.3,0.1l-0.9-0.7l-1.1-1.1l-0.8-1.2l-0.2-0.7l0.3-0.8
      l-0.3-0.6l-0.5-1.1l-0.1-0.9l5.1-3.1l3.1-1.6l1.5-0.5l0.4,1.6l0.3,0.5l0.3,0.3l0.5,0.1l0.5-0.4l0.7-0.4l1.2,0.2l0.9,0.4l0.3,0.4
      l0.6,0.4l0.8,0.1l1.6-0.7l1.6-1.1l0.5-0.8l0.2-0.7l0.9,0.5l0.8,0.1l0.4-0.2l-0.2-1.1l0.7-0.6l0.7-0.3l0.3,0.5l0.7,0.5l0.5,0l1.4-1.3
      l0.3,0.3l0.3,0.5L179.2,497.9z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="bulgaria"
        d="M457.7,630.8l-0.3,3.9l-1.1,1.8l-1.7-0.6l-2.2,0.5l-1.2,2l-0.7,0.6l-0.6,0.7l-0.4,2.6l-0.1,4.3
      l-0.8,0.5l-0.8,0.2l-3.2,3.8l1.8,1.1l0.8,0.8l1.3,2.2l1.9,2.5l0.4,1.2l-1.6-0.3l-0.6,0.1l-0.4,0.4l-0.7-0.1l-0.9,0l-1,0.4l-0.5,0.2
      l-0.7-0.4l-1.3-1.2l-0.8-0.9L442,657l-0.6,0.3l-2.1,0.3l-0.5,0.5l-1,0.5l-1,0.3l-1.4,0.2l-0.7,0l-0.4,0.3l-0.4,0.8l-0.2,0.8
      l-0.2,0.3l-1.8,0.4l-0.4,0.4l-0.1,0.4l0,0.4l-1.4-0.4l-1.1,0.3l-0.3,0.3l-0.2,0.5l0.1,0.5l0.4,0.5l0.4,1.3l0.1,1.3L429,668l-0.8,0.5
      l-1.7,0.6l-1.6-0.3l-0.7,0.2l-1.2,0.1l-1.1,0.2l-1.7,0.5l-1.5,0.3l-1.4-1.1l-1.6-0.8l-1.7-0.5l-0.6,0.3l-0.3,0.3l-1.4-1l-0.6-0.4
      l-0.3-0.4l-0.6-1.3l-0.4,0l-1.2,0.5l-1.1,0l-0.7-0.1l-2,0.1l-0.3,0.9l-0.3,0.1l-0.4,0.1l-1.1-0.1l-1.4,0.7l-1.5,0.4l-1.2,0l-1.2-0.2
      l-0.7,0.1l-1.5,0.1l-1,1l-1.5-0.1l-1.3-0.2l0.2-0.3l0.3-3.9l0.6-1.7l0-0.4l-0.1-0.3l-0.6-0.3l-0.4-0.9l-0.8-2.5l-0.5-0.5l-1.3-0.5
      l-1.2-0.7l-1-0.9l-1.8-2.3l0.9-0.2l0.3-0.5l0.9-1.3l0.1-0.6l-0.1-0.4l-0.6-0.6l-0.4-1.4l0.3-1.3l0-0.7l-0.3-0.6l0.3-0.8l0.7-0.4
      l0.4-0.1l1.7-0.1l1.1-1.6l0.7-0.5l0.7-0.9l0.3-0.3l0.3-0.7l0.1-0.7l-1.4-1l-0.5-0.8l-0.6-0.9l-0.8-0.6l-1.7-1l-0.7-1l-0.3-1.3
      l-0.4-1l-0.5-0.7l-0.1-0.5l-0.2-0.7l0-1.3l0.4-1.7l0.3-0.6l0.6-0.2l1.5-0.9l0.1-1.2l0.3-0.7l0.5-0.4l0.4-0.3l0.8,0.7l2,1.1l1,0.8
      l-0.1,0.5l-0.5,0.5l-0.9,0.5l-0.5,0.6l-0.1,0.8l0.1,0.6l0.6,0.5l3.6-0.6l3.6,0.3l4.9,1.1l3.2,0.4l2.4-0.5l4.4,0.9l4.1,0.8l4,0.2
      l2.2-0.7l1.6-0.9l1.3-1.7l3.3-2.2l3.2-1.2l4.2-1l2.8-0.3l0.4,0.3l3.6,2l1.6,0l1.3,0.4l0.5,0.5l0.3,0.1l1.7-0.5l0.8,1.1l1.2,1.6
      l2,0.8l1.8,0.4l0.6,0.1L457.7,630.8z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="belarus"
        d="M452.6,405l1.6,1.8l0.4,0.1l0.9-0.7l0.2,0l1.8-0.1l0.9,0.6l0.6,1.2l0.6,1l0.6,0.3l1.8-1.2l1-0.4
      l0.7,0l2.3,1.1l1.1,0.6l0.2,0.5l0,0.6l-0.3,1l-0.2,1l0.7,1.2l0.8,0.8l1.7-1.4l0.6-0.4l0.7,0l0.9-0.5l0.7-0.8l0.6-0.3l1.2,0.2
      l2.2-0.2l2.6,1.2l0.2,0.4l1.3,1.4l0.5,0.7l0.4,0.2l0.7,0.7l0.9,0.4l0.6-0.1l0.3,0.2l0.3,0.5l0,0.9l-0.1,2.6l-0.5,0.8l-0.5,0.6
      l-0.1,0.5l0,0.6l0.7,1.1l1,1.7l0.2,1v0.8l-1.3,2.2l-0.4,0.5l-0.3,1.1l-0.2,1.1l0.1,0.5l2.2,1.8l1.6,1l0.4,0.5l0,0.3l-0.9,1.9
      l-0.1,0.5l1.3,0.8l0.7,1.2l0.6,2l1.2,1.9l2.6,1.7l1.9,1.1l0.4,0.5l0.1,0.6l-0.1,1.3l-0.5,1.6l-0.3,0.9l0.8,0.4l2-0.1l2.4,0.3
      l2.9,1.7l0,0.8l-0.3,0.7l0.2,0.7l0.3,0.6l2.5,1.9l0.2,0.6l0,0.9l-0.1,0.7l-0.7,0.1l-0.8,0.3l-1.3,0.8l-0.5,1.2l-2.1,1.6l-1.3,0.7
      l-1,0l-2.4-0.3l-0.8-0.8l-0.4-0.7l-0.9-0.3l-1.2,0l-1.7,0.1l-0.3,0.2l-0.3,0.9l-0.7,1.5l-0.5,0.9l0.4,0.5l0.7,1.1l1.1,1.4l1.1,1.2
      l0.3,0.7l0,0.5l-0.5,0.6l0.1,1.3l1.1,1.7l-0.4,0.3l-0.1,2l0,2.2l0.3,0.5l0.6,0.4l0.5,0.8l0.8,1.8l0.1,0.5l-2.2-0.1l-2.7,0.1l-1.5,1
      l-0.6-0.2l-1-0.3l-1.2,0.6l-1.6,1.8l-1.1,1.1l-1,1.5l-0.3,0.8l-0.7,1.5l-0.6,1.7l0.3,1.2l0.5,1.1l0.1,1.2l0.2,0.9l-0.7,0.7l-0.4,1
      l-1.1-0.2l-1.4-1l-0.3-1.4l-1-1l-0.7-0.5L475,497l-1.8,0.4l-2.4,0.3l-1.8,0.1l-1,0.5l-1.4,0.5l-0.6-0.6l-0.8-1.6l-0.7-1.6l-0.5-0.7
      l-0.4-0.2l-0.5,0l-0.6,0.5l-0.4,0.5l-0.6,0.2l-0.9,0.4l-0.7,0.6l-0.7,1.4l-0.5-0.1l-0.5-0.3l-0.6-1.6l-0.8-0.4l-1.3,0l-1.6-0.3
      l-1.3-0.5l-0.5,0.1l-0.8,0.7l-0.8,0.1l-1.8-0.6l-0.3,0.3l-0.5,0.9l-0.6,0.9l-0.5,0.1l-0.3-0.2l0.1-1.5l-1-0.5l-1.7-0.1l-1.2,0.2
      l-0.6-0.1l-0.3-0.3l-1.5-2.6l-0.8-0.2l-1.4,0.1l-2.1-0.3l-2.4-0.6l-1.3-0.2l-0.7-0.6l-1.5-0.2l-4-1.1l-1.6-0.2l-2.4,0l-3.7-0.2
      l-2.4,0.1l-1.1,0.4l-1.3,0.2l-2.1,0.2l-0.9,0l-1.4,0.1l-1.6,0.3l-0.4,0.5l-0.5,1.2l-1.8,2.1l-1.7,1.4l-0.3,0.1l-1-0.7l-0.8-0.2
      l-1-0.1l-0.7,0.2l-0.4,0.3l0.1,1.6l-0.1,0.1l-0.8-1.9l0.1-1.7l0.4-1l0.5-0.9l-0.2-1.3l0.5-1.8l0-1.3l-0.2-0.5l-0.4-0.6l-1.1-0.7
      l-0.5-0.6l-1.5-0.7l-1.5-0.9l-0.3-0.6l0.1-0.4l0.3-0.6l1.2-1.7l1.3-1.7l0.8-0.7l4.3-2.2l0.7-0.8l0.2-1.3l0-0.9l-0.1-1.7l-0.3-2.4
      l-0.3-1.6l-0.8-3.1l-2.2-6.4l-1.3-6.7l0.9,0.4l2,0.1l1.6-0.5l0.8-0.1l0.8,0.1l1.1-0.3l1-0.1l0.5,0.6l1,0.5l1.9-0.8l1.7-1l1.7,0.1
      l0.2-0.5l0.4-2.4l0.5-0.5l2.1,0.2l0.8-0.4l0.8-1.2l1.2-0.7l1,0l1.1-0.8l0.5,0.6l0.3,1l-0.4,0.8l0.2,0.3l0.7,0.4l1.3,0l0.8-0.3
      l0.2-0.5v-0.8l-0.2-0.8l-0.5-0.7l-1-0.3l-0.7,0l-0.1-0.4l0.2-0.9l0.6-1.7l0.8-1.5l0.5-0.6l0.1-0.5l-0.1-0.9l0-1.6l0.7-2.3l0.9-1.7
      l1.2-0.6l1.5-0.3l1-0.8l0.5-1l0.2-0.8l0.2-0.7l0.5-0.3l3.6,0.2l0.6-1.5l0.3-0.4l0.7-0.4l0.5-0.5l-0.2-0.4l-0.9-0.3l-2.2-0.2
      l-0.4-0.5l0.1-0.6l0.6-1.6l0.6-2l0.3-1.6l0-0.9l0.3-0.2l1.8-0.3l0.6-0.3l1.5-2.1l1.2-0.4l3,0.6l1.4,0l0.4,0l1.4,0.1l0.1-0.2l0.6-2.1
      l0.6-0.6l2.4-2.8l1.6-1.2l1-0.3L452.6,405z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="switzerland"
        d="M234.3,567.9l0.4,0.2l0.8,0.8l-0.2,1.3l-1,2.1l-0.5,1.7l-0.1,1.3l0.1,0.6l0.2,0l0.9,0.1h0.5
      l1.5,0.3l1.2,0.5l0.2,0.5l0.2,0.7l1.4,0.9l1.6,0.6l0.5-0.2l2-2.1l0.8,0.3l0.5,1.1l0,0.6l-0.5,2.2l-0.1,1.2l0.5,0.8l0.1,0.6l-0.1,0.6
      l-0.8,0l-1.1-0.3l-0.9-1l-0.7,0.1l-0.6,0.2l-0.3,0.9l-0.3,1.1l0.1,0.6l0.4,0.5l0.3,1l0.2,1.3l0.2,0.6l-0.2,0.3l-0.6,0.2l-0.5-0.2
      l-0.8-1.5l-0.4-0.6l-0.6-0.1l-1.1,0.4l-1.7,0.8l-0.7,0l-0.6-0.2l-0.6-0.7l-0.5-1.4l-0.2-0.9l-0.3,0l-1.1-0.2l-0.5,0.3l0,1.4
      l-0.1,1.8l-0.6,1.1l-1.6,2l-0.6,0.9l-0.2,0.6l0,0.5l0.2,0.9l0.3,0.9l-0.3,0.5l-0.8,0.3l-0.6-0.5l-0.2-0.9l-1.3-1.3l0.6-1.1l-0.1-0.3
      l-2.1-0.6l-0.9-0.8l-1.3-1.4l-0.2-0.6l0.1-2l-0.1-0.5l-0.2-0.2l-0.6,0L220,587l-0.8,1l-1.6,1.2l-0.2,0.2l0.5,1.2l0,0.4l-1.3,1.8
      l-0.2,0.6l-1.6,1.1l-0.8,0.4l-2.3-0.8l-0.6-0.1l-1,0.6l-1.4,0.5l-2.3,0.5l-0.9-0.4L205,595l-0.2-0.6l-0.6-1l-0.7-0.6l-0.5-0.6
      l-0.6-0.7l-0.4-0.6l0.5-1.9l-0.4-0.6l-0.2-0.9l0.1-0.6l-0.2-0.2l-2.1-0.4l-1.8,0.1l-1.3,0.6l-1,1l-0.1,0.2l0.1,0.2l0.5,0.9l-0.9,1
      l-1.3,0.8l-0.9,0.1l-0.4-0.1l0-1.1l0.8-0.4l0.7-0.7l0.2-1l0.1-0.7l-0.7-0.8l0.1-0.5l0.5-1l0.3-0.9l0.4-0.7l1.5-1.2l1.5-1.2l0.2-1.3
      l0.1-1.6l0.2-0.4l2-1l0.5-0.4l0.2-0.5l1.6-1.8l1.5-1.8l0.3-0.6l0.3-0.4v-0.3l-0.2-0.2l-0.7-0.1l-0.3-0.6l0.8-1l1-0.6l1,0l0.4,0.3
      l0,0.3l0.4,0.4l0.7,0.1l0.9-0.1l0.9-0.4l0.6-0.9l0.3-0.7l1.4-0.8l1,0.4l2.7,0.1l2-0.2l1.2-0.5h1.5l1,0.3l0.2,0l0.3-0.1l0.3-0.3
      l1-0.2l0.1-0.2l0-0.2l-0.2-0.1l-1.2,0.1l-0.4-0.2l-0.1-0.4l0.4-0.8l0.9-0.6l0.7-0.1l0.5,0.2l1.3,1.1l0.3,0l0.2-0.2l0.3-0.1l0.4,0.2
      l0.5,0.7l0.1,0.1l2.9-0.2h0.6l2,1.2L234.3,567.9z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="cyprus"
        d="M526.6,754.1l-6.1,4.3l-0.4,1.3l0.3,0.9l0.9,1.1l0.2,0.3l0.3,0.8l-1.3,0.2l-1.3,0.1l-0.7-0.1
      l-0.7,0l-2.2,2.3l-1.2,0.8l-1.4,0.5l-1.4,0.3l-0.7,0l-0.6,0.3l-0.4,0.5l0,0.5l-0.2,0.4l-0.8-0.1l-0.3-0.8l-0.6-0.4l-1.4,0.2l-0.7,0
      l-2.2-0.8l-0.7-0.3l-0.4-0.7l-1.1-2.5l-0.2-1.8l1.1,0.5l1-0.6l0.9-0.9l1.1-0.4l0.7,0.2l0.7,0.2l1.3-0.3l0.5-1.4l0.2-1.6l2.1,0.5
      l2.2,0.2l1.8,0.1l1.7-0.3l5.3-1.7l1.5-1l0.9-0.3l1.6-0.8l1.7-0.5L526.6,754.1z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="czech-republic"
        d="M296.3,508.5l1,0l1-0.5l0.1-0.8l-0.1-1.4l0.1-0.2l1.5,0.4l1.6,0.6l0.2,1.4l0.4,0.7l0.5,0.6
      l0.5,0.3l0.8,0l2.1,0.8l1,0.2l1,0.6l0.9,0.6l0.6,0.1l0.3,0.6l0.4,0.4l0.7-0.3l2.5-0.5l0.9,0.6l0.6,0.7l0.1,0.2l-0.3,0.6l-0.2,0.5
      l-0.3,0.3l-0.9,0.3l-0.5,0.5l-0.4,0.6l0.2,0.5l0.7,0.4l0.5,0.1l0.2,0.4l1.6,1.8l1.3,2.3l0.5,0.4l0.5,0.1l0.5-0.3l0.6-0.7l0.7-0.5
      l0.6-0.3l1.1-0.6l0-0.4L321,518l-0.5-1.3l0.1-0.2l1.2,0.2l2,0.7l3.1,2.2h0.6l1.1-0.2l1.2-0.4l0.6-0.4l0.2,0.2l0.2,1.2l-0.3,0.7
      l-1.4,0.7l0.1,0.3l0.4,0.4l0.6,0.3l0.8,0.8l0.5,0.9l0.5,0.4l0.5,0.2l1.3-0.5l0.4-0.4l0.2-0.3l0.2,0.1l0.4,0.4l0.1,0.3l1.2,0.5
      l0.7,0.6l0.5,0.3l0.5-0.3l2,0.5l0.5,0.4l0.2,0.7l-0.1,0.4l0.3,1.1l2.5,2.6l0.3,1.3l0,0.5l-0.3,0l-0.7,0.3l-0.9,0.1l-0.9-0.1
      l-0.7,0.5l-0.7,0.8l-0.7,0.5l-0.4,0.5l-0.2,0.5l-2.4,1.4l-0.3,0.6L335,539l-0.1,1.1l-0.2,1l-0.4,0.5l-1.3,0.4l-0.3,0.2l-0.2,0.5
      l-0.7,0.8l-0.8,0.7l-1.6,0.8l-1.7,0.2l-2.2-0.3l-1.3-0.3l-0.6,0.4l-0.9,1.1l-0.9,1.8l-0.4,1.4l-0.3-0.4l-0.5-1.5l-0.6-0.2l-0.8-0.1
      l-0.6-0.2l-1.3-0.8l-0.7-0.3l-0.8-0.1l-0.7,0.5l-0.6,0.6l-1.7,0l-1.9-0.3l-2.7-1.9l-0.7,0l-0.8,0.1l-1.2-0.5l-2.3-1.3l-1.1-0.3
      l-0.7,0.2l-0.6,0.3l-0.4,0l-0.3-0.4l-0.9-0.5l-0.9-0.1l-0.2,0.3l-0.3,2.8l-0.3,1l-1.2,0l-0.4,0.5l-0.9,1.3l-0.2,1.3l-1.6-0.2
      l-0.8-0.2l-0.7,0.2l-0.7,0.7l-2.1,0l-1.6-0.4l-0.7-1.6l-0.8-0.6l-1-0.6l-0.3-0.1l-0.5-0.9l-1-1.1l-1.6-1.5l-1.3,0.1l-0.5-0.4
      l-0.2-0.5l-0.5-0.9l-0.6-0.6l-0.7-0.3l-1-0.8l-1.4-1.8l-1.3-1.3l-1.2,0l-0.8-0.7l-0.8-0.9l-0.6-0.8l-0.9-2l-0.7-1.2l-0.5-0.7
      l-0.6-0.6l-0.2-0.5l0.7-1.1l0.3-0.5l0.3-0.4l0.2-0.4l0-0.3l-0.6-1.1l-0.9-0.8l-1.3-0.8l-0.8-1l-0.3-0.9l-0.1-0.5l-0.6-0.7l-0.4-1
      v-0.6l0.1-0.2h0.4l0.5,0.4l0.7,0.8l0.5,1.2l0.3-0.4l0.6-1.2l1.1-1.4l1.1-0.8l1-0.1l0.8-0.2l0.7-0.4l1.2,0.2l0.9,0.3l0.3-0.2l0.4-0.7
      l0.2-0.6l1.9-0.4l0.7-1.2l0.4,0l0.4-0.2l0.4-0.5l0.4-0.2l0.3,0.2l0.4,0.1l0.4-0.3l0.6-1.4l0.4-0.2l1.7-0.2l2.3-0.8l1.2-0.7l1.1-0.4
      l1.2-0.7l2-0.7l0.1-0.3l-0.9-0.7l-0.3-0.4l-0.2-0.5l0.3-0.5l0.4-0.2l0.6,0.2l1.6,0.3l0.5,0.3l0.2,0.7l0.4,0.7l0.3,0.1l-0.1,1.1
      l0.5,0.4l0.8,0.3l0.5-0.1l0.4-0.4L296.3,508.5z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="germany"
        d="M220.1,432.8l-0.3,0.4l0.1-2.9l1.3-3l0.5,0.1l-0.6,0.8l-0.2,0.6l-0.2,1.2l0.1,0.6l2.9,0.2
      l-0.3,0.5l-3,0.4L220.1,432.8z M236.9,432.1l0.1,0.4l1.7,0.5l0.7,0.9l0.8,1.3l0.1,1.9l-1,1.3l-0.9,0.8l3.2-0.3l0.3,0.8l0.5,0.8
      l1.7-0.6l4.4,2.4l2.6-1.2l0.7-0.1l0.6,2l-0.7,2l-2.3,2.1l0.5,1.3l0.7,0.3l2.2-0.3l3.5,1.3l0.7-0.4l2.8-2.9l1.1-0.6l3.7-0.5l0.7-1.1
      l1.5-1.2l1-1.3l2.3-2.4l2.4,0.4l1.4,0.5l1.5,0.2l1.4,2.6l3.5,2.8l3.2-0.2l1.1,2.7l0.5,3.3l1,1l0.9,0.7l2.6,0.7l0.1,0l0.1,0.4
      l0.2,1.6l0.2,1.3l1.4,5.3l0,1.3l0,0.3l-0.5,1.8l-0.9,1.5l-1.2,0.9l-0.6,1l-0.1,1.1l1.5,1.8l3.1,2.6l1.2,2.2l-0.6,1.9l-0.2,1.4
      l0.2,0.9l0.5,0.7l0.8,0.5l0.3,0.8l-0.2,1.1l0.1,0.8l0.6,0.5l-0.1,0.2l-0.3,0.8l-0.4,1.4l-0.2,1l-0.9,1.4l0.3,1.2l0.7,1.4l0.5,0.7
      l0.2,0.6l-0.3,1.5l0.2,0.4l2.1,1.1l0.3,0.5l0.2,1.1l0.7,2.3l-0.6,2.9l-0.5,1.6l-1.2,2.5l-0.1,0.2l-0.1,0.3l-0.4,0.4l-0.5,0.1
      l-0.8-0.3l-0.5-0.4l0.1-1.1l-0.3-0.1l-0.4-0.7l-0.2-0.7l-0.4-0.3l-1.6-0.3l-0.6-0.2l-0.4,0.2l-0.3,0.5l0.2,0.5l0.3,0.4l0.9,0.7
      l-0.1,0.3l-2,0.7l-1.2,0.7l-1.1,0.4l-1.2,0.7l-2.3,0.8l-1.7,0.2l-0.4,0.2l-0.6,1.4l-0.4,0.3l-0.4-0.1l-0.3-0.2l-0.4,0.2l-0.4,0.5
      l-0.4,0.2l-0.4,0l-0.7,1.2l-1.9,0.4l-0.2,0.6l-0.4,0.7l-0.3,0.2l-0.9-0.3l-1.2-0.2l-0.7,0.4l-0.8,0.2l-1,0.1l-1.1,0.8l-1.1,1.4
      l-0.6,1.2l-0.3,0.4l-0.5-1.2l-0.7-0.8l-0.5-0.4h-0.4l-0.1,0.2v0.6l0.4,1l0.6,0.7l0.1,0.5l0.3,0.9l0.8,1l1.3,0.8l0.9,0.8l0.6,1.1
      l0,0.3l-0.2,0.4l-0.3,0.4l-0.3,0.5l-0.7,1.1l0.2,0.5l0.6,0.6l0.5,0.7l0.7,1.2l0.9,2l0.6,0.8l0.8,0.9l0.8,0.7l1.2,0l1.3,1.3l1.4,1.8
      l1,0.8l0.7,0.3l0.6,0.7l0.5,0.9l0.2,0.5l0.5,0.4l1.3-0.1l1.6,1.5l1,1.1l0.5,0.9l-0.1,0.3l-0.1,1.1l0,1.1l-0.2,0.6l-0.7,0.8l-0.4,0.2
      l-0.2,0.2l-2.2-1l-0.2,0.2l-0.1,0.1l-0.6,3l-0.4,0.6l-0.6,0.5l-1.3,0.5l-0.9,0.2l-0.7,0.3l-2.2,1.2l-1,0.7l-0.6,0.9l0,0.5l1.1,1.6
      l1.2,1.6l0,1.4l-0.5,1.1l-0.1,0.4l0.4,0.2l0.7,0.1l0.6,0.2l0.2,0.8l-0.1,1.3l-0.2,1.2l-0.2,0.5l-0.5,0l-1-0.5l-0.8-0.6l-0.3-0.4
      l0-0.5l0.2-0.3l-0.3-0.6l-1-0.5l-1.1,0.2l-0.8,0.3l-0.5,0l-0.6-0.5l-0.9-0.4l-1.1-0.3l-0.7-0.3l-0.1,0.2l0.1,1.1l-0.2,0.5l-5.5,0.6
      l-1.7,0.6l-1.2,0.8l-0.9,0.3L256,569l-0.9,0.6l-1,0.2l-0.2-0.2l-0.6,0.3l-1.1,0.3l-0.7-0.1l-0.3-0.5l-0.7-0.8l-0.3-0.5l0-0.3
      l-1.5-0.1l-1-0.4l-2.1,0.1l-0.5-0.2l-0.1,0.2l-0.3,2.1l-0.4,0.9l-0.7,0.9l-0.8,0.5l-0.7,0.1l0-0.7l0.2-0.8l-0.5-0.2l-0.7-0.1
      l-0.4-0.2l0.1-0.6l-0.2-0.4l-0.3-0.4l-0.7-0.5l-1.6-0.8L237,567l-0.4,0.4l-0.8,0.4l-1.2-0.1l-0.3,0.2l-2-1.3l-2-1.2h-0.6l-2.9,0.2
      l-0.1-0.1l-0.5-0.7l-0.4-0.2l-0.3,0.1l-0.2,0.2l-0.3,0l-1.3-1.1l-0.5-0.2l-0.7,0.2l-0.9,0.6l-0.4,0.8l0.1,0.4l0.4,0.2l1.2-0.1
      l0.2,0.1l0,0.2l-0.1,0.2l-1,0.2l-0.3,0.3l-0.3,0.1l-0.2,0l-1-0.3h-1.5l-1.2,0.5l-2,0.2l-2.7-0.1l-1-0.4l-0.6-0.2l-0.4-1.2l0.1-1.7
      l0.6-2.3l0.2-1.7l-0.3-1.1l0.4-1.6l1-2.2l0.7-2.3l0.3-2.4l0.5-1.6l1-1.1l2.4-3.1l0.2-0.2l-0.1-1.5l-0.6-0.2l-0.9-0.4l-2.4-0.5
      l-2.2-0.4l-1-0.4l-0.9-1.2l-0.5,0l-1.1,0.4l-1.3,0.3l-1-0.2l-0.6,0l-0.3,0.2l-0.2-0.2l-0.2-1l-0.5-0.3l-0.8-0.2l-0.5,0.1l-0.3,0.5
      l-0.5,0.3l-0.5-0.1l-1.5-2.3l-0.4-0.5l-0.1-0.5l-0.4-0.9l-0.9-0.9l-0.9-0.3l-0.4,0.1l0.1-1.1l0.3-1.6l0.3-0.8l0.4-0.7l0.5-0.5
      l0.1-0.8l-0.1-0.8l-0.5-0.1l-1.4-0.6l-0.8-0.6l-0.6-0.8l-0.8-1.1l-0.3-1.1l0-1.1l0.1-0.5l0.1-0.3l0.6-1.7l2.2-1.5l-0.2-1.5l0-0.9
      l-0.5-0.6l-1.1-0.2l-0.3-0.4l-0.1-0.4l0.8-0.9l-1-0.7l-0.4-0.7l-1.3-1l-0.1-0.3l0.6-2.8l-0.5-0.8l-0.6-0.4l-0.7-0.2l-0.3-0.4
      l-0.1-0.5l0.1-0.3l0.8,0.1l0.3-0.3l2-1.7l0.1-0.3l-0.3-0.2l-0.4-0.1l-0.1-0.4l0-0.5l1.1-2.4l0.3-1l0.1-0.7l-0.1-0.7l-0.6-1.1
      l-0.6-0.9l0-0.7l-0.4-0.4l-1.2-2V491l0.7-0.6l1-0.4l0.3-0.3l0.6-0.2l1.5,0.6l0.7,0.5l0.2-0.1l0.6-0.5l1.1,0.1l2.6-1.1l0.4-0.5
      l0.3-0.5l0-0.2l-1-1.1l0-0.4l0.1-0.4l0.3-0.3l0.6-0.2l0.6-0.5l1.4-1.3l0.5-1.1l0.2-1.2l0-0.9l-0.4-0.7l-0.4-0.5l-0.5,0.1l-1,0
      l-1-0.4l-0.5-0.7l-0.1-0.6l0.2-0.4l0.1-0.5l-0.2-0.5l0.1-0.4l0.4-0.3l3.1,0l0.2-0.3l0.2-1.8l0.8-2.7l0.7-1.5l0.1-0.6l0-3.6l0.1-1.8
      l-0.5-0.9l-1.2-1l0.2-2l0.4-1.6l1.2-1.9l0.9-0.5l4-0.3l4.5,0.1l1.8,2.9l-0.7,1.5l1.1,0.7l0.5-0.3l0.4-1.3l0.3-1.4l0.4-0.4l1.4,1.1
      l0.5,0.7l0,2.3l0.5-3.2l-0.4-2.2l0.3-2.2l0.6-1.1l0.5-0.7l3.3,0.8l3.6-0.4l1.4,0.8l3.1,4.2l1,0.7l1.3,0.2l-1.8-0.9l-3.7-5.1
      l-1.1-0.6l-1.7-0.2l-1.1-0.5l-0.7-0.8l-0.2-0.7l0-5.2l-0.6-0.8l-0.8-0.3l-0.5,0.4l-1.1,0l-0.2-1.2l0.3-0.9l2.1-0.6l1.4-0.8l0.1-1.4
      l-0.9-1.1l-1.1-2.1l-1.3-1.9l-0.1-2.3h0l2.2,0l0.5,0.1l3.3,1.1l0.8,0.7l1,0l1.8-0.7l1.4-0.3l0.5,0.4l0.7,0.2L236.9,432.1
      L236.9,432.1z M223.4,434.3l-0.5,0.5l-1.1-0.1l-0.6-0.5l0.2-0.5l0.6-0.4l0.5-0.1l0.7,0.2L223.4,434.3z M283.4,441l0.3,1.3l-0.3,0.7
      l-1.3-1.1l-1.3,0l-0.8,1.8l-0.6,0.1l-2-1.6l-0.3-0.8l-0.1-0.6l0.3-2.2l-0.1-0.7l0.6-0.8l0.1-1.1l1.1-1.2l1,0l0.3,1l0.5,0.7l1.7,0.8
      l0.3,0.3l0.2,0.5l-0.8,0.9l-0.3,0.5l0.2,0.8L283.4,441z M254.9,440.2l-1.8,0l-0.7-0.8l-0.7-0.2l0.4-1l0.5-0.4l1.7,0.6l0.5,1.3
      L254.9,440.2z M289.3,449.5l-0.2,0.6l0.2,1l-0.5-0.1l-1.5,0.2l-1.4-0.3l-0.3-1.2l0.2-1.1l-0.6-0.8l-0.5-0.5l-0.1-0.7l0.1-0.7
      l2.5,1.8L289.3,449.5z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="denmark"
        d="M236.9,432.1h-0.2l-0.7-0.2l-0.5-0.4l-1.4,0.3l-1.8,0.7l-1,0l-0.8-0.7l-3.3-1.1l-0.5-0.1l-2.2,0h0
      l-0.1-1.7l-0.3-1.2l-0.8-1.8l1.1-0.4l-0.2-3.5l-0.4-1.8l-3.2-1.9l-2.5-1.8l0.6-6.2l0.2-1.7l-0.9-3.3l0.1-3.8l0.4-6l0.8-0.2l0.6,0
      l2.2,1.1l0.9,0.1l0.6,1l0.8,0.4l0.5-1l0.2-1.8l1.8-2.3l1.2-0.8l0.9-0.4l0.9,0.9l0.7,1l0.2-2.3l0.5-4.3l-1.7-0.7l-1.4,0.6l-1.4,2.8
      l-1.2,3.4l-2,0.3l-1.6,1l-1.4-1l-0.9-0.9l0-1.3l0.2-0.8l1.7-2.8l2.2-2.7l2.3,0l1.6-0.9l1-0.1l3.1,0.2l1.6-0.6l1.4-1.2l3.1-5.3
      l1.7-2.2l3.5-0.8l3.2-2.6l0.9,0l-1.5,1.9l-0.2,0.7l-0.2,1.1l1.1,2.5l-0.2,1.5l0.1,2.9l-1,1.5l-1.2,3.2l-0.5,0.5l-0.1,3.8l0.1,0.9
      l-0.2,3.4l1.2,1.4l1.2,0.7l4.2,0l0.4,0.6l0.5,1l-0.4,1.8l-0.4,1.3l-1.2,1.1l-1.5,0.8l-1,0l-1.3-1.6l-0.6,0.5l-0.6,0.8l-1.1,4.3
      l-0.5,2.9l-0.3,0.2l-0.6-0.4l-1.1,0l-1.3,0.7l0.7,0.6l0.7,1.1l-0.3,0.5l-1.2,0.6l-1,1.2l-0.4,0.9l-1.3,1l-0.8,1.3l0.4,1.6l0.2,1.4
      l0.4,1.6l-0.3,1.3l-1.6,1.8l-0.6,1.6l1.4,0l0.9,0.4l0.5,0.5l0.5,0.7l-0.3,0.8L236.9,432.1z M252.3,381.5l-0.5,0.5l-1.6-0.7l0.7-1
      l1.8-0.5l1,0.2l-1.1,1L252.3,381.5z M270,412.4l0,2.1l-0.3,0.6l-0.4,0.4l-1.2,0.4l-1,0.6l-0.9,1l-0.3,1.5l0.7,1.1l1.3,0.6l0.3,2
      l-1.1,1l-2.7,1l-0.3,2.4l0.1,1.9l-0.1,1.4l-0.2,1.9l-2.2,0.9l-1.4-2.9l0-1.2l-0.4-1.4l-0.1-1.2l-0.5-1.8l-2.1-0.5l-0.8-0.1l-1.1,0.3
      l-0.3-0.1l-1.4-2.5l0.2-2.8l-0.7-1.4l-0.1-0.6l0-0.7l-0.6-0.6l-0.7-0.3l-0.4-1.6l0.8-0.4l2.1,0.2l0.6-0.1l0.5-0.3l1.7-2.6l-0.1-0.6
      l0.2-0.8l1.8-0.3l0.8,1l-0.2,1.6l0.1,2.1l1.1,0.6l0.4,0.1l0.5-1.5l0.3-0.7l0.4-0.4l0.1-1.4l-0.3-0.9l-0.5-0.6l2-1.7l2.1-1.4l1.2-0.1
      l1.2,0.3l1.1,0.5l0.6,0.4l0.3,0.6l-0.8,1.5l-0.2,0.8L270,412.4z M247,412.5l-0.2,0.4l-0.7-0.4l-0.1-1.3l0.3-1.2l-0.3-1.1l0.4-0.7
      l1,1.6l0.3,0.8l-0.4,0.9L247,412.5z M271.2,416.3l-1.1,0.9l-0.2,0l-0.4-1.2l0.6-0.7l0.3-0.6l0.2,0l0.3,0.7L271.2,416.3z
      M247.5,416.1l0.5,1.1l0.6,2.3l1,2.6l-0.4,1.1l0.3,1.4l-0.3,1.4l-1.9,1.6l-2.1,0.1l-2.2-0.8l-3.1-1.5l-0.3-0.9l-0.4-0.5l-0.8-2.6
      l0-3.2l1.6-0.4l3.4-1.6l0.8,0.2l0.8,0.8l1,0l1.4-1.1L247.5,416.1z M299.5,428.1l-0.4,0.3l-1.9-0.6l-2.4-1.4l0.3-2.8l0.6-1.2l4.3,3.1
      l0.1,1.2L299.5,428.1z M248.5,433.6l-0.5,0.1l-0.7-1.6l-0.1-0.5l0.8-1l0.5-1.2l1.4-1.8l0.8-2.1l0.3,0l-0.4,1.9l-1.8,5.3L248.5,433.6
      z M240.6,430.8l-1.2,0.3l-0.6-0.5l-1.1-0.2l-0.4-3.1l0.1-0.2l0.6,0.2l2,1.5l0.7,1.6L240.6,430.8z M269.8,429.2l-0.4,0.3l-1.8-0.2
      l-2,1.4l-0.8-0.4l0.3-0.9l0.2-0.3l0.7-0.4l0.5-0.6l0.2-0.9l0.4,0.5l1.3,0.2l0.6,0.3l0.5,0.4L269.8,429.2z M245.6,431.6l-0.8,0.2
      l-0.9-0.4l-1.5-1.7l-0.2-0.4l0.8,0.3l1,0.9l0.8,0.2l1.1,0.7L245.6,431.6z M255.9,430.7l2.1,1.3l1.4-0.1l1,0.5l0.2,0.8l0.1,1.8
      l-1,0.5l-1.1-0.2L257,436l-4.9-2.9l0.1-2.4l0.2-1l2.3-0.2L255.9,430.7z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="estonia"
        d="M451,331.9l0.6,0.7l0.8,1.1l0.2,0.7l-0.3,0.4l-0.8,0.3l-0.2,0.4l-0.3,0.6l-0.9,0.1l-0.5,0.4
      l-0.6,1.9l-1.1,3.2l-1.6,2.4l-1.3,1.3l-0.6,1l-0.4,1.2l-0.1,1.2l1.2,6.7l0,1.2L445,358l-0.2,1.2l0.2,1.1l0.8,1.8l0.9,2.7l0.3,1.7
      l0.6,0.6l0.5,0.5l0.1,0.3l0,0.3l-0.3,0.3l-2.5,0.9l-0.3,0.8l-0.3,0.9l-1.1,1.3l-0.3,1.2l-0.2,1.4l0,0.5l-0.3,0.1l-1.6-0.3l-1.8-0.9
      l-0.8-0.7l-0.8,0l-0.9,0.4l-3.4,1.3l-0.8-0.3l-1.9-1.2l-1-1.3l-2.2-2.7l-0.2-0.6l-0.3-0.5l-2.3-0.7l-0.9-1l-0.7-0.1l-1-0.5l-2.7-2.1
      l-0.7-0.2l-0.2,0.4l0,0.5l-0.2,0.3l-0.3,0l-0.6-0.8l-0.8-0.7l-2.3,1.3l-0.8,0.3l-0.7,0.1l-3.7,1.7l-1.1,0.9l-0.5-0.1l0.1-0.9
      l1.5-4.3l0.3-3.4l0.6-0.5l0.2-0.5l-0.2-1.1l-1.6-0.7l-0.6,0.1l-0.6,1.2l-0.6,0.9l-1.4,0.5l-1.2-0.9l-2.9-1.2l-0.7-1.6l-0.2-1.6
      l-1.5-1.6l-0.6-1.8l0.2-1.3l1.4-0.9l0.4-0.7l-1.7,0.1l-0.4-0.2l-0.1-0.7l-0.8-2.3l0.7-0.9l0.3-0.9l-0.6-0.7l0.1-0.9l0.4-0.9l-0.3-2
      l1.7-1.1l1.7-0.7l3.5-0.4l-0.3-1.8l1.4-0.1l2.4-2.2l2.4,0.4l3.4-1.5l6.6,0l0.9-0.9l-0.2-0.9l0-0.9l1.2,0.3l2.1-0.2l7.8,1.9h1.9
      l2.7,1.9l1.4,0.5h4.2l6.5,0.8l1.3-1.3L451,331.9z M391.4,346.8l-1,1.1l-0.6-0.4l-0.3-0.5l-1.2,2.5l-1.4,0.4l-0.8-0.5l0.1-0.9
      l-0.8-2.5l-1.2-0.7l-1.7-0.1l-1.2-1l4.8-0.7l0.5-1.2l1-1.2l0.7-0.1l0.6,0.3l0.1,1l0.2,0.4l2.2,0.5l0.8,1.6l0.3,1.9L391.4,346.8z
      M396.3,353l-1,0.2l-2.3-1.6l0.5-1.1l0.7-0.4l2,0.7l0.3,1.6L396.3,353z M387.8,351.4l0.8,0.5l0.8-0.2l0.8-0.4l1.7,0.4l3.9,2.7
      l0.4,0.7l-2.3,0.3l-0.5,0.8l-0.6,0.6l-0.7,0.2l-1.1,1.2l-1.5,1.1l-0.3,0.7l-2.7-0.1l-1.5,0.4l-1.2,1.2l-0.5,2.4l-0.9,1.9l-0.9,0.7
      l-0.9,0.1l-0.2-0.7l0.1-0.7l2-2.6l0.4-0.9l-1-0.4l-0.8-0.9l-1.8-1.1l-0.3-0.9l0.4-0.1l0.4-0.2l0.5-0.7l0.2-0.8l-1.4-2.5l0.7-0.4
      l0.9,0.1l0.9,0.7l1-0.8l0.4-0.1l0.7,0.3l0.7-1.6l1.7-0.5l0.9-0.5L387.8,351.4z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="spain"
        d="M101.5,636.2l0,0.6l0.5,0.8l0.5,0.3l1,0.4l0.8,0.1l1.1,0.2l0.7,0.4l0,0.7l-0.2,0.8l-0.4,0.7
      l-0.2,0.5l0.2,0.3l0.4,0.2l0.4,0.1l0.3-0.1l0.2-0.4l0.4-0.5l0.2-0.1l0,0.3l0.2,0.4l1.3,0.6l2.8,1.1l1.1,0l0.9,0.1l0.3,0.5l1.8,1.8
      l0.4,0l0.8,0l1-0.1l0.7-0.3l0.5,0.1l0.5,0.3l0.6,0.3l0.8,0.6l0.7,0.7l0.5,0.2l2.8-0.5l0.6,0.4l0.7,0l0.8-0.1l1.6,0.2l1.3-0.1
      l0.1-0.2l0.1-1.6l0.2-0.6l0.3-0.1l0.8,0.1l2.9,0.9l1.2,0.6l1.1,0.5l1,0l0.7,0.3l0.9,1.5l-0.2,0.8l0.1,0.3l0.1,0.5l0,0.6l0.2,0.4
      l0.4,0l0.6-0.1l0.6-0.2l1.1-0.6l0.3-0.1l1.8,0.7l0.8,0.5l0.3,0.5l0.4,0.5l0.5,0.1l0.8-0.5l1.2-0.5l2,0.5l2.3,0.7l1,0.1l0-0.3
      l0.2-0.5l0.4-0.2l0.6-0.1l0.8-0.3l0.9-0.4l0.9-0.2l1,0.3l1.2,0.3l0.7,0l0.3,1l0.6,0.4l0.2,0.9l-1,0.4l-0.6,0.1l-0.2,1.5l0.3,0.4
      l0.6,0.4l0.2,0.5l0.1,2.2l-1.2,1.3l-1.7,1.5l-8.1,4.7l-1.9,2.3l-0.7,0.5l-6,1.4l-4.2,1.5l-2,0.5l-2.5,2.6l-1.2,1.1l1,0.3l1.1,1.3
      l-0.4,0.6l-1.6,0.9l-0.7,0.3l-0.4-0.1l-0.4,0.1l-2.7,4.5l-2.4,3.2L123,689l-1.4,2.1l-3,5.4l0,1.5l1.5,5.3l0.8,1.4l1.2,1.2l2.2,1
      l0.5,1l-0.8,0.9l-2.2,1.7l-3.8,2.2l-1.6,1.8l-0.3,1.7l-1.1,0.8l-0.4,2.4l-0.7,1.6l-0.1,0.5l-0.7,1.2l-0.1,0.9l1.2,1.2l-0.6,0.5
      l-0.6,0.2l-1.4,0.1l-4.6,0.1l-3.7,2.5l-1.8,2.3l-1.7,4.2l-2,2.5l-0.9,0.5l-1.4-1.1l-1.7-0.2l-1.7,0.4l-0.9,0.9l-1.4,0.5l-1.3-0.4
      l-2.9-0.2l-1.3,0l-2,0.7l-1.7-0.5l-2.9-0.2l-6.3,0.6l-0.8,0.3l-0.8,1l-2,1.8l-3.1,0.1l-2.8,1.1l-0.7,0.7l-1.2,2l-0.4,1.5l-0.2,0
      l-0.3-0.4l-0.4,0.1l-0.2,1.1l-1,0.5l-0.9,0.2l-2.1-0.9l-1.8-1.3l-0.9-0.1l-1.5-2.1l-0.7-1.3l-0.5-1.4l0.1-0.6l-0.1-0.5l-1.3-0.6
      l-0.3-1.3l1-1.7l0.8-0.7l0.5-0.2l-1.2,0.1l-0.9,1.1l-1.1-1.8l-4.6-3.5l0.3-0.8l-0.1-0.4l-0.8,0.9l-0.5,0.2l-2.4-0.1l-2.7,0.4
      l-0.7-3.6l-0.3-1.4l-0.1-0.9l0.7-2.1l0.8-0.9l1-1.8l1.3-1.5l1.3-0.3l0.6-0.2l0.5-1.1l0.3-1l-0.2-0.1l-1.5,0.2l-2.8-4.1l0.1-0.7
      l0.3-1l0.2-1.2l0.1-1l0.7-0.8l1.1-0.8l0.9-1.2l0.5-1.2l0.1-1.1l-0.5-0.8l-1.5-0.4l-1.6-3l-0.3-1.9l-0.3-0.2l-1-0.9l-0.9-1.6
      l-0.1-0.3l1-0.3l3.9,0l0.8-0.4l0.1-0.1l0.7-1.3l0.8-2.1l0.2-1.3l-0.2-0.5l-1.3-1.3l-0.1-0.4l0.2-0.6l0.8-0.7l1-0.7l0.6-0.6l-0.1-0.5
      l-0.3-0.5l-0.1-0.5l0.2-0.6l0.1-2.1l0.1-0.5l-0.2-1.9l-0.3-1.6l-0.8-2l0.2-0.4l0.4-0.4l1.3-0.7l1-1.6l1.5-1.4l1.9-1.1l1.3-1.2
      l0.5-0.9l0.4-0.2l-0.1-0.4l-0.3-0.6l-0.8-0.6l-1-0.4l-1.1,0l-0.7-0.1l-0.2-0.5l0.1-1.3l-0.1-1.3l-0.2-0.6l-0.5-0.5l-1,0.1l-0.9-0.4
      l-0.7-0.1l-0.4,0.3l-1.9-0.1l-0.8-0.2l-0.6-0.3l-0.4,0.1l-0.2,0.3l0,0.4l-0.1,0.5l-0.7,0.5l-1.6,0.5l-1.3,0l-1.2-0.3l-0.4-0.3
      l-0.6-0.2l-2.4,0.3l-0.3-0.2l-0.8,0.5l-1.2,0.6l-0.7,0l-0.2-0.1l-0.1-0.3l-0.5-0.9l0.1-0.5l1-1.4l-0.1-0.3l-0.4-0.5l-0.4-0.7
      l-0.1-0.3l-0.6-0.1l-0.7,0.4l-2.5,0.7l-0.6,0.3l-1.1,0.7l-1.1,1.1l-0.9,0.2l-0.3-0.3l-0.1-2.5l1.3-1.7l1-1l-0.4-0.2l-1,0l0.1-0.8
      l0.5-0.4l0.5-0.8l-0.5-0.4l-0.4-0.6l0-1.5l0.1-0.6l-0.1-0.6l-2.1,0.9l-0.5-0.1l0-1.1l1.1-1.7l0.1-0.5l-1.3-0.2l-1-0.8l-0.6-0.7
      l-0.7-1l0-0.9L15,640l1-0.6l0.8-0.4l1.8-1.5l2.4,0.3l1.5-0.3l1.4-0.8l0.8-0.2l1.2-0.7l0-0.9l-0.4-0.7l0.4-0.6l1.4-0.8l1.6-1l1.8-0.2
      l1.8-0.9l1.2,0.6l1.1-0.2l1.2,0.7l1.6,1.6l2.4,0.7l1.9-0.5l3.3-0.1l1.7,0.2l2.9-0.4l1.7,0.1l2.7-0.8l2.1,1l4.1,0.5l2.5,0.8l6.8,1.4
      l2.5,0l3.5-0.8l1.5-0.6l1.4,0.3l2-0.7l1,0.1l1.2,1l4.4,1.3l1.1-1.1l0.9-0.2l3.1,0.7l3.2,1.4l1.6,0.1l2.4-0.4l1.9-0.9L101.5,636.2z
      M172.8,691.6l-0.2,0.2l-3.6-1.8l-1.2-0.2l-0.3-0.3l0-0.9l0.1-0.4l2.4-0.2l1.9,0.6l1,1.7l0.1,0.3L172.8,691.6z M159.4,692.4l1.1,0.5
      l1.2-0.5l0.6,0.1l0.6,0.2l0.2,1l-0.6,1.1l-0.8,1.1l-0.7,1.2l-0.6,1.4l-1.1,0.8l-1,0.5l-2-1l-1.2-0.3l-0.4-0.4l-0.3-1.5l-0.5-0.5
      l-0.8-0.2l-0.7,0.4l-0.9,0.8l-0.5-0.8l-0.8-0.1l-0.3-0.5l0-0.6l4.8-3.7l1.4-0.8l3-0.9l0.5,0.1l-0.4,0.6l0,0.3l0.4,0.3l-0.1,0.4
      l-0.4,0.4L159.4,692.4z M139.4,705.5l-0.4,0.9l-1.8-0.3l-0.4-0.4l0.4-1l0.5-0.1l0-0.7l0.5-0.7l2.5-0.6l0.6,0.5l0.1,0.7l-1.5,1.6
      L139.4,705.5z M141.2,709.2l-0.3,0.2l-0.8-0.2H139l0-0.6l0.2-0.4l0.2-0.4l0.7,0.8l1.1,0.2L141.2,709.2z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="finland"
        d="M462.2,73.2l-0.8,0.4l-2.4,1.6l-1.5,1.1l-1.8,0.8l0.5,1l3,0.2l0.5,0.3l0.3,0.5L460,80l-0.3,1.4
      l-3.2,7.5l-0.1,1.6l1,4.3l1.5,5.1l4.4,2.2l3.3,1.8l2.1,4.1l3.5,5.4l1.8,2l0.1,0.6l-0.5,3.7l-2.2,3.7l-2.1,3.1l-2.2,3.7l-1.7,3.1
      l-1.8,3.8l-0.2,1.2l0,1.1l0.3,1.3l2.3,4.5l0.9,2.3l1.1,2.5l0.9,2.7l0.5,2.4l0.9,2.3l0.6,1.2l1,1.7l1.2,2.5l0.4,2l1.8,6.8l0.2,1.7
      l-0.1,1.3l-0.8,0.3l-1.7,0.2l-1.9,0.8l-0.1,0.3l1.2,1.6l-1.1,2.7l-0.2,3.8l-1.1,2l-0.1,0.5l0,0.4l0.2,0.3l2.1,0.5l0.2,0.5l0,1.1
      l-0.2,1l-1.1,0.8l-1.1,1.1l-0.3,1l0,0.9l0.4,1.6l0.8,1.8l1,1.1l3.4,1.1l0.4,0.9l0.2,1.2l-0.1,1.2l-1.6,2.4l0,0.9l0.6,2.2l0.8,2.1
      l3.3,2.3l1.1,1.2l0.3,1l0.2,1.6l0,1.7l-0.3,1.5l-1,1.9l-2.4,3.8l-2.4,1.5l-0.1,0.3l0.7,1.2l4.3,4.8l2.8,2.3l3.7,3l2.4,2.4l0.8,1.7
      l1,1.9l1.2,1.6l0.8,1.3l0.3,0.9l0,0.9l-1.1,2.8l-0.6,2.1l-1.1,3.1l-1.2,2.2l-2.9,4l-4.3,4.9l-1,1.5l-2,2.6l-3.5,5.1l-0.9,1.1
      l-2.8,4.1l-1.3,1.3l-1,1.2l-2.8,3.8l-3,2.9l-3,2.7l-0.9,1.3l-1.1,1l-1.3,1l-0.6,0.5l-3,3.6l-4.2,5l-0.4,0.1l-1.1,0.8l-1.7,0.2
      l-0.7,0.6l-2.6-1.7l-0.4-0.1l-1.5,0.4l-1.5,1.3l-2.7,0.4l-1.3,0.4l-0.9,0.6l-0.2-1.4l0.4-1.8l0.6-1.2l0.1-0.8l-0.4,0.1l-0.9,1.7
      l-0.5,2l-0.9,1l-2,0.4l-2-1.6l-0.9,0l0.6,1.2l0.4,1.3l0,0.7l-1.1-0.1l-1.2,0.8l-1,1.1l-0.5,0l-0.7-1.5l-1.3,0.7l-1.1,1l-2.2,0.3
      l-1.3,1.3l-2.3,0.9l-1.3,0l-2.9,1l-1,1.6l-0.8,0.6L408,319l-3.7,0.8l-3.6,1l-1.5-0.1l-1.5-0.4l-1.6,1.4l-1.7,1.9l-1.9,0.7l-0.7-0.2
      l0.5-1l1.2-1l0.9-1.4l0.1-1.2L394,319l-0.8-0.1l-1-1.2l-1-2.6l-0.5-0.1l-0.3,0.7l-0.3,2l-0.3,0.6l-0.5,0.5l-0.6,0.5l-0.6,0.2l-2.2,0
      l-0.3-1v-0.4l0.4-1.3l-0.3-0.2l0.3-1l0.5,0.1l0.6-0.2l0.3-0.5l0-0.6l-0.8-0.2l0-0.4l0.7-1.8l0.1-0.5l-0.3-0.1l-0.5,0.2l-3.1-0.6
      l-3.8-2.3l-0.9-0.1l-0.6-2.1l-0.9,0.3l-1.3,1.2l-1-0.9l-1.1-0.6l-0.3-1l0-1.4l-0.1-1.7l-0.3-2l-0.2-2.8l0.2-2.2l0.9-1.6l0.3-1
      l0.4-2.7l0.1-3.1l-0.2-1.1l0.1-0.7h0.7l-0.1-0.6l-0.3-0.3l-0.3-0.7l0.3-0.4l0.8,0l0.1-0.2l0.1-0.3l-0.6-1.8l-0.1-0.9l-0.9-2.7
      l-1-2.6l-1.5-1.8l0.5-3.1l0.6-2.8l-0.1-1.3l-0.2-1.6l-1.9-1.8l-0.3-2.5l-0.5-2.7l0.2-1.7l0.3-1.3l0.6-1.3l3.1-4.1l0.2-2.1l2.1-0.2
      l-1-1.9l-0.2-1.1l0-1.3l3-0.9l1.1,0.7l2.6-0.9l2.3-1.7l0-0.9l-0.4-0.8l-0.5-1.6l0.4-0.4l0.9,0.3l-0.4-0.8l0.1-0.8l0.9,0.3l1.5-2.3
      l0.1-1.8l2.6-0.9l3-3.6l1.4-1.1l1.3-0.8l2.9-3.7l1.2-0.2l0.6-2.5l2.4-3.3l0.7-0.4l1.1-3l3-3.5l1.9-4.5l1-1.6l0.3-1.7l1.2-0.1
      l1.1-1.3l2.3-0.9l2.3,0.2l0.9,0.6l0.9-0.2l-0.1-1.5l-0.6-1l0.5-0.9l1.2-0.7l-0.1-1.5l-0.3-0.9l-1-1.2l0.5-2.8l0.1-3.1l0.5-3.5
      l-1.2-1.9l-4.7-3.2l-0.9,0.1l-1-0.4l-1.1-2.4l0.5-2.1l0.1-0.8l-0.4,0l-0.7,1l-1.5,1.2l-2-0.9l-1,0.2l-1.2-5.3l-0.6-2l-1-2.5
      l-1.8-1.2l-0.4-0.7l-0.2-1.1l-0.1-1.5l-0.2-2.2l0.1-1.8l0.2-1.1l0.8-0.7l1.1-2.1l0.2-1.5l0.1-2.3l0.5-2l0.6-1l-0.1-0.8l-0.4-1.2
      l-0.9-1.7l-1.3-2l-1-2l-0.4-1.8l-0.2-1.6l0-1.5l0.4-1l1.2-1.3l0.2-0.5l-0.5-2.9L400,123l-1.4-0.3l-0.8,0l-0.2-0.3l0-0.6l0.2-1.2
      l0.5-1.3l0.4-0.9l0.1-0.7l-0.5-2.5l-0.1-3.1l0.2-2.4l1.5-1.8l0.1-0.7l-1.9-2l-1.4-2.2l-0.4-1.3l-1.6-0.2l-1-3.8l-1.4-1.9l-1.4-1.6
      l-0.8-0.7l-4.9-2.3l-2-0.4l-2.3-1.4l-1.7-1.7l-1.5-1.1l-1.3-1.4l-1.8-1.3l-0.5-1.1l-1.9-2l-0.9-1.3l-3.1-2.5l-0.1-1l0-1l-0.1-0.4
      l-3.2-1.9l0.6-1.1l2.5-0.1l2.1,1l0.5-0.4l0.3-0.9l-0.9-3.5l0.2-0.9l0.9-1.1l1.4-0.9l2.3-0.1l1.6,0.1l0.3,0.1l2.3,3.8l2,3.7l1.1,1.6
      l2.6,4.4l1,2.6l0.3,1.8l1.1,0l3.6,0.8l3.1,0.7l0.9,1l2.1-0.2l1.6-0.9l2.9-1.2l0.8-1.4l1-1.5l1.7,0.2l1.8,1.2l2.1,1.6l1.9,0.7
      l2.5,1.1l1.2,1.5l1.6,0.4l1.7-1.5l1-4l0.9-1.8l1.3-1.3l1.4-0.6l1.1-0.2l0.8-1l1.2-2.3l0.2-2.8l-0.2-5.1l0.2-1.7l1-2.8l1.3-7.4
      l0.6-2.1l0.7-1.3l1-0.8l1.8-2.3l2.5-4.5l0.7-0.4l1.8-0.2l2.3,0.2l2,0.8l0.2-0.1l0.9-0.4l1.7-1.4l2.9-2.8l1.8-0.8l1.7,0.1l1.8,3.1
      L454,45l1.7,1.6l4.6,3.1l4,2l2.2,6.6l-1.1,2.6l-0.6,0.9l-2,2.6l-2.1,3.6l-0.2,1.9l0.7,1.9L462.2,73.2z M413.9,193.8l-1.8,0.9
      l-1.4-0.6l0-1.8l0.9-0.9l1.6-0.3l2.2,0.9l0.3,0.5l-1.2,0.3L413.9,193.8z M371.4,240.5l0.1,0.5l0.7-0.1l0.9-0.9l0.7,0.4l-0.1,1.3
      l-0.4-0.1l-0.1-0.2l-0.6,0.7l-0.1,0.4l-0.7,0.3l-1.2-1.2l-0.8-2l1.8,0l-0.2,0.5L371.4,240.5z M374.1,307.5l-0.2,1.1l-0.8-0.1
      l-0.8,0.2l-0.7-1.1l-0.3-1.8l0.1-0.4l0.5-0.4l0.4,1L374.1,307.5z M380.5,312.1l-0.9,0.1l-1.2-1.2l-0.1-0.5l0.5-0.3l-0.3-0.9l0.1-0.4
      l0.9,0.7l0.5,0.9l-0.5,0.2l0.8,0.9L380.5,312.1z M382.6,311.3l1.5,0.5l0.6-0.2l0.7,1.2l-1.2,0.8l-0.1,1l0.5,0.6l0.2,0.9h-1.2
      l-0.6-0.7l-0.3-0.9l-0.6-0.7l-0.7-0.5l0.4-0.7l0.2-1L382.6,311.3z M378.6,316.7l-1.2,0.8l-0.4-0.2l0.1-1.4l0.7-0.6l1.2-0.1
      L378.6,316.7z M376.2,317.5l-1,0.2l-0.6-0.7l0.2-0.5l0.7-0.6l0.8,0.1l0.2,0.7L376.2,317.5z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="france"
        d="M156.3,511.2l0.7-0.2l1.2-0.7l1-0.2l0.6,0.6l0.3,0.3l0.6,1.3l0.2,1.3l0.3,1.1l0.5,0.4l1.9,0.1
      l1.4,0.4l0.4,0.4l0.5,2.4l0.3,0.3l0.3-0.3l0.3-0.4l0.5-0.1l0.8,0.2l1.1,0l1.1,0.3l1.5,1.4l-0.1,0.4l-0.3,0.8l-0.1,0.6l0.3,0.3
      l0.4,0.6l-0.1,0.7l-0.4,0.5l-0.2,0.4v0.3l0.1,0.2l0.3,0.2l2.3,0.3l2.1-0.3l1.3-0.8l0.2-0.8l0.4-0.9l0.8-0.8l0.5-0.3l0.5,0.3
      l-0.8,3.2l0.6,0.8l0.1,1.2l0.2,1.1l0.7,0l0.9,0.2l0.6,0.4l0.7,0.6l1.1,0.6l0.7,0.2l0.3,0.5l0.6,0.6l1,1.2l0.9,0.8h0.4l0.8-0.3
      l1.2-0.2l0.9,0l0.4,0.6l0.9,0.3l0.3,0.2l0.4,0.4l0.6,0.2l0.7-0.2l0.5-0.5l0.7-0.2l0.7,0.1l0.4,0.3l0.8,0.4l0.4-0.1l0.9,0.3l0.9,0.9
      l0.4,0.9l0.1,0.5l0.4,0.5l1.5,2.3l0.5,0.1l0.5-0.3l0.3-0.5l0.5-0.1l0.8,0.2l0.5,0.3l0.2,1l0.2,0.2l0.3-0.2l0.6,0l1,0.2l1.3-0.3
      l1.1-0.4l0.5,0l0.9,1.2l1,0.4l2.2,0.4l2.4,0.5l0.9,0.4l0.6,0.2l0.1,1.5l-0.2,0.2l-2.4,3.1l-1,1.1l-0.5,1.6l-0.3,2.4l-0.7,2.3l-1,2.2
      l-0.4,1.6l0.3,1.1l-0.2,1.7l-0.6,2.3l-0.1,1.7l0.4,1.2l0.6,0.2l-1.4,0.8l-0.3,0.7l-0.6,0.9l-0.9,0.4l-0.9,0.1l-0.7-0.1l-0.4-0.4
      l0-0.3l-0.4-0.3l-1,0l-1,0.6l-0.8,1l0.2,0.6l0.7,0.2l0.2,0.2v0.3l-0.3,0.3l-0.3,0.6l-1.5,1.8l-1.6,1.8l-0.2,0.5l-0.5,0.4l-2,1
      l-0.2,0.4l-0.1,1.6l-0.2,1.3l-1.5,1.2l-1.5,1.2l-0.4,0.7l-0.3,0.9l-0.5,1l-0.1,0.5l0.7,0.8l-0.1,0.7l-0.2,1l-0.7,0.7l-0.8,0.4l0,1.1
      l0.4,0.1l0.9-0.1l1.3-0.8l0.9-1l-0.5-0.9l-0.1-0.2l0.1-0.2l1-1l1.3-0.6l1.7-0.1l2.1,0.4l0.2,0.2l-0.1,0.6l0.2,0.9l0.4,0.7l-0.5,1.9
      l0.4,0.6l0.6,0.7l0.5,0.6l0.7,0.6l0.6,1l0.2,0.6l-0.9,1l-1.6,0.9l-0.2,0.6l0,0.7l0.2,0.5l0.9,0.7l0.9,1.5l0.6,1.3l1.3,1.3l0.3,0.4
      l-0.1,0.3l-0.3,0.5l-0.5,1.8l-0.5,0.3l-0.6,0.1l-1.6,1.3l-0.7-0.2l-1,0l-0.8,0.4l0.1,0.8l0.7,0.7l0.4,0.8l0.2,0.8l0.7,0.6l1,0.4
      l0.6,0l0.4,0.2l0.2,0.3l0.4,1.8l-0.3,0.5l-0.6,0.2l-0.3,0.7l-0.7,1.1l-0.4,0.9l0.4,0.8l0.2,0.6l-0.2,0.6l0.3,0.9l0.8,0.9l2.1,1.3
      l2,1l0.6,0.2l2.7-0.7l0.4,0.1l0.3,0.8l0.1,0.5l-0.3,0.8l-0.7,1.1l-0.8,0.9l-0.5,0.7l0.1,0.7l0,0.9l-0.6,0.3l0-0.2l-0.3-0.2l-0.2,0.1
      l-0.2,0.2l0,0.3l-1.4,0.6l-0.9,0.6l-3.7,3.5l-1.7,1l-0.3,0.6l-0.4,1.2l-1,1l-0.9,0.5l-2.2,0.5l-2.2,1.1l-1-0.5l-2.6,0l-1.6-1.3
      l-3.1-0.8l-1-1.9l-1.4-0.1l-0.9,0.1l-0.6-0.3l-0.1-0.6l0-0.6l-1,0.3h-0.8l-0.4,0.2l-0.4,0.3l-0.4-0.2l-0.2,0.1l0,0.4l-0.9,0.1
      l-1-0.2l-2.6-1l-0.4-0.1l-1.8-0.4l-0.7-0.4l-0.6-1l-0.4-0.3l-0.3-0.2l-1.7,0.5l-0.6,0.7l-0.9,0.9l-6.2,4.3l-1.1,1.8l-1.3,2.6
      l-0.1,1.2l0.6,3.9l1.3,2l0.2,0.5l-0.7,0l-1.2-0.3l-1-0.3l-0.9,0.2l-0.9,0.4L155,652l-0.6,0.1l-0.4,0.2l-0.2,0.5l0,0.3l-1-0.1
      l-2.3-0.7l-2-0.5l-1.2,0.5l-0.8,0.5l-0.5-0.1l-0.4-0.5l-0.3-0.5l-0.8-0.5l-1.8-0.7l0.1-0.3l0.3-0.5l0-0.3l-0.3-0.4l-1.7-0.5
      l-0.8-0.1l-0.5,0.3l-0.4,0.4l-0.9-1.5l-0.7-0.3l-1,0l-1.1-0.5l-1.2-0.6l-2.9-0.9l-0.8-0.1l-0.3,0.1l-0.2,0.6l-0.1,1.6l-0.1,0.2
      l-1.3,0.1l-1.6-0.2l-0.8,0.1l-0.7,0l-0.6-0.4l-2.8,0.5l-0.5-0.2l-0.7-0.7l-0.8-0.6l-0.6-0.3l-0.5-0.3l-0.5-0.1l-0.7,0.3l-1,0.1
      l-0.8,0l-0.4,0l-1.8-1.8l-0.3-0.5l-0.9-0.1l-1.1,0l-2.8-1.1l-1.3-0.6l-0.2-0.4l0-0.3l-0.2,0.1l-0.4,0.5l-0.2,0.4l-0.3,0.1l-0.4-0.1
      l-0.4-0.2l-0.2-0.3l0.2-0.5l0.4-0.7l0.2-0.8l0-0.7l-0.7-0.4l-1.1-0.2l-0.8-0.1l-1-0.4l-0.5-0.3l-0.5-0.8l0-0.6l1.9-0.5l1.7-2
      l1.6-7.3l1.2-8.8l0.9-1.7l1.1-0.5l-0.9-1.2l-0.6,0.6l-0.2,0.7l-0.3,0.3l0.7-8.1l0.5-3l0.8-3.1l1.6,1.2l1.4,1.3l0.7,1.1l0.9,3.6
      l0.7,0.8l1,0.8l-0.4-0.8l-0.7-0.6l-1.1-4.9l-0.7-1.4l-1.1-1.2l-3.4-2.5l-0.3-0.5l-0.2-0.9l1.1,0l1,0.5l-0.1-0.5l-0.3-0.5l-0.5-2
      l-0.4-4.7l0.1-0.8l-0.2-1l-1.1-0.2l-0.9,0l-0.9-0.4l-4.6-2.8l-1.6-2.9l-1.6-2.1l-0.4-0.9l0-0.9l0.8-2l-0.7-1.3l-0.7-0.2l-0.6-0.6
      l0.6-1l0.5-0.7l0.9-0.2l1.2,0.2l1.2,0.6l0.9,0.2l-2.7-1.6l-4.4,0.5l-1-0.2l-0.8-0.4l-0.3-1.2l0.6-0.5l0.6-1l-0.6-0.7l-0.8-0.3
      l-1.3,0l-1.2,0.2l-0.3-0.4l0.7-1.1l-0.6-0.4l-0.8,0.2l-1.2,0.2l-1.2-0.3l-1.1-1.3l-0.7,0l-0.5,0.2l-0.7-0.5l-0.8-0.1l-0.6,0.2
      l-0.7-0.7l-4.6-1.5l-2-0.2l-1.8,0.7l-1-0.2l-0.7-0.9l-0.6-1.6l-2.9-1.2l0.6-0.8l1.4-0.2l1.6-0.6l0.6-0.7l-1.2-0.8l-0.9-0.2l-0.4-0.3
      l-0.4-0.8l0.5-0.3l0.4,0.2l1.1,0.1l1.9-0.2l-0.7-0.8l-0.7-0.2l-0.3-0.2l-1.5-0.1l-0.7,0.3l-1.6-0.1l-0.3-0.8l-0.2-0.7l0.5-1.6
      l2.2-1.4l5.5-1.5l2.4,0.2l1.6-0.3l2-1l0.9-0.8l2.8-0.5l2.7,0.9l2.5,3.3l1.2,1.1l2.9-2l4.3,0.1l0.9,1.1l0.4-0.9l0.8-1.1l0.6,0.5
      l0.3,0.7l4.5-0.2l0.7-0.2l-1.2-0.8l-1-1.9l-0.2-7l-1.3-2l-1.4-3.2l-0.7-1.9l-0.1-0.7l0.2-0.9l1.8,0l1.4,0.2l2.6-0.7l1.3,0.5
      l-0.1,1.5l0.4,1.9l0.4,0.9l0.7,1l2.1-0.1l2.3,0.6l2.9,0.1l4.2,1l1.8-0.6l1.7-1.3l3.3-0.8l0.3-0.4l-1.9,0.2l-1.7-0.8l-0.2-0.9
      l0.2-0.8l0.7-1.8l5-2.9l3.6-0.9l3.8-1.6l1.9-1.6l1.3-2.1l0.4-0.5l0.5-0.4l-0.5-0.8l0.3-8.1l0.4-1.5l0.7-1.2l1.1-0.9l1.7-1l6.2-1.4
      l0.9-0.6l0.1,0.9l0.5,1.1l0.3,0.6l-0.3,0.8l0.2,0.7l0.8,1.2l1.1,1.1l0.9,0.7L156.3,511.2z M108.9,595.4l-0.4,1.5l-0.8-1.3l-1-1.2
      l-0.2-1.1V593l1.2,0.8L108.9,595.4z M233.8,645.8l-0.3,2.3l0.2,0.7l0.4,0.5l0.2,0.5l0.4,6.2l-0.1,0.5l-1.4,2.5l-0.3,0.7l-0.1,3
      l-0.2,0.8l-0.5,0.8l-0.9,2.6l-0.8,1.2l-2.1-1.4L227,666l-0.6-0.7l-0.4-0.5l0.3-0.6l0.6-0.6l0.1-0.5l-1.4-0.6l-0.6-0.4l0-0.7l0.5-1
      l-0.2-0.9l-0.8,0l-0.6-0.1l-0.1-0.4l0.4-0.6l0.6-0.7l0-0.8l-0.7-0.4l-0.6-0.7l-0.3-0.9l0.5-0.6l0.8-0.4l-0.6-0.9l-0.4,0l-0.3-0.2
      l0.3-0.4l0.6-0.6l0.9-1.9l1.2-0.9l2.1-0.6l0.6-0.3l0.5-0.7l0.6-0.4l0.7,0.1l0.7,0.3l0.4,0.3l0.3-0.3l0.3-0.9l-0.2-0.7l0.1-2.1
      l0.4-1.2l0.6-0.1l0.6,0.6l0,0.6l0.2,1.3L233.8,645.8z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="england"
        d="M110.3,511.6l-1,0.6L109,513l-0.2,0.3l-0.6,0.2l-0.6,0L105,512l-0.6,0.1l0.6-0.7l1.5-0.6l0.9-0.7
      l2,0.7L110.3,511.6z M73.6,461.9l0.5,0.4l1.2-0.1l-0.4,0.8l-1.4,0.9l-0.9,0.9l-1.1,0.7l-0.5-0.9l-0.6,0l-1-1.6l-0.2-2.5l1.2-0.6
      l1.7,0L73.6,461.9z M48,423.6l1.7,0.5l1.2,0l1.1,1.5l0.6,2.3l1.4,2.3l1.8,2l0.1,1.2l-0.6,0.7l-1.3,0.8l0,0.9l0.9-0.4l0.8-0.2
      l1.8,0.2l0.6,0.9l0.4,1.3l0.2,1.1l-0.2,1.2l-0.5-0.4l-0.5-1l-0.6-0.5l-0.6-0.3l0.3,1.4l-0.1,1.9l0.3,0.2l0.9,0l-0.6,2l-1.2,0.5
      l-1.4,0.2l-0.3,0.7l-0.3,0.9l-0.7,1.3l-1,0.8l-1.2-0.2l-1.2-0.6l-1-0.1l-0.7,0.4l-0.5,0.3l-0.4-0.1l-1.3,0.1l-1.2,0l-0.2-0.5
      l0.2-1.6l-0.3-0.4l-1.1-0.2l-0.4-0.4l-0.7-1.1l-0.1-0.5l-0.1-0.7l-0.7-0.9l-0.8-0.6l-0.5,0l-1,1.1l-0.8,1.1l0.3,0.5l0.3,0.7
      l-0.4,0.5l-1.3,1.2l-0.2,0.4l-0.4,0.2l-0.6-0.3l-1.6,0.1l-0.7-0.2l-0.8-0.9l-2.1-0.6l-0.3-1.4l-0.4-0.3l-2.3-2.3l-0.3-0.8l0.3-0.5
      l0.9-0.7l2.9-1.2l0.5-0.5l0.1-0.4l-0.9-0.5l-0.8-0.5l-0.3-0.3l0-0.3l0.4-0.4l0.9,0l0.7,0.2l0.6-0.4l1-0.4l0.7-0.4l0.6-1.2l0.6-1
      l0.1-0.6l0.5-2l0.3-0.5l1.9-1.3l0.5,0.7l0.9,0.2l0.8-0.7l1-2.1l0.7-0.1l0.8,0.2l1.5-0.3l2.6-1L48,423.6z M62.9,419.4l-1.5,0
      l-0.5-0.2l-0.6-0.5l-0.7-2.8l0.3-1l0.3-0.5l0.3-0.4l0.8-0.2l0.8,0.5l0.3,0.5l0.7,1.9l0.1,1.6L62.9,419.4z M50.9,409.4l0.4,2.7
      l0.4,1.6l0,0.6l-0.4,0.8l-1.9,1l-0.6,0l0-0.3l0.4-1.1l-0.4-1.2l0.2-0.9l-0.2-0.2l-0.4,0.1l-1.4,1.5l-0.5,0.1l-0.1-0.3l0.3-1.2l0-0.8
      l0.2-0.5l0.4-0.5l0.4-0.3l0.4,0l0.4,0.4l1.1-1L50.9,409.4z M52.8,411.8l-0.2,0.2l-0.6-0.1l-0.2-0.3l-0.1-0.5l0-0.9l0.4-0.7l1.5-1
      l-0.7-0.4l0-0.3l0.4-0.9l1.6-1.3l0.4-0.2l0.4,0l-0.8,2.4L52.8,411.8z M55,400.8l-4.7,1.2l-1.6-0.1l-0.1-0.6l0.3-0.4l1.3-0.4l0.5-2.8
      l-2-1.3l-0.1-0.4l0.2-0.6l0.2-0.3l1.2-0.7l0.5-0.1l0.4,0.1l0.9,0.8l1,1.6l1.3,0.3l0.9,0.7L55,400.8z M45.3,395.7l-0.7,0.1l0-0.3
      l1.2-1.4l0.7-0.2l0.3,0.1l-0.5,0.8L45.3,395.7z M35.8,387.6l-1,0.3l-0.4-0.2l-0.1-0.3l0.2-0.7l0.8-0.3l0.6,0.4l0.1,0.4L35.8,387.6z
      M49.2,387.6l-0.3,0.3l-0.4-0.1l-0.4-0.4l-0.6-1l1.3-0.7l0.5,0.4l0.2,0.5l0,0.5L49.2,387.6z M37.8,384.4l-0.5,0.1l-0.6-0.1l-0.4-0.3
      l-0.4-1.3l-0.1-0.8l0.2-1.5l0-1.8l1.3-0.1l0.3,0.3l0.2,5.3L37.8,384.4z M50.7,376l0,1l-0.2,1.1l0.3,1.2l0.1,0.9l0.5,0.3l0.3,0.4
      l2.2,0.4l2-0.1l0.4,0.3l0,0.6l-0.3,0.6l-1.2,1.1l-1.4,1.8l-0.4,0.4l-0.4,0l-0.3-0.2l-0.2-3.2l-1.5,0.4l-1.2,0l-0.7-0.4l-0.5-0.8
      l-0.9-1.9l-2.7-0.8l-0.8-1.1l-0.2-0.6l0.1-0.4l0.6-0.8l0.7,0.3l0.4-0.2l0.3-0.4l0-0.3l-0.4-0.7l0-0.2l2.8-0.9l0.2-1.4l0.6-0.1
      l0.7,0.5l0.9,1.4L50.7,376z M38.3,372.1l1.3,1.2l-1.1,2l-1.6,0l-2.3-1.5l0-0.3l0.2-0.4l0.3-0.4l0.4-0.1l0.6,0.2l0.8-0.4l0.6,0.1
      L38.3,372.1z M50.1,357.2l-1.5,3.9l-0.6,0.1l-0.5,1l-1.6,1.1l1.4,0l0.4,0.4l0,0.8l-0.3,0.4l-1.8,1.8l-1.2,0.7l-1.3,1.8l-0.7,0
      l-0.7,1.2l-0.5,0.5h-0.3l-0.4-0.3l-0.8-1.1l1.5-1.1l0.1-0.6l1-0.7l-0.1-0.2l-1.6-0.9l-0.6-0.6l0.1-0.3l0.8-0.7l-0.4-0.1l-0.3-0.4
      l-0.5-0.1l-0.1-0.4l-0.1-0.9l0.1-1l0.5-0.4l0.2-0.5l0.2-0.1l0.7,0.2l0.7,0.8l0.9-0.3l1,0.1l0-0.2l-0.7-1.9l0.1-0.4l0.4-0.4l2.3-1.4
      l2.9-2.3l0.7-0.4l0.2,0.3l0.3,1.2L50.1,357.2z M86.3,353.8l0.1,1.8l-0.1,0.6l-0.3,0.7l-0.9,1.3l-2.3,1.8l-4.3,4.1l-2.5,2l-0.3,1
      l-0.2,1.4l1.5,0.3l0.6,0.5l-0.4,0.7l-2.2,2.4l-0.7,2.2l1.7-0.1l1.4-0.4l2.8-1.3l2.6-1l1.3,0l2.5,0.8l0.6,0l1-0.4l1.1-0.1l7.2,0.2
      l2-0.5l1.3,0.6l1.1,1.4l1,2.6l0,0.4l-0.6,1.2l-1.2,1.5l-1,2l-0.3,1.1l-0.2,1.2l-0.3,1.1l-2,5.1l-2,2.8l-0.9,2l-1.1,1.6l-1,1
      l-1.1,0.7l-3.2,0.7l-0.9,0.5l-1.1,0.9l-1.1,0.4l1.3-0.1l1.3-0.5l2.4-0.2l2.7,1.7l-0.3,1.4l-1.1,1.1l-2.5,0.2l-2.3,2.4l-1,0.7
      l-1.1,0.4l-1.4-0.1l-2.5-0.6l-1.1-0.7l1,1.1l1.1,0.6L87,409l0.4-0.1l2.1-1.4l2.8,0l5.3,2.6l1.5,2l2.2,2.8l1.2,1.1l0.9,1l0.5,1.5
      l1,4.9l1.2,4.7l1.5,5.1l0.7,1.4l0.9,1l4.6,2.3l1,0.8l1.8,2.2l1.7,2.3l1.6,1.8l1.7,1.4l-0.8,0.7l-0.6,1.2l0.4,1.6l0.7,1.5l1.4,2.4
      l1.2,2.6l-0.5-0.4l-0.5-0.2l-0.7,0.1l-0.6-0.1l-1.2-0.8l-1.1-1l-2.2,0.4l-1.2-0.2l-1.1,0l2.1,0.6l2.2,0l4.9,4.4l1.7,2.6l1,3.4
      l-0.7,1.5l-1.1,1l-1,1.1l-0.9,1.3l2.7,1.9l0.6-0.1l0.6-0.3l0.6-0.6l1-1.5l0.5-0.5l1.7-0.2l1.4,0.1l1.4,0.3l1.3-0.1l2.5,0.7l1.3,0.6
      l3.2,2.7l0.7,1.5l0.3,1.9l0,2.1l-0.5,1.9L142,482l-0.4,2.2l-0.3,0.8l-0.4,0.6l-1.7,1.7l-1.1,0.7l-0.5-0.3l-0.5,0l-0.1,0.4l0.5,0.9
      l0,1.1l-1,0.8l-1,0.3l-1.7-0.4l-2.4,1.5l1.7,0.8l0.3,0.8l-0.4,1.4l-1.1,0.6l-1.2,0.3l-1.2,0.1l-1,0.3l-1,0.7l1.2-0.3l0.8,0.3
      l0.5,1.2l0.5,0.3l2.4,0.5l1.5,0l2.8-0.3l1.4,0l0.5,0.2l0,1l-0.2,2.4l-0.4,0.5l-3.8,2l-0.8,1.4l-0.2,0.8l-2.2-0.1l-1,0.9l-1.8,0.6
      l-1.4,0.6l-1.3,0.8l-1.1,0.2l-4.8-0.9l-2.9,0.1l-3.9,0.8l-1-0.1l-1.5-0.8l-1.6-0.5l-1.8-0.2l-1.5-0.7l1,1.4l-2.1,1.4l-1,0.3l-1,0
      l-2.1,0.4l-1.9-0.2l0.3,1l0.5,0.8l-0.4,0.4l-0.4,0.1l-3.7-0.6l-0.5,0.1l-0.5,0.6l-1.3-0.3l-1.3-1l-1.4-0.7l-1.5-0.3l-1.2,0.1
      l-4.7,1.5l-0.9,1.5l-0.5,2.2l-0.7,1.9l-1.1,1.5l-1.3,0.2l-1.3-1l-2.4-1.1l-0.8-0.8l-0.3,0l-0.3,0.3l-0.9,0.3l-1,0l-1.5,0.3l-2.6,0.9
      l-1,0.6l-2.3,1.7l-0.5,0.5l-0.8,1.7l-1.2,0.3l-1.1-1.1l-1.3-0.4l-1.4,0.4l-0.8,0.6l-0.4-0.5l0-1l1-1.2l2.7-0.9l2.4-2.3l1.2-1.4
      l0.5-0.8l0.6-0.5l0.7-0.2l0.4-0.9l3.3-3.6l0.3-0.8l0.2-1.5l0.3-1.4l2.7-0.9l1.3-3l0.4-0.2l3.7-0.5l2.7,0l2.7,0.6l1.4,0.1l1.4-0.2
      l1.1-0.8l1.9-2.9l1.1-1.3l1.2-1.2l1.1-1.3l1.8-2.5L93,493l-1.5,1.4l-0.9,0.8l-2.8,0.8l-1.2,0.8l-2.1,1.8l-0.4,0.1l-3.2-0.4l-2.3-2.4
      l-1.5-1l-0.6-0.1l-0.6,0.3l-1.4,0.3l-1.4,0l0.7-1.1l1-0.6l-2.2-0.4l-0.6-0.3l-0.7-0.8l-1.7-0.1l-0.8,0.2l-1.4,1l-2.2,1.1l-2.6-1.5
      l-0.5-0.7l0-1.3l-0.4-1l-0.7-0.4l0.9-1.3l1.1-0.9l2.5-0.9l3.7-2.1l2.1-0.9l1.9-1.5l0.8-0.9l0.6-1.3l0.6-1.6l0.8-1.3l-0.8-0.3l-0.4-1
      l0.1-1l0.4-0.9l-0.3-1.1l-0.6-1.1l0-0.9l0.2-1l-1.5,0l-1.5,0.3l-1.4,0.7l-1.3,0.9l-1.2,0.2l0-0.7l0.5-0.9l1.3-1.3l1.4-1.1l0.5-0.8
      l0.4-1l0.7-0.8l1.8-1.4l3.5-1.6l0.5-0.1l1.4,0.2l1.4-0.2l1.2-0.6l1.2-0.1l2.7,1.7l-0.8-2.6l1.2-0.6l1.7,2.4l0.6,0.2l1.3-0.3
      l-0.5-0.4l-0.6,0l-0.8-0.4l-0.7-0.8l-1.1-2.4l0.1-1.4l0.7-1.5l0.8-1.4l-0.7-0.3l-0.6-0.5l-0.2-1.4l0.2-1.2l1.5-1.1l0.4-1.6l0.2-1.8
      l-0.2-0.8l-1.5,0.1l-0.7,0.3l-0.6,0.5l-0.7,0l-1.8-2l-1-1.5l-1.9-3.2l-0.3-1.9l1.5-4.2l2.3-2.7l2.7-0.9l-0.5-0.2l-4.1,0l-1.4,0.3
      l-1.3,1.1l-0.7,0.3l-0.8,0.1l-0.7,0.5l-0.7,0.8l-0.7,0.5l-1.4-0.1l-0.7,0.2l-0.5-0.4l-0.4-0.7l-0.5-0.2l-0.6,0.2l-1.2,1l-1.3,0.6
      l-1.5-0.6l-2-1.2l-0.4,0.4l-0.4,1.1l-0.3,1.7l-1.4-1.5l-1.2-1.9l-0.4-1.2l0-1.4l0.7-0.5l0.7,0.5l1.1-3.3l2.1-4.3l0.7-1.3l0.5-1.6
      l-0.1-1.1l-0.5-0.9l-2-2.1l0-1.7l0.2-1.9l0.5-1.2l0.2-0.2l2.6,0l-1-0.6l-2-1.7l0-0.6l0.5-1.6l-0.2,0.2l-0.4,0.7l-0.8,1.8l-0.5,0.4
      l-1.4,0.4l-0.3,0.9l-0.2,0.2l-0.7,0.1l-0.2,0.8l-0.2,0.1l-0.2-0.9l0-1.5l0.3-1.4l0.5-1.1l2.1-2.4l-1,0.8l-2.3,2.2l-1.2,1.5l-0.3,0.5
      l-0.1,0.4l0,0.5l0.5,2.6l-0.2,1.2l-2,7.8l-0.4,0.8l-0.3,0.4l-0.3,0.1l-1-0.1l-0.4-0.6l0-0.7l0.2-1l0.8-3.7l0.4-1l0.5-1l1.2-1.7
      l0-0.1l-0.8,0.3l-0.3-0.1l-0.2-0.3l0.2-5l0.6-1.7l0.2-2.4l0.6-2.1l0.6-1.5l0.5-1.9l0.7-0.9l0.2-1.3l0.8-1.4l0.6-1.5l-0.3,0.2
      l-4.1,3.9l-1,0.7l-1.4-0.2l-1.1-0.4l-0.8-0.9l-0.4-1.8l-1,0l-0.9-0.3l0-0.2l1.1-1l1.8-0.3l1.7-1.6L54,389l0.1-0.3l1.3-0.9l1.7-3
      l0.3-2.8l-0.8-1.3l-0.3-0.9l-1.6-1l-0.3-1.2l0.2-0.7l0.5-0.7l0.8-0.5l1.3-0.5l-1.1-0.5l-0.4-0.6l-0.3-0.9l0-0.5l0.6-2.4l0.3-1
      l0.7-1.3l3,0.1l0.4-0.6l0.3,0l1.6,0.5l-0.2-0.5l-2.5-3l-0.2-0.6l0.7-1.6l0.1-0.7l-0.1-0.8l0.2-0.6l0.8-0.3l2.5,0l0.6-0.3l-0.3-0.8
      l-0.6-1l-0.1-0.9l0.1-0.8l0-1.6l0.1-0.7l0.6-1l0.5-0.3l0.6-0.2l1.3,0.3l0.5,0.4l0.6,1l0.4-0.1l1.7-1.1l0.5-0.2l0.7,1.2l2.9-1
      l3.9-0.4l2.3-0.7l2.4-0.2l2.3-0.7l2.4,0.3l0.1,0.4l-0.1,0.6L86.3,353.8z M88.4,348.7l-0.1,0.1l-0.4-0.4l-0.7-1.5l1.1-0.3l0.5,0.2
      l-0.2,0.6L88.4,348.7z M85.7,347.6l-0.7,0.3l-0.7,0l-1-1.3l-0.4-0.9L83,345l0.4-0.2l1,0.3l0.5,1.1l0.1,0.7l0.1,0.3l0.6,0.3
      L85.7,347.6z M86.9,342.3l-0.2,0.6l0.9,0l1.3,0.5l0.8,0.1l0.7,0.6L90,345l-0.4,0.3l-0.4,0.1l-1.5-1.1l-2,0.5l-0.4-0.1l-0.3-0.3
      l-0.1-0.4v-0.8l-0.1-0.2l-0.7,0.7l-0.3-0.1l-0.2-0.3l-0.1-0.7l0.1-1L84,340l0.7-0.3l1.1,0.2l1.2,0.8l0.4,0.5l0,0.4L86.9,342.3z
      M92.9,337.7l-1.3,0l0.7-1.3L93,336l1.5,0.1l-0.3,0.6L92.9,337.7z M90.8,338.7l-1,0.6l-0.4-0.5l-0.1-1.5l-1.2-0.6l-0.6-0.4l-0.5-0.7
      l0.1-0.2l0.8-0.3l1.3,1.3l0.5,1.1l1,0.3l0.1,0.2L90.8,338.7z M107.4,307.4l0.2,1.7l0.6-0.4l0.9,1.6h0.5l0.8-0.6l-0.2,1.5l-0.8,4.1
      l-0.2,0.7l-0.2,1.2l-0.2,0.2l-0.2,2.5l-0.5,0.8l-0.5,2l-0.2,0.2l-0.7-0.8l0.7-3l0.3-1.7l-0.2-0.9l-0.4-0.8l-1,0l-0.9,0.4l-0.2-0.5
      l0-0.6l-0.2-0.2l-1.1,0l-0.3-0.2l-0.2-0.6l0-0.5l1-0.4l1,0.1l1.4-1l-0.9-3.2l-1.2-0.3l-0.3-0.3l0.2-0.5l0.6-0.3l1-1.6l0.6-0.3l0.7,0
      L107.4,307.4z M110.5,307.9l-0.3,0.3l-1.1-2.4l0.8-2.8l1,0.1l0.2,0.7l-0.1,0.7l-0.5,0.1l0,0.2l0.2,1.3l0,1.5L110.5,307.9z
      M113.7,300.8v0.3l-0.6,2l0,0.8l-1-0.1l-0.2-0.2l-0.2-1.2l0.1-1.2l0.1-0.3l0.3-0.1l0.3,0.2l0.5-0.6l0.3,0L113.7,300.8z"
      />
      <path fill="#333333" stroke="#F4F4F4" strokeWidth="0.560223" d="M93.3,533.3l-0.4,1.2l-1.1-0.4l-0.1-0.3l1.2-0.7l0.3,0L93.3,533.3z" />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        d="M60.2,745.9l0,0.2l0,0.1l0,0.4l-0.1,0l-0.1-0.2l0-0.3l0.1-0.1l-0.1-0.2L60.2,745.9z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="greece"
        d="M431.2,662.9l1,0.3l0.6,0.5l0.4,0.5l0.6,0.4l0.4,0.1l0.3,1.4l0.2,1.7l-0.3,0.7l-0.8,0.2l-2.4,1.6
      l-0.1,1.5l0,0.7l0,0.5l0.3,0.4v0.6l-0.3,0.7l-1.1,1.1l-0.7,0.9l-0.8,1.2l-0.5,0.1l-0.4,0.2l-0.3-0.6l-1.8-1.2l-4.2-0.7l-2-0.9
      l-0.9,0.2l-1.7-1l-1.2,0.4l-2.5,1.7l-1.3-0.2l-1.4-1l-0.9-0.2l-1.1,0.5l-1.8,1.9l-1.8,1l-1.6-0.4l-2.1,0L401,679l0.4,0.8l1.2,1.3
      l-0.6,1l0.4,1l0.8,0.2l1.2-0.1l2.1,1.2l0.9,1.3l0.6,1.4l-1.3-1l-0.9-1l-1.2-0.4l-1.7-0.8l-1.1-0.1l-1.1,0.6l-0.1,0.7l1.2,1.2
      l1.1,0.8l0.6,0.6l0.4,1.4l-0.2,0.5l-0.4,0.4l-1.3-0.9l-2-3.1l-2.8-0.6l-0.5,0.6l0.6,1.6l0.4,0.6l2.4,1.7l-0.2,0.4l-0.4,0.2l-2.7-1
      l-0.8-1.5l-0.2-1.9l-2.5-1.3l-2.4-1.5l-0.5-1.4l0.5-0.5l0.3-1l-1.3,0.2l-0.8,0.6l-1.3,0.6l-0.1,1l0.2,0.9l-0.4,1.4l-0.4,2.4l0.3,1.3
      l2.9,3.6l1,2.6l0.7,1l1.5,1.1l1.5,2l0.6,1l0.5,1.7l-1.3,1.1l-0.7,0l-0.4-0.5l0.6-1.2l-0.1-0.7l-2-1.1l-0.8,0.4l-1,0.7l0.6,1.3
      l0.6,0.9l0.3,1.2l1.2-0.1l-1.6,1.4l-1.5,0.7l-1.5,0l-0.9,0.1l-0.3,0.3l0.8,0.2l0.6,0l1,0.7l2.9,0.9l1.4,1.1l1.4,0.1l1.4,2l2.4,0.5
      l1.3,2l1.8,0.4l1.5,0.7l0.4,0.7l0.2,1.3l0.1,2.7l0.3,2l0,0.6l-0.1,1l-0.4,0.5l-0.6,0l-1.1-1.5l-1.7-1.6l-1.8-1.9l-0.5-0.3l-0.4,0
      l-1,0.6l-2.7,0.5l-1.2,0.7l-0.5,0.2l-0.1,0.4l0.6,0.4l0.7,0.9v1.2l0.6,1.4l0.8,0.4l1,0l0.6,0.3l0.1,0.6l0.6,0.7l0.4,0.5l0,0.3
      l-2.8,0.9l-0.6,0.4l-0.5,0.2l-0.7-0.4l0-1.1l-1-0.6l-0.9-0.5l-1-0.2l-0.9-0.8l-0.6,0.6l0.5,2.2l1,1.5l1.7,4l0.8,2.4l0.2,1.1
      l-0.4,1.9l0.8,1.4l0.6,1.4l-0.6-0.1l-0.5-0.5l-0.9-0.6l-1.8-2.3l-0.6-1.4l-0.7-0.1l-1.3,0.2l-1.4,3.1l0,1.8l-0.7-0.4l-0.6-0.5
      l0.1-1.9l-0.1-0.8l-1.7-2.6l-0.8-0.3l-0.4-0.9l-0.6-0.9l-0.8,0.2l-0.7,0.4l-0.2,1.4l-0.1,1.3l-0.5,1l-1.8-1.8l-1.8-3.2l0-1.7
      l1.3-1.6l-0.2-1.1l-1.3-2.3l-1.8-1.5l-1-0.4l-0.5-1.5l-1-0.8l-0.8-0.4l-0.2-0.5l0.2-0.4l1.9-1.6l1.1-2.5l0.6-0.1l1.1,0.6l1.3-0.2
      l1.1-1.5l0.9-0.8l1.5,0.1l3.4,2l3.7,1.1l1.8,1l1,1l0.5,0.2l0.9,0.1l0-0.7l-0.3-0.6l0.7-0.3l2,0l0.4-0.3l0.4-0.5l-0.4-0.6l-0.6-0.3
      l-0.7-0.1l-0.5-0.2l-0.7,0.2l-1.1-0.5l-0.6-0.4l-0.3-0.4l-2-0.8l-1.9-1.4l-0.4,0.8l-0.8,0.4l-1.1,0.1l-3.1-0.9l-1.9,0.7l-1,0.2
      l-0.8,0l-1,0.3l-1.1,0.2l-1-1.3l-0.4-1l-0.3-0.2l0,0.9l-0.3,0.7L371,714l-0.8-0.6l-0.6-1.8l-0.8-2.2l-1.4-1.8l-1.1-0.5l-0.1-1
      l0.1-0.8l1.4-0.2l2.1,0.8l0.4-0.2l0.5-0.4l-0.1-0.9l-0.3-0.8l-0.6,0l-0.4,0.1l-1.3-0.2l-1.7,0.4l-0.8-0.4l-0.3-0.5l-1.4-1.2
      l-1.2-1.6l-2-1.1l-1.3-3.3l-1.1-1.4l-1.1-1l0.2,0l0.4,0.2l0.8,0.6l0.9,0.1l0.5-0.4l0.3-0.3l0.2-0.6l0.1-0.7l0.1-0.2l0.7,0.1l0.2-0.2
      l0-0.6l-0.4-0.7l-0.4-0.9v-0.4l0.3-0.2l0.5-0.4l0.3-0.5l0.6-0.2l0.8,0l0.9-0.2l0.6-0.5l0.1-0.5l0.4-1.4l0.2-0.7l0.4-0.6l0.2-0.9
      l0.4-0.8l0.9-0.3l0.8-0.4l0.6-1.1l0.3-0.9v-0.6l-0.5-0.9l-0.4-0.9l0.1-1.1l1.6-0.1l0.6-0.1l2.1-0.1l0.9-0.6l0.7,0.1l1.4,0.5l0.6-0.4
      l1.8-0.8l1.8-2.4l0.7-0.4l1.7-0.1l0.5-0.3l0.6,0.1l1.9,0.5l1.1,0.1l1.3-0.3l1.4-0.6l0.4-2.1l0.3-0.3l0.9-0.1l0.7,0l1.3,0.2l1.5,0.1
      l1-1l1.5-0.1l0.7-0.1l1.2,0.2l1.2,0l1.5-0.4l1.4-0.7l1.1,0.1l0.4-0.1l0.2-0.1l0.3-0.9l2-0.1l0.7,0.1l1.1,0l1.2-0.5l0.4,0l0.6,1.3
      l0.3,0.4l0.6,0.4l1.4,1l0.3-0.3l0.6-0.3l1.7,0.5l1.6,0.8l1.4,1.1l1.5-0.3l1.7-0.5l1.1-0.2l1.2-0.1l0.7-0.2l1.6,0.3l1.7-0.6l0.8-0.5
      l0.2-0.8l-0.1-1.3l-0.4-1.3l-0.4-0.5l-0.1-0.5l0.2-0.5l0.3-0.3l1.1-0.3L431.2,662.9z M413.1,679.9l-1.5,0.5l-1.5-1l0-0.6l0.8-1.3
      l0.4-0.4l1.1,0.1l0.6,0.9l0.2,0.4l-0.2,0.7L413.1,679.9z M423.7,682.8l-1.3,0.4l-1.5-1.3l1.4-0.5l0.7,0.4l0.5,0.4L423.7,682.8z
      M420.8,689.5l-0.4,0.5l-0.3,0.9l-0.2,1.3l-0.7,0l-0.4-0.3l-0.1-0.5l0-0.6l-0.3,0l-0.2,0.7l-0.2,0.3l-0.7,0.1l-0.8-0.4l0-0.9l-0.2-1
      l0.1-0.4l2.1-0.1l0.6,0.7l0.7-0.4l0.3-0.5l0.9-0.3L420.8,689.5z M358,697.8l0.3,0.8l-1.5-0.5l-1.1-0.8l-0.9-1.9l-1.9-2.1l0-0.6
      l0.7-0.5l1.5-0.3l0.6,0.3l0.4,0.4l0.1,0.4l-0.9,0.8l-0.2,0.4l0.7,0.7l0,0.3l0.3,1.4l0.3,0.5l0.8,0.4L358,697.8z M432.2,699.4
      l-0.2,0.9l1.6,1.5l0.6,0.9l0.2,0.9l-0.1,0.3l-0.6-0.5l-0.5-0.2l0.2,0.6l0.5,0.6l-0.9,0.3l-0.9,0l-2.7-0.8l-0.6-0.8l1.6-1.2l0.3-0.5
      l-1.1,0l-1.2,1.5l-1.9-0.6l-0.6-0.6l-0.1-0.3l0.8-1.3l1.4,0l0.7-0.3l0.9-0.4l0-0.6l2.1-0.1L432.2,699.4z M402.7,702l-0.5,0.2
      l0.5-1.2l1-0.6l-0.4,1L402.7,702z M401.4,702.6l-0.5,0.5l-0.8-0.2l-0.8-1.7L401.4,702.6z M397.1,704.9l0.7,1.6l0.6,0.5l1.3,0.6
      l0.6,0.1l2.2,1.2l2.6,0.2l0.3,0.3l0.3,0.9l0.5,0.7l0.1,0.5l-0.3,0.6l0.4,1.8l0.7,1.8l1,0.8l1.2,0.3l1.2,0l0.3,0.3l-0.1,1.5l-0.5,0.6
      l-0.4,0.1l-0.3-0.2l-0.3-0.4l-0.3-0.2l-0.7,0l-0.5-0.6l-1.2-0.8l-0.2-0.5l0-0.8l-0.5-0.6l-0.5-1.1l-0.5-0.3l-0.3-0.5l0-0.2l-1.8-0.2
      l-1.5,0l-1.3-0.6l-0.4-1.6l-0.8-0.4l-0.6-0.5l-0.5-0.6l-1.2-1.2l-1.3-1l-1.3-0.7l-1.3-0.4l-1.1,0.5l-0.6-0.1l-0.1-0.3l1.4-0.7
      l1.9-1.3l1.3-0.4l0.6-0.1L397.1,704.9z M411.9,707.2l-1.2,0.4l-0.3-0.1l0.3-0.5l0-0.2l-1.2-0.8l0.1-1.1l0.1-0.3l0.9,0.6l0.2,0.9
      L411.9,707.2z M365.2,710.2l-0.5,0.1l-0.4-0.1l-0.4,0.1l-0.3,0.3l0-1.2l0.4-1.5l0.5-0.9l0.7-0.4l0.3,0.7l-0.1,2.4L365.2,710.2z
      M428.5,716l-1.1,0.8l-1.2-1.2l-0.2-0.4l0.9-0.5l0.5-0.8L427,713l-1.3-1.4l-0.1-1l2-0.4l1.1,0.8l0.6,0.1l-0.2,0.8l0.1,0.3l0.1,2.5
      l-0.5,0.3l-0.1,0.7L428.5,716z M366,714.3l-0.6,0.2l-0.7-1.4l-0.3-1l0.3-0.1l0.3,0.1l0.3,0.4v0.4l0.1,0.4l0.3,0.5L366,714.3z
      M364.3,713.5l0.1,1.7l0.8,0.3l1.1,1.5l-0.1,0.8l-0.2,0.3l-1.8-0.7l-0.4,0.3l-0.5-0.1l-0.3-0.9l0-0.3l-0.3-0.5l-0.2-0.2l-0.7,0.7
      l-0.5,0.1v-0.6l0.7-1.7l0.3-0.3l0.5,0.6l0.4-0.2l0.3-0.9l0-0.9l0.2-0.3L364.3,713.5z M398.7,720.3l-0.5,0.4l-0.5,0l-0.4-0.1
      l-0.2-0.3l0.2-0.1l0.3-0.6l0.2-0.2l0.4,0.1l0.2,0.2L398.7,720.3z M415.6,722.7l-0.3,1l-0.9-1.1l-1-0.8l-0.4-0.7l-0.6-0.4l-0.2-0.9
      l0.7-0.4l0.3,0l0.8,1.1l1.2,0.1l-0.1,0.7l0.4,0.9L415.6,722.7z M367.5,722l1.2,1.4l-1-0.4l-1.1,1l-1.3-1.2l-0.8-1.2l-0.2-0.5
      l0.8-1.1l0.8,1.1l1,0.2L367.5,722z M437.1,722l1.4,0.5l0.4-0.1l0.7,0.2l0.2,0.9l-0.9,0.1l-1.6,0.8l-0.7-0.2l-0.8-0.7l-1.3-0.1
      l-0.4-0.2l0.7-0.8l1.2-0.4L437.1,722z M408.2,725.4l-0.8,0.7l-0.1-1.1l0.5-1.1l0.7-0.1l0.3,0.5L408.2,725.4z M418.7,725.1l-0.4,0.9
      l-0.7-0.1l-1.2-1l-0.4-0.5l-0.2-0.5l0.5-0.1l0.6,0.5l1.6,0.3L418.7,725.1z M427.8,726.1l-0.5,0.1l0.2-0.6l1-1l1.5,0l1.3-0.5l0.3,0
      l-0.6,0.8l-1.1,0.7L427.8,726.1z M420.4,727.7l-1.1,0.1l0.1-1.1l0.4-0.3l1.3,0.6l-0.1,0.3L420.4,727.7z M415,726.6l-0.1,1.5l-0.3,0
      l-0.2-0.2l0-0.6l0.1-0.9L415,726.6z M409.1,728.8l-0.7,0.4l0.2-1l-0.3-0.5l0.3-0.4l0.4-0.4l0.2,0.4l0.4,0.6L409.1,728.8z M410.1,732
      l-0.4,0.2l-0.7-0.3l0.2-0.8l0.5-0.3l0.5,0.3l0.1,0.4L410.1,732z M422.1,734.3l-1,0.5l-0.7-0.8l-0.4-1.3l1.9-1.8l0.5,0.2l0.3,0.5
      l0,1.7L422.1,734.3z M419,732.8l-0.9,1.1l-0.8-0.1l-0.3-0.5l0.5-1.1l1-0.6l0.5,0.2l0,0.8L419,732.8z M439.4,734.4l-1.2,0.2l0.2-1.2
      l-0.6-0.9l0.9,0.5l0.6,0.6l0.3,0.1l-0.1,0.4L439.4,734.4z M412.4,735l-0.2,0.1l-0.3-0.6l-0.1-0.6l0.1-0.3l0.4,0l0.6,1.1L412.4,735z
      M425.8,736.9l-1,0.1l-0.3-0.1l0.6-0.2l0.4-0.3l0.2-0.3l1-0.6l0.7-0.7l0.7,0.5l-0.9,0.3L425.8,736.9z M438.5,737.8l-0.4,0l0.4-0.7
      l1.2-1l1.8-0.8l0.6-0.1l1,0.5l-1.9,0.9l-0.5,0.5l-1.4,0.1L438.5,737.8z M420.2,738.5l-0.2,0.2l-0.9-0.9l-0.3-0.5l0.4-0.4l1.3,1
      L420.2,738.5z M410.3,737.2l0,0.9l-0.1,0.3l-2.4,0.4l0.2-1l0.1-0.3l0.8,0.5l0.3-0.2l0.1-0.3L410.3,737.2z M449.2,740.3l-0.3,0.2
      l-0.6-0.7l0-0.3l0.6-0.4l0.3-0.1l0.1,0.3l0,0.6L449.2,740.3z M432.8,739.8l-0.9,0.3l-0.6,0.7l-0.7-0.5v-0.7l0.8,0.2l0.6-0.4
      l-0.2-0.4l0.6,0.2L432.8,739.8z M421.4,742.6l-0.5,0.8l-0.8-0.3l0.3-0.3l0.2-0.4l0-0.5l-0.2-0.3l0.1-0.1l0.7,0.6L421.4,742.6z
      M449,749.3l-0.8,0.3l-0.3,0l-0.4-0.7l0.5-1.6l-0.5-1l0-0.4l0.7-0.6l0.5-0.9l1.2-1l3-1.2l0.7-0.1l0,0.9l-1,2.3l-0.9,1.2l0.2,0.9
      l-1.4,0.3L449,749.3z M392.9,745.5l-0.1,0.6l-1.2-0.4l-0.3-0.6l-0.1-1.4l0.3-0.7l0.2-0.2l0.6,0.8l1.2,1.2L392.9,745.5z M441.2,755.9
      l-0.4,0.8l-0.5-0.7l0.2-0.8L440,754l1-1.8l0-0.9l0.8-0.5l-0.2,1.5l-0.6,1.2l0.6,1l0.3,1.1L441.2,755.9z M402.3,754.9l0.8,0.1l1.1,0
      l0.2-0.1l0.7-0.8l0.9,0l0.4,0.8l-0.9,0.4l-0.2,0.2l0.2,0.2l0.6,0.3l0.9-0.1l0,0.6l0.2,0.5l0.4,0.3l0.5,0.1l1.1-0.1l1-0.2l1.1-0.4
      l1.1-0.2l3.3,0.2l1.2,0.9l2.3,0.1l2.1,0.5l1.1-0.3l1.9-0.3l0.3,0.3l-0.2,2l0.1,0.6l0.5,0.3l0.5-0.1l0.7-0.7l1.6-0.5h1.6l1.4-1.3
      l0.4-0.1l-0.3,0.7l-0.2,1.6l-0.3,0.9l-0.1,0.7l-0.9,0.4l-1.4,0.1l-2.5-0.2l-2.6,0.3l-4.7,0.7l-4.8,0.3l-0.6-0.2l0-0.9l-0.1-0.6
      l-0.3-0.4l-1.5-0.4l-1.4-0.6l-5.5-0.9l-1.3-0.3l-2.1,0.2l-0.8,0l-0.5-0.3l-0.4-0.5l-0.2-1.7l0.3-1.7l0.5-0.4l0.2,0.5l0.5,0.2
      l0.5-0.5l0-0.8l0.3-0.7l0.4,0.3l0.3,1.1L402.3,754.9z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="croatia"
        d="M316.3,585.4l0.6,0.3l2.1,1.2l1.4,1.3l0.8,1.4l1.1,1.1l1.4,0.8l1.1,1.1l0.8,1.3l1.1,0.8l1.4,0.2
      l0.9,0.5l0.4,0.8l0.8,0.7l1.2,0.6l1.8,0.3l3.5,0.1l0.3,0l0.8,0.2l0.9-0.2l1.1-0.5l0.4-0.3l1.2-1.6l0.7,0.1l1.3-0.2l0.8-0.3h0.1
      l-0.1,0.4l-0.1,0.7l-0.6,0.5l0.6,1.1l0.6,1.9l-0.3,0.9l0.4,0.7l1.2,0.5l0.1,0.2l-0.4,0.2l-0.3,0.6l0,1.1l1,1l2.1,1l0.7,0.2l0.3,0.4
      l0.4,0.2l0.2,0.3l0,0.4l-0.2,0.3l-1,0.1l-1.1,0l-0.8-0.5l-0.1,0.3l0,0.4l-0.8,0.2l0.4,2.7l-0.2,0.8l-0.3,0.3l-0.3-0.1l-0.3,0
      l-0.2,0.3l0.1,0.6l-0.8,0.1l-1.2-0.3l-0.6-0.5l-0.1-0.5l0-0.5l-0.4-0.8l-1-0.8l-2-0.1l-0.8-0.3l-0.8-0.3l-0.9-0.2l-0.8,0l-0.9,0.2
      l-1.7-0.4l-0.6,0.5l-0.9,0.6l-0.7,0l-1.4-1.3l-0.4-0.1l-1.3,0.7l-0.5,0l-0.4-0.2l-1.7-0.5l-0.8-0.1l-0.6,0.2l-1-0.3l-2.4-1.7
      l-1.5,1.3l-3-0.3l-0.9,0.9l-1,1.7l-0.8,0.8l-0.7-0.3l-0.9-0.8l-1.5-1.9l-0.8-0.3l-0.9-0.1l-0.8,0.2l-0.4,0.4l-0.3,2.8l-0.3,2.5
      l0,1.5l1.7,1.4l2,2.4l0.6,0.3l0.3,0.8l0.5,2l0.5,2.2l1,1.5l0.9,1l1.1,0.9l1.4,1.4l1.1,1.6l0.3,0.6l2.2,2.1l2.1,2.1l1.9,0.7l0.3,0.4
      l0,1.6l0.2,0.6l1.3,1.7l2.6,2.5l0.3,0.6l0.1,0.4l-0.2,0.3l-0.7,0.3l-0.6-0.4l-2.4-2.4l-2.4-1.5l-2.7-2.9l-3.5-1.1l-2.4-1.3l-1.5,0.2
      l-1.6,0.4l-1,0l-0.7-0.2l-0.5-0.8l0.1-0.6l-0.1-0.8l-1.4-1.3l-1.9-1.2l-1.8-1.6l-3.7-4.3l-0.7-1.4l0.7-0.3l0.5,0l0.6-0.3l1,0
      l1.2,0.3l-1-0.9l-1.3-0.9l-3.4-3.6l-1-1.7l-0.1-1.8l0.2-2.5l-0.6-1.8l-2.6-2.3l-1-1.2l-1.9-0.7l-0.9,0.1l-0.5,0.9l-0.4,2l-1.7,2.7
      l-0.6,1.2l-0.9,1.5l-0.8,0.1l-0.5-0.1l-1.4-2.5l-1.3-1.9l-0.2-0.9l-0.1-1.1l-1-4.1l0.7-0.6l0.4,0.7l3.1,0.8l0.7-0.4l0.4-0.5v-0.3
      l0.3-0.1l1.1,0.5l0.9-0.1l1.4,0l1,0.1l0.7-0.4l0.9-1.5l0.3-0.8l0.4-0.2l0.3,0.1l0.2,0.7l0.5,0.6l1,1l0.7,0.5l0.6,0.2l0.6-0.4
      l0.6-0.1l1.8,0.8l1.5,0.2l1.1-0.4l-0.2-0.6l-0.4-0.7l-0.1-0.6l0.1-0.5l0.8-0.5l0-0.2l-0.9-1l0-0.2l2.1-1.1l2-0.6l0.3-0.5l0.2-0.7
      l0.1-1.3l-0.1-1.1l-0.8-1l-0.1-0.5l0.2-0.5l0.3-0.5l0.8-0.2l0.9-0.3l0.7-0.4l1-0.3l0.8-0.5l0.8-1.1l0.5-0.2l1.4,0.2l0.3-0.3
      l-0.2-1.6l0.3-0.4l0.5-0.2l0.2-0.2l1.2,0.2L316.3,585.4z M296.3,610.8l-1.4,0.4l-0.7-0.6L294,610l-1.2-0.2l-0.7-0.7l-0.1-0.3l1-0.8
      l0.5-1.3l0.7,0.8l0.8,1.5l0.4,0.4L296.3,610.8z M292.5,616l-0.1,0.6l-0.7-0.8l-0.4-1.4l-0.9-2.3l-0.1-0.7l0.5-0.6l0-0.7l-0.6-2.1
      l0.5-0.3l0.3,0l0.1,1.4l0.3,0.8l0.9,1l-0.2,1.7l0.2,2.4l0.2,0.5L292.5,616z M296.5,614.3l0.3,0.7l-1.1-0.7l-1-0.2l-0.2-0.5l0.1-0.4
      l0.2-0.4l0.7,0.1l0.1,0.4L296.5,614.3z M300.7,621.2l-0.3,0.4l-0.8-0.8l-0.7-0.6l-0.5-0.7l-1-0.8l-0.3-1l-1.5-2l-0.2-0.5l0.7,0.8
      l0.6,0.5l0.5,0.1l1.3,1.3l1.2,1.6l1.5,1.4l-0.3,0L300.7,621.2z M300.7,627.9l0.2,0.2l0,0.2l-0.6-0.2l-0.2,0.1l-2.9-3.5l-0.3-0.7
      l1,0.8L300.7,627.9z M301.2,625.7l0.2,0.6l-1.5-1.1l-0.6-0.7l-0.1-0.3L301.2,625.7z M302.9,627.1l0.8,1.2l-0.7-0.2l-0.8-0.7
      l-0.5-0.8L302.9,627.1z M319.4,638.4l-1.9,0l-1.6-0.3l-0.8-0.5l0.1-0.4l0.2-0.7l1.8,0.1l2.7,0.5l0.7,0.6l-0.2,0.3L319.4,638.4z
      M324.2,640.7l-0.8,0.2l-5.2-0.1l-1.5-0.3l-1.7-0.9l-0.3-0.3l1.7-0.2l1.6,0.2l0.5,0.6l4.3,0.5L324.2,640.7z M329.8,644.3l0.9-0.3
      l0.7,0.2l0.5,0.9l0.9,0.6l1.5,1l0.9,0.8l2.1,1.4l0.5,0.2l1.1,0.4l0,0.6l0.5,0.7l0.5,0.8l-2.2-1.5l-2-1.7l-3.9-2.6l-2.8-0.6l-3.8-2.1
      l-2.5-0.7l1-0.2l1.1,0l5.9,2.8L329.8,644.3z M317.9,642.8l2.2,0.4l1.6-0.2l1.4,0.3l0.9,0.5l0.2,0.2l-1.2,0l-1.3-0.2l-1.5,0.5
      l-1.3-0.3l-0.5-0.3l-0.4-0.4L317.9,642.8z M329.1,646.4l1.6,1.1L326,646l0.5-0.1l0.5,0L329.1,646.4z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="ireland"
        d="M38.1,426.7l-1.9,1.3l-0.3,0.5l-0.5,2l-0.1,0.6l-0.6,1l-0.6,1.2l-0.7,0.4l-1,0.4l-0.6,0.4
      l-0.7-0.2l-0.9,0l-0.4,0.4l0,0.3l0.3,0.3l0.8,0.5l0.9,0.5l-0.1,0.4l-0.5,0.5l-2.9,1.2l-0.9,0.7l-0.3,0.5l0.3,0.8l2.3,2.3l0.4,0.3
      l0.3,1.4l2.1,0.6l0.8,0.9l0.7,0.2l1.6-0.1l0.6,0.3l0.4-0.2l0.2-0.4l1.3-1.2l0.4-0.5l-0.3-0.7l-0.3-0.5l0.8-1.1l1-1.1l0.5,0l0.8,0.6
      l0.7,0.9l0.1,0.7l0.1,0.5l0.7,1.1l0.4,0.4l1.1,0.2l0.3,0.4l-0.2,1.6l0.2,0.5l1.2,0l1.3-0.1l0.4,0.1l0.5-0.3l0.7-0.4l1,0.1l0.5,0.7
      l0.2,0.7l-0.9,0.3l-0.9-0.1l-0.4,0.5l0,0.9l0.3,1.2l0.6,0.8l0.5,1.9l0.4,2.1l0.6,1.2l0.1,1.5l-0.1,0.8l0.1,1.4l-0.3,0.5l0.2,1.3
      l0.7,2.6l0.3,1.5l0.2,3.2l-0.5,1.2l-0.7,1.1l-0.5,1.3l-0.3,1.4l-0.2,2.3l-1.5,2.7l-0.6,0.7l-0.7,0.4l1.6,1.9l-1.3,0.8l-1.4,0.3
      l-1.6-0.5l-1,0.1l-0.9,0.6l-0.4,0.4l-0.3-0.2l-0.6-1.5l-0.4,1.6l-0.9,0.5l-1.6-0.1l-2.6,0.4l-1,0.4l-0.4,0.7l-0.3,0.8l-0.4,0.5
      l-0.5,0.3l-2,0.6l-0.4,0.2l-0.9,1.3l-1.2,0.8l-1,0.2l-0.9-0.8l-0.4-0.5l-0.4-0.2l-1.4,0l0.4,0.2l0.3,0.5l0.1,1l-0.2,1l-0.7,0.5
      l-0.8,0.1l-1.3,1l-1.7,0.3l-0.9,1l-5.7,1.6l-0.3,0l-0.8-0.4l-0.8-0.2l-0.8,0.1l-2.4,0.9L7.5,497l1.5-2.3l2-1.1l0.2-0.3l-0.6-0.2
      l-3.7,0.8l-1.3,0.7l-1.3,0.2l0.6-1l1.7-1.4l0.9-0.7l0.5-0.3l0.6-0.8l1.8-0.9l-5.7,1.9l-1.5-0.2l-0.4-0.5L1.6,491l-0.4-1.3l1.7-2
      l1-0.9l1.2-0.5l1.1-0.7l0.4-0.8l-0.5-0.3l-3.4,0.2L1,484.7l0.1-0.6l0.3-0.7l1.7-1.2l0.9-0.2l0.8,0.1l0.8,0.3l0.7,0.4l1.9-0.2
      l-0.8-0.8l-0.1-1.6l-0.6-0.5l0.8-0.7l0.9-0.4l1.5-1.5l0.5-0.2l3-0.4l3.2-0.8l3.2-1.1l-1.6-0.6l-0.8-0.8l-1.3,1.7l-0.9,0.6l-2.6,0.3
      l-0.8-0.2l-1.1-0.5l-0.4,0.2l-0.3,0.4l-1.7,0.8l-1.8,0.2l2.1-1.5l2.6-2.6l0.6-0.8l0.8-1.4l-0.3-0.6l-0.5-0.4l1.9-2.9l0.7-0.5
      l1.2-0.1l0.9-0.5H17l0.4-0.2l0.8-0.9l-1.2-0.6l-1.3-0.3l-3.9,0.3l-0.5-0.1l-0.5-0.3l-0.3-0.4l-0.2-1l-0.3-0.2H9.1l-0.9,0.3l-0.6,0
      L7,461.5l0.9-1l-1.2-0.2l-1.2,0.2l-1-0.3l0-0.6l0.5-0.6l-0.6-0.6l-0.1-0.8l0.6-0.4l0.7,0.1l1.4-0.6l1.8-0.3l-1.6-0.6l-0.6-0.5l0-0.7
      l0.1-0.6l1.8-1.1l2-0.5l-0.1-0.7l0.1-0.8l-2-0.2l-1.9,0.5l0.2-1.5l0.5-1.3l0.1-0.9l-0.1-0.9l-0.9,0.4l-0.1-1.3l-0.4-0.9l-1.4,0.6
      l0-1.2l0.4-0.8l0.7-0.4l0.7,0.2l1.3,0l1.3-0.6l1.8-0.2l2.9,0.2l2,1.8l0.5-0.3l0.8-1.1l0.4-0.1l3,0.5l1.9,0.6l0.5-0.2l-0.3-1.2
      l-0.6-0.9l0.8-1.1l1-0.8l0.7-0.4l1.5-0.5l0.7-0.4l0.4-1.5l0.7-1.2l-3.8,0.6l-3.6-1.4l0.6-1l0.8-0.6l1.3-0.4l0.1-0.5l0.7-0.4l1.1-1.2
      l-0.4-1.5l0.2-1.1l0.8-0.7l0.2-1.1l0.4-0.8l1.6-0.3l1.5-0.7l0.6,0.1l1.8-0.2l0.6,0.3l-0.1-1.3l1.1-0.2l0.4,0.3l0.2,0.9L34,425l0.2,1
      l-0.3,0.8l-0.6,0.6l0.5,0.6l-0.8,1.1l0.9-0.5l1.2-1.1l-0.1-0.9l-0.2-1.1l-0.3-1l0.2-1.1l0.7-0.7l1.8-0.3l-0.8-1.3l0.7-0.1l0.7,0.3
      l1.1,1l1.1,0.8l1.2,0.6l-1.1,1.2l-1.4,0.8L38.1,426.7z M6.2,450.3l-0.1,0.6l-0.9-0.7l-0.4-0.8L2.4,449l1-0.8l0.5,0.2l1.7,0l0.5,0.3
      L6.2,450.3z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        d="M71,444.9l-2.4,2.5l-1-0.4l-0.8,0.2l-0.2-0.1l0.5-0.9l0.6-2.1l1-0.8l1.2-2.2l1-0.6l0.3,0.1l0.2,0.2l0.5,2.5
      l-0.6,0.9L71,444.9z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="italy"
        d="M283.3,585l-0.2,1l-0.5,0.2l-0.9,0.6l-1,0.8l-0.9,0.9l-0.2,0.9l0.3,0.6l0.3,0.2l0.3-0.2l0.5,0.1
      l0.6,0.3l1,0.3l0,0.3l-0.2,0.4l-0.8,0.7l-0.7,0.8l-0.1,0.5l0.1,0.4l0.2,0.2l1.1-0.1l0.2,0.3l-0.5,2.1l0.2,0.4l0.9,0.3l0.7,0.5
      l1.3,1.3l0.5,1.1l-0.4,0.4l-0.8,0.2l-0.7-0.1l0.7-0.7l-1.8-2.4l-0.8,0l-1.1,1l-3-1l-0.6,0.4l-0.4,0.8l-1.1,1l-1.5,0.4l-1.7,1.1
      l-1.7,0.8l-1.3,0.6l-0.8-0.1l1.2-1.3l-0.5,0l-1.6,0.9l-0.9,0.8l-0.3,1.3l-0.3,2.1l0.7,0.6l1.2,2.8l1.5,1.2l-0.3,1.1l-0.4,0.9
      l-0.9,0.8l-0.8-0.6l-0.5,0l-0.4,1.8l0.7,4.8l1.1,3.3l1.1,1.5l2.4,2.3l2.5,1.2l4.6,3.8l2.5,1.2l0.7,0.6l1.5,2.9l1.3,3.3l1.4,5.2
      l1,2.6l2,2.9l4.2,4.1l3.8,3l3.5,1.9l2.8,0.3l6.6-0.4l1.1,0.2l1.2,0.5l0.3,1.3l-0.4,0.9l-1.4,0.9l-1.4,1.2l-0.2,1.7l1.3,1.2l6.3,3.1
      l6.5,2.6l2,1.3l2.3,2.1l5.6,2.8l1,1.4l3.4,3l1.5,2.3l0.3,1.8l-0.7,1.8l-0.3,1.3l-0.6,1.2l-1.5-0.5l-1.7-1.3l-2.5-5.2l-4.6-0.5
      l-0.9-0.4l-1.6-0.9l-0.1-0.6l-0.4-0.7l-0.4-0.2l-1.7-0.2l-1.2,0.8l-1.4,2l-1.6,2.9l-1.6,4.2l-0.1,1.7l0.9,1.6l2.7,0.9l2,1.5l1.4,1.5
      l0.1,3.7l0.6,2.1l-0.9,1.2l-1.7-0.3l-2.3,0.7l-1.6,1.3l-0.7,1.3l0.2,3.3l-0.3,1.3l-3.1,2.4l-1.6,2.4l-0.4,1l-0.6,1.1l-3.9,0
      l-0.9-1.4l0-2.1l0.7-1.3l1.4-0.6l1-2.7l-0.3-1.9l0.6-0.9l0.5-0.6l1.1-0.4l1.5-0.3l0.2-2.7l-1.2-1.2l-0.4-1.7l-0.6-3.2l-2-4.1
      l-1.1-3.7l-0.8-1.8l-1.3-1l-2.3,0l-1.1-0.3l-4-2.6l-0.3-0.4l0-0.7l0.7-1l-0.5-1.4l-0.5-1.3l-0.8-1.1l-0.9-0.6l-1.8,0.4l-0.6,0.3
      l-1.1-0.1l-0.9,0.5l-0.5,0l1.4-2l-0.4-0.5l-1.4-0.8l-1.9-0.1l-0.5-0.1l-0.3,0.5l-0.4-0.3l0-0.9l-2.2-4l-1.5-1.6l-0.7-0.3l-1.3,0.3
      l-2.3-0.7l-1.3-0.2l-0.7,0.2l-1.1,0.5l-0.6-0.3l-0.2-0.5l-2.1-1.7l-2.6-0.9l-5-5.3l-1.5-2l-3.1-2.2l-2-3.2l-1.6-1.2l-2.4-1l-0.5,0.1
      l-0.7,0.4l-0.6,0.1l-0.4-0.4l0.5-0.4l0.5-0.2l-0.2-1.2l-2.7-3.2l-1.6-1l-0.4-0.6l-0.3-0.9l-0.3-0.6l-0.7-0.3l-0.6,0.1l-0.9-0.2
      l0-1.6l0.2-1.2l-0.1-1l-0.9-2.7l-1.5-2.3l-0.9-5.4l-0.7-1.5l-1.6-1.2l-3.7-1.3l-5.2-3.5l-1.1-0.1l-3.1-1.4l-1.9-0.2l-2.5,1.2l-3,3.4
      l-2.5,3.5l-0.9,0.7l-3.2,1.2l-2.8,0.6l0-0.9l-0.1-0.7l0.5-0.7l0.8-0.9l0.7-1.1l0.3-0.8l-0.1-0.5l-0.3-0.8l-0.4-0.1l-2.7,0.7
      l-0.6-0.2l-2-1l-2.1-1.3l-0.8-0.9l-0.3-0.9l0.2-0.6l-0.2-0.6l-0.4-0.8l0.4-0.9l0.7-1.1l0.3-0.7l0.6-0.2l0.3-0.5l-0.4-1.8l-0.2-0.3
      l-0.4-0.2l-0.6,0l-1-0.4l-0.7-0.6l-0.2-0.8l-0.4-0.8l-0.7-0.7l-0.1-0.8l0.8-0.4l1,0l0.7,0.2l1.6-1.3l0.6-0.1l0.5-0.3l0.5-1.8
      l0.3-0.5l0.1-0.3l-0.3-0.4l-1.3-1.3l-0.6-1.3l-0.9-1.5l-0.9-0.7l-0.2-0.5l0-0.7l0.2-0.6l1.6-0.9l0.9-1l0.4,0.4l0.9,0.4l2.3-0.5
      l1.4-0.5l1-0.6l0.6,0.1l2.3,0.8l0.8-0.4l1.6-1.1l0.2-0.6l1.3-1.8l0-0.4l-0.5-1.2l0.2-0.3l1.6-1.2l0.8-1l0.8-0.7l0.6,0l0.2,0.2
      l0.1,0.5l-0.1,2l0.2,0.6l1.3,1.4l0.9,0.8l2.1,0.6l0.1,0.3l-0.6,1.1l1.3,1.3l0.2,0.9l0.6,0.5l0.8-0.3l0.3-0.5l-0.3-0.9l-0.2-0.9
      l0-0.5l0.2-0.6l0.6-0.9l1.6-2l0.6-1.1l0.1-1.8l0-1.4l0.5-0.3l1.1,0.3l0.3,0l0.2,0.9l0.5,1.4l0.6,0.7l0.6,0.2l0.7,0l1.7-0.8l1.1-0.4
      l0.6,0.1l0.4,0.6l0.8,1.5l0.5,0.2l0.6-0.2l0.2-0.3l-0.2-0.6l-0.2-1.3l-0.3-1l-0.4-0.5l-0.1-0.6l0.3-1.1l0.3-0.9l0.6-0.2l0.7-0.1
      l0.9,1l1.1,0.3l0.8,0l0.1-0.6l-0.1-0.6l-0.5-0.8l0.1-1.2l0.5-2.2l0.3,0.2l1.2,0l1.3,0.1l0.8,0.9l0.8,0.3l1.2,0.1l0.8-0.1l0.4-0.3
      l0.4-1.1l0.8-1.3l1.3-0.7l2.2-0.1l1.1-0.2l1.1,0l0.9,0.2l0.9,0l2.3-0.9l2.3-0.7l0.3,0.1l0.1,0.2l-0.4,0.6l-0.4,0.7l0.3,0.8l1.3,1.7
      l0.7,1.3l0.7,1l1.1,0.5l1.4,0.3l1.2,0.1l1.2,0.4l4.3,0.9l2.1,0.2l1.6,0L283.3,585z M269,628.3l0.3-0.8l-0.1-0.6l-0.7,0.1l-0.5,0.8
      l0.3,0.7L269,628.3z M268.5,660.1l0-0.1l-0.1,0l0,0.1L268.5,660.1L268.5,660.1z M244.5,645l0.4,0.6l0,0.4l-0.3,0.4l0.1,0.9l-1-0.8
      l-1.5,0.4l-0.9-0.1l-0.3-0.7l0.2-0.4l1.4-0.1l0.4-0.2l0.9,0.1L244.5,645z M235.6,675.8l0.6,1l1.3,4l0.1,0.9l-0.3,0.9l-0.3,0.6
      l-1.3,2l0.2,1.7l0.5,1l0.1,1.1l-0.2,1.4l-0.8,8.6l-0.4,1.5l-0.2,1.3l-0.9,0.4l-1.2-0.4l-1.5-0.7l-0.7,0l-0.7,0.3l-0.6-0.2l-0.5-0.4
      l-0.4,2.9l-0.7,1.2l-1,0.8l-0.9,0l-1-0.3h-0.8l-0.6-0.6l-0.5-1l-0.8-1.2l-0.8-1.4l-0.1-1.3l-0.1-2.9l0.2-0.6l0.3-0.6l0.2-1.3
      l-0.1-1.1l0.2-0.4l0.5,0.4l0.3-0.1l0-0.6l0.1-1.1l-0.6-0.9l-1-0.3l-0.1-0.9l0.1-0.9l0.5-0.6l0.2-0.8l0-2.5l-0.7-0.9l-0.3-1.4
      l-0.4-0.9l-0.7-0.9l-0.8-0.7l-0.5-0.7l-0.1-1.8l0.3-1.5l0.2-0.7l0.2,0.1l0.8,0.8l0.6,0.2l1.2,0.2l1.2-0.2l1.5-0.7l1.4-0.8l2.1-2.5
      l1.3-0.5l0.7-0.7l0.2-0.9l0.5-0.2l0.6,0.9l0.8,0.1l1.2,0.7l0.5,0.7l0.5,0.8l0.4,0.4l0.5,0.2l0.1,0.2l-0.4,0.2l-0.4,0.9l0.2,0.3
      L235.6,675.8z M219.8,673.4l-0.4,0.7l-0.6,0l0.2-0.5l0.5-1l0.6-0.3l0.3,0.3l-0.3,0.6L219.8,673.4z M286.1,678.5l-0.5,0.1l-0.3-0.2
      l-0.2-0.2l0.2-0.6l1.1,0.3l0,0.3L286.1,678.5z M222.1,703.3l-0.7,1.5l-0.7-1l0-0.9l0.1-0.3l0.9,0.4L222.1,703.3z M305.3,715.9
      l-0.8,1.7l-0.4,0.6l-2.8,4.1l-0.3,0.9l-0.2,1l-0.3,0.9l-0.4,0.8l-0.4,1.1l0.1,1.2l0.1,0.6l0.3,0.4l0.6,0.4l0.4,0.6l-0.7,0.5l0.7,1
      l0.6,0.6l0.1,0.6l0,0.6l-1.3,1.1l-0.5,0.6l-0.3,0.8l-0.1,0.8l0.1,0.7l0,0.7l-1.3-0.1l-1.3-0.4l-1.3,0.2l-1.9-0.8l-0.7-0.1l-0.6-0.3
      l-1.6-2.5l-1.3-1.1l-1.4-0.8l-1.4-0.1l-1.4,0.1l-1.2-0.5l-2.5-1.7l-2.7-1.4l-1.1-0.9l-0.5-0.6l-0.6-0.4l-1.5-0.4l-1.4-0.9l-0.6-0.1
      l-1.3,0.1l-0.7-0.1l-0.7-0.3l-1.3-1.1l-0.9-1.5l-0.2-0.7l0.6-1.8l0.7-1.7l0.6-0.5l0.7-0.3l0.4-0.5l0.4-0.6l1.4,1.8l0.6,0.4l0.6-0.1
      l1.1-0.6l0.1-0.7l1.2-0.9l1.5,0l0.7,0.2l0.4,0.8l0.6,0.2l0.7,0.1l2.2,1.5l0.6,0.2l0.6,0l1.7-0.6l1.3-0.2l2.8,0.3l1.5-0.4l1.1,0
      l1.5-0.6l1.2-1l0.6-0.2l0.7-0.1l1.6,0.1l1.6,0.2l0.7-0.2l0.6-0.6l0.7-0.3l0.7,0.2l1.9-1.1l0.8-0.1l0.8,0.4L305.3,715.9z M264,737.3
      l-0.6,0.2l-0.7-0.5l-0.1-0.7l0.1-0.2l0.9,0.3l0.3,0.6L264,737.3z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        d="M99.1,538l0.1,0.9l-0.5,0.2l-0.4-0.3h-0.9l-0.8,0.2l0.2-1.6l1.6,0.2L99.1,538z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        d="M235,575.9l-0.9-0.1l-0.2,0l-0.1-0.6l0.1-1.3l0.5-1.7l0.1,0.3l0.1,0.4l0.1,0.4l0.1,0.5l0.2,0.5l0.3,0.4l0.1,0.4
      l-0.2,0.5L235,575.9z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="lithuania"
        d="M434.4,414.9l0,0.9l-0.3,1.6l-0.6,2l-0.6,1.6l-0.1,0.6l0.4,0.5l2.2,0.2l0.9,0.3l0.2,0.4
      l-0.5,0.5l-0.7,0.4l-0.3,0.4l-0.6,1.5l-3.6-0.2l-0.5,0.3l-0.2,0.7l-0.2,0.8l-0.5,1l-1,0.8l-1.5,0.3l-1.2,0.6l-0.9,1.7l-0.7,2.3
      l0,1.6l0.1,0.9l-0.1,0.5l-0.5,0.6l-0.8,1.5l-0.6,1.7l-0.2,0.9l0.1,0.4l0.7,0l1,0.3l0.5,0.7l0.2,0.8v0.8l-0.2,0.5l-0.8,0.3l-1.3,0
      l-0.7-0.4l-0.2-0.3l0.4-0.8l-0.3-1l-0.5-0.6l-1.1,0.8h-1l-1.2,0.7l-0.8,1.2l-0.8,0.4l-2.1-0.2l-0.5,0.5l-0.4,2.4l-0.2,0.5l-1.7-0.1
      l-1.7,1l-1.9,0.8l-1-0.5l-0.5-0.6l-1,0.1l-1.1,0.3l-0.8-0.1l-0.8,0.1l-1.6,0.5l-2-0.1l-0.9-0.4l-0.1-0.4l0.1-0.9l0-1.4l-0.3-1.3
      l-1-1.1l-1-0.8l-1.3-0.8l-1-0.4l-0.5-0.1l-0.1-0.5l-0.2-0.4l-0.5-0.4l-1-0.5l-0.8-0.1l-0.7,0.8l-0.5-1l-0.5-1.7l0.1-1.4l0.3-1.4
      l1.4-4.2l-0.1-0.7l-1-1.2l-1.3-0.8l-0.7-1.8l-2.6-0.1l-2.4,0.1l-0.8-0.1l-2.3-0.8l-2.2-1.2l-1.5-0.7l-1.3-0.8l-0.7-0.8l-1.1,0.2
      l-0.7,0l0-0.1l-0.4-1.5l0.4-2.3l-0.8-3.3l-1.3-4l-0.1-4.4l-0.1-1l3.1-2.5l4-2.6l0.9-0.2l3.7-1.6l0.5-0.1l3.3,0.3l2.6,0.4l2.2,0
      l1.2-0.4l1.1,0.3l0.9,1.2l0.9-0.1l0.9-0.8l4.9,0.7l1.1,0l1.2,0.1l2.3,0.7l1.3,0.7l2.9-0.4l1.2,0l0.6-0.3l2-1.8l0.9-0.3l0.8-0.3
      l0.7,0.3l0.5,1.5l1.5,2.6l1.6,0.5l4.4,1l0.9,0.5l2.5,2.3l1.5,1.1l1,0.9l1.5,1.8l0.8,1.3l1.4,1l1.7,0.6L434.4,414.9z M368.3,422.8
      l-0.7-0.2l1.3-2.4l0.5-1.5l0.4-2.2l0.3-0.7l0,1l-0.1,1.7l-0.8,2.8L368.3,422.8z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="luxembourg"
        d="M194.4,522l-0.1,0.5l0,1.1l0.3,1.1l0.8,1.1l0.6,0.8l0.8,0.6l1.4,0.6l0.5,0.1l0.1,0.8l-0.1,0.8
      l-0.5,0.5l-0.4,0.7l-0.3,0.8l-0.3,1.6l-0.1,1.1l-0.8-0.4l-0.4-0.3l-0.7-0.1l-0.7,0.2l-0.5,0.5l-0.7,0.2l-0.6-0.2l-0.4-0.4l-0.3-0.2
      l-0.9-0.3l-0.4-0.6l0.3-0.3l0.3-0.4l0.2-0.6l0.3-0.6l-0.9-1.6l-0.2-0.5l-0.7-0.9l0-0.5l0.2-0.4l-0.1-0.3l0.1-0.8l0.5-0.8l0.3-0.9
      l0.6-1.3l1.3-1.5l0.9,0.2l0.4,0L194.4,522L194.4,522z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="latvia"
        d="M443.2,375.5l1.4,0.1l0.5,0.3l0.3,1.7l1.6,1.3l1.5,1.1l0.4,0.5l0.1,1l-0.1,1.1l-0.2,0.6l-0.6,0.7
      l-0.5,1.7l-0.1,1.6l-0.8,2.8l0.2,0l1.8-0.5l0.5,0.3l0.4,0.6l0.1,1.8l0.6,0.8l0.6,1.2l0.2,1l1.1,1.1l0.1,0.7l0.7,2.6l0.3,1.5l0.1,1.2
      l-0.3,1.5l-0.3,1l-0.4-0.1l-1,0.3l-1.6,1.2l-2.4,2.8l-0.6,0.6l-0.6,2.1l-0.1,0.2l-1.4-0.1l-0.4,0l-1.4,0l-3-0.6l-1.2,0.4L437,414
      l-0.6,0.3l-1.8,0.3l-0.3,0.2l-0.6-0.1l-1.7-0.6l-1.4-1l-0.8-1.3l-1.5-1.8l-1-0.9l-1.5-1.1l-2.5-2.3l-0.9-0.5l-4.4-1l-1.6-0.5
      l-1.5-2.6l-0.5-1.5l-0.7-0.3l-0.8,0.3l-0.9,0.3l-2,1.8l-0.6,0.3l-1.2,0l-2.9,0.4l-1.3-0.7l-2.3-0.7l-1.2-0.1l-1.1,0l-4.9-0.7
      l-0.9,0.8l-0.9,0.1l-0.9-1.2l-1.1-0.3l-1.2,0.4l-2.2,0l-2.6-0.4l-3.3-0.3l-0.5,0.1l-3.7,1.6l-0.9,0.2l-4,2.6l-3.1,2.5l-0.4-3.9
      l0.2-8l0.5-4l2.2-2.3l1.1-1.8l0.6-2.4l0.2-2.3l0.4-1.9l3.2-5.4l2.5-0.6l3.4-1.5l3.8-1.3l0.7,1.6l0.4,1.2l4.6,4.4l1.2,1.5l1.8,5
      l4.2,2.5l3.3-0.8l1.4-1.2l2.7-2.3l1.2-1.7l0.2-1.6l-0.5-6.9l-0.7-3l0.2-1.9l0.5,0.1l1.1-0.9l3.7-1.7l0.7-0.1l0.8-0.3l2.3-1.3
      l0.8,0.7l0.6,0.8l0.3,0l0.2-0.3l0-0.5l0.2-0.4l0.7,0.2l2.7,2.1l1,0.5l0.7,0.1l0.9,1l2.3,0.7l0.3,0.5l0.2,0.6l2.2,2.7l1,1.3l1.9,1.2
      l0.8,0.3l3.4-1.3l0.9-0.4l0.8,0l0.8,0.7l1.8,0.9l1.6,0.3L443.2,375.5z"
      />
      <path fill="#333333" stroke="#F4F4F4" strokeWidth="0.560223" d="M209.9,630.7l-0.7,0.3l0-0.4l0.2-0.2l0.2-0.1l0.3,0.2L209.9,630.7z" />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="moldavia"
        d="M453.3,603l-0.6-1.1l-0.6-0.9l-0.4-0.5l0.2-0.2l0.5-0.3l0.3-0.3l0-1.1l-0.2-1.3l-0.2-0.6l0-1
      l-0.2-1.5l0.2-2.8l0.9-3.5l0.5-1.7l-0.3-1l0.2-2.2l-0.4-1.1l-0.6-1.5l-0.9-3.2l-1.1-1.1l-1.4-1.2l-0.6-0.9l-0.4-1l-0.8-1l-1-0.9
      l-1.2-2.3l-0.6-1.1l-0.2-0.3l-1.3-1.5l-0.7-1.4l-0.4-1.1l-0.2-1l-0.9-2l-0.8-1.5l-0.8-1.1l-0.4-0.8l-0.9-1l-1.3-0.8l-0.9-0.1
      l-1.1,0.1l0.3-0.6l2.4-1.6l0.6,0.3l1.3,0.1l2.6-0.1l1.3-1.1l0.8,0.3l0.6-0.5l1.1-0.6l0.2,0.1l0.1,0.1l1.6,0.3l1.2,0.6l0.8,0.9
      l0.9,0.6l0.9,0.2l0.5,0.4l0.1,0.7l0.8,0.3l1.5,0l0.7,0.4l-0.2,0.9l0.2,0.3l0.6-0.3l0.4,0.3l0.2,0.7l0.2,0.3l0.8-1l0.8,0.1l2,0.4
      l1.1,2.2l0.7,0.8l0.6,0.3l0.7-0.3l0.7-0.4l0.4,0.2l0.8,1.4l0.2,1.9l0,0.7l-0.3,1.3l-0.4,1.3l-0.3,0.9l0.1,0.7l0.3,0.6l0.5,0.2
      l1.6,1.2l0.6,0.8l0.8,0.6l0.6,0l0.3,0.3l0.1,0.4l-0.1,1l-0.4,1l0,0.6l0.6,0.7l0.1,0.9l0,0.6l0.3,0.4l1.4,1l1.8,0.9l0.5,0.8l0.3,1
      l-0.1,1.7l-0.1,1.5l2.4,2l-0.3,0.4l-0.4,0.4l-2.3,0.3l-0.5,0.2l-1-1.5l-0.5-0.2l-0.5,0.5l-0.6,0.3l-0.7-0.1l-0.8-0.5l-0.4-0.3
      l-0.3,0l-0.5,0.3l-0.6-0.1l-0.4-0.4l-0.6,1.2l-0.4,0.3l-0.2,0l0-2.1l-0.2-0.3l-0.5-0.1l-1.1,0.5l-1.1,0.7l-0.4,0.6l0,1l0.2,1.2
      l0.7,1.9l-0.4,0.8L462,593l-1.1,1.2l-1.3,0.7l-0.1,1.4l-0.7,1l-1.2,1l-0.8,1.2l0.2,0.8l0.1,0.8l-0.1,0.5l0,0.4l-0.3,0.2l-1.9,0.1
      l-0.5,0.2L453.3,603z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="montenegro"
        d="M347.7,634.2l0,0.2l0.1,0.6l0.3,0.6l0.9,0.6l1.4,1.1l1.6,2.1l0.7,0.6l0.7,0.2l1.3,0.9l0.9,0.2
      l1,0.2l2.6,1.8l1.2,0.5l0.8,0.7l0.1,0.6l0,0.4l-1.5,0.5l-0.3,0.7l-0.7-0.1l-0.9,0l-0.3,0.4l0.4,0.7l0.3,0.9l-0.2,1.2l-0.1,0.2
      l-0.2,0l-1.3,0.7l-0.9,0.3l-0.8,0.2l-0.4-0.3l-0.2-0.4l0-1.3l-0.2-0.4l-0.3-0.2l-0.6,0.3l-0.7,1l-0.6,1.2l-0.9,1.2l-0.8,1.2
      l-0.8,1.5l-0.6,1.2l0.6,0.7l0.4,0.9l-0.1,0.7l0.1,0.4l-0.2,1.2l0,0.8l-1.8-1.2l-0.8-1.8l-2.7-3l-3.1-2l-0.2-0.3l0.2-0.4l0.1-0.3
      l-0.6,0l-0.4,0.2l-0.4-0.1l-0.5-0.8l-0.5-0.7l0-0.6l0.2-0.1l0.3-0.2l0.6-0.6l0.1-0.3l0-0.5l-0.9-1.6l-0.1-1.1l-0.1-2l0.2-0.5
      l0.3-0.2l1.6-0.2l0-1.5l0.1-0.5l0.3-0.6l0.2-0.6l0.9-0.8l1.2-1l0.5,0l0.5,0.1l0.5,0.9l0.6-0.1l0.1-1l-0.7-1.4l-0.4-0.9l0.1-0.5
      l0.3-0.3l0.6,0.2l0.6,0.2l0.4-0.2l0.6-0.1L347.7,634.2z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="north-macedonia"
        d="M384.6,653.6l1.8,2.3l1,0.9l1.2,0.7l1.3,0.5l0.5,0.5l0.8,2.5l0.4,0.9l0.6,0.3l0.1,0.3
      l0,0.4l-0.6,1.7l-0.3,3.9l-0.2,0.3l-0.7,0l-0.9,0.1l-0.3,0.3l-0.4,2.1l-1.4,0.6l-1.3,0.3l-1.1-0.1l-1.9-0.5l-0.6-0.1l-0.5,0.3
      l-1.7,0.1l-0.7,0.4l-1.8,2.4l-1.8,0.8l-0.6,0.4l-1.4-0.5l-0.7-0.1l-0.9,0.6l-2.1,0.1l-0.6,0.1l-1.6,0.1l-0.1-0.3l-0.3-0.5l-0.7-0.2
      l-1.5,0.2l-0.4-0.4l-0.6-2.1l-0.5-0.3l-0.5-0.7l-0.9-2.2l0-1l0.1-0.9l-0.5-2l0.3-0.5l0.5-0.3l0-0.8l-0.1-1.2l0.6-2.4l0.2-0.2
      l0.1,0.1l1.4,0.2l0.4-0.3l0.2-0.5l0.1-1.8l0.3-0.8l3.3-1.6l1-0.1l0.7,0.7l0.6,0.5l0.4,0l0.1-0.5l0.4-0.9l0.7-0.5l2-0.4h0l0.7,0.1
      l1.4-0.4l0.9-0.6l0.5-0.1l0.6-0.2l0.9,0l0.9,0.2l1.1-0.3l1.1-0.5l0.4,0.1l0.5,0.4L384.6,653.6z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        d="M290.5,747.9l-0.7,0.2l-0.7-0.4l-0.2-0.3l1-0.2l0.5,0.2l0.2,0.4L290.5,747.9z M293.4,750.4l-0.4,0.5l-1.1,0
      l-1-0.7l0-1.5l1.1,0.3l1,1L293.4,750.4z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="netherlands"
        d="M184,460.8l-0.5-0.3l2.6-0.8l1.7-0.2l0.3,0.1l-3,1L184,460.8z M189.9,459.5l2.3-0.3l0-0.1
      l-0.6-0.2l-2,0l-0.6,0.1l0.1,0.2L189.9,459.5z M196.9,458.2l-0.5-0.1l-1.4,0.4l-0.1,0.2l0.4,0.1L196.9,458.2z M182.6,462.2l-1,0
      l-1.4,1.2l0.2,0.2L182.6,462.2z M202.4,456h-0.5l-1,0.4l-0.3,0.5l1.1-0.1L202.4,456z M169.7,498.6l-1.5,0.8l-2-0.3l-1.5-0.6
      l-1.9,0.1l-0.9,0.3l0.4,1.6l0.3,0.5l0.3,0.3l0.5,0.1l0.5-0.4l0.7-0.4l1.2,0.2l0.9,0.4l0.3,0.4l0.6,0.4l0.8,0.1l1.6-0.7l1.6-1.1
      l0.5-0.8l0.2-0.7l-1.3,0.5L169.7,498.6z M207.1,462.7l-1.6-0.4l-1.1-0.5l-0.7-0.9l-1.1-1.3l-3,0.1l-2.5,0.4l-3.4,0.2l-2.2,0.6
      l-4,2.1l-1,1.1l-1,2.3l-3.5,2.6l-2,1l-0.6-0.4l-0.8-0.3l-0.7,1.3l-0.4,1.2l-1.4,7l-0.9,2.5l-1.2,2.1l-2,2.6l-0.9,0.9l-0.6,0.3
      l-0.7,1.3l-0.6,1.5l-0.4,0.7l2.2,2.6l0.3,0.7l0.3,0.4l-2.1,0.3l0.9,0.8l1.1,0.6l0.7,0.3l0.4,0.6l-0.8,0.3l-0.8,0l-3-2.2l-1.7-0.4
      l-2.3,0.1l-0.6,0.2l-0.6,0.7l0.8,1l0.8,0.6l1.2,0.1l1.5,0.8l2.2-0.6l1.6,0.8l1,0.3l0.9,0.5l0.8,0.1l0.4-0.2l-0.2-1.1l0.7-0.6
      l0.7-0.3l0.3,0.5l0.7,0.5l0.5,0l1.4-1.3l0.3,0.3l0.3,0.5l0.1,0.4l0.1,0.4l0.3,0.2l1.1-0.1l0.6-0.7l0.4-0.4l0.3,0.3l0.2,0.9l0.3,1.1
      l1.3,1.3l1.1,0.4l1.4-0.2l0.6-0.2l0.4,0.2l0.4,0.7l0.8,0.8l1.7,0.5l0.5,0.3l0.4,0.5l-0.1,0.7l-0.8,1.8l-0.1,0.5l0.1,0.2l-0.2,0.3
      l-1,1.2l-0.1,0.4l0.3,0.7l0.3,0.6l0.6,0.3l0.6,0.1l1.1,0l1.2,0l0.6-2.8l-0.5-0.8l-0.6-0.4l-0.7-0.2l-0.3-0.4l-0.1-0.5l0.1-0.3
      l0.8,0.1l0.3-0.3l2-1.7l0.1-0.3l-0.3-0.2l-0.4-0.1l-0.1-0.4l0-0.5l1.1-2.4l0.3-1l0.1-0.7l-0.1-0.7l-0.6-1.1l-0.6-0.9l0-0.7l-0.4-0.4
      l-1.2-2V491l0.7-0.6l1-0.4l0.3-0.3l0.6-0.2l1.5,0.6l0.7,0.5l0.2-0.1l0.6-0.5l1.1,0.1l2.6-1.1l0.4-0.5l0.3-0.5l0-0.2l-1-1.1l0-0.4
      l0.1-0.4l0.3-0.3l0.6-0.2l0.6-0.5l1.4-1.3l0.5-1.1l0.2-1.2l0-0.9l-0.4-0.7l-0.4-0.5l-0.5,0.1l-1,0l-1-0.4l-0.5-0.7l-0.1-0.6l0.2-0.4
      l0.1-0.5l-0.2-0.5l0.1-0.4l0.4-0.3l3.1,0l0.2-0.3l0.2-1.8l0.8-2.7l0.7-1.5l0.1-0.6l0-3.6L207.1,462.7z M166.5,493.3l1-0.3l1.6,1.3
      l1.5-0.4l-0.1-0.3l-0.2-0.3l-1.1-1l-1.9-0.1l-1.1,0.3l0,0.4L166.5,493.3z M178.8,468.1l1.2-1.4v-2.2l-1.7,1.8l-0.4,1.1l0.2,0.3
      L178.8,468.1z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="norway"
        d="M422.6,0.6l3.1,1.4l1.1,0l1.6,2.6l0.8-0.2L429,5.9l-1.6,0.7L425,7.1l-0.4,0.3l-2.1-0.2L421.3,5
      l-2-0.5l0-0.7l1.3-1.6L422.6,0.6z M484.5,48l0.6,4.4l0,1.5l-0.3,1.5l-0.4,0.8l-0.8,0.3l-2-0.1l-2.8-1.7L477,53l-0.6-0.1l-0.2,0.2
      l0.4,1.6l-0.1,1.3l-0.3,1.4l-0.4,1.2l-0.5,1l-1.1,1.3l-1.9,1.1l-5.2,2.1l-0.4,0.9l-1.7,5.7l-0.5,0.8l-0.6,0.7l-1.8,0.9l-0.9-1.3
      l-0.7-1.9l0.2-1.9l2.1-3.6l2-2.6l0.6-0.9l1.1-2.6l-2.2-6.6l-4-2l-4.6-3.1L454,45l-2.6-3.4l-1.8-3.1l-1.7-0.1l-1.8,0.8l-2.9,2.8
      l-1.7,1.4l-0.9,0.4l-0.2,0.1l-2-0.8l-2.3-0.2l-1.8,0.2l-0.7,0.4L431,48l-1.8,2.3l-1,0.8l-0.7,1.3l-0.6,2.1l-1.3,7.4l-1,2.8l-0.2,1.7
      l0.2,5.1l-0.2,2.8l-1.2,2.3l-0.8,1l-1.1,0.2l-1.4,0.6l-1.3,1.3l-0.9,1.8l-1,4L415,87l-1.6-0.4l-1.2-1.5l-2.5-1.1l-1.9-0.7l-2.1-1.6
      l-1.8-1.2l-1.7-0.2l-1,1.5l-0.8,1.4l-2.9,1.2l-1.6,0.9l-2.1,0.2l-0.9-1l-3.1-0.7l-3.6-0.8l-1.1,0l-0.3-1.8l-1-2.6l-2.6-4.4l-1.1-1.6
      l-2-3.7l-2.3-3.8l-0.3-0.1l-1.6-0.1L372,65l-1.4,0.9l-0.9,1.1l-0.2,0.9l0.9,3.5l-0.3,0.9l-0.5,0.4l-2.1-1l-2.5,0.1l-0.6,1.1
      l-1.5,0.1l-4.4,0.4l1.9,2.8l0.6,1.1l0.1,1.6l-0.3,3.1l-0.9,2.6l-1.1,2.1l-2.1,2.1l3.2,2.1l-2.2,2.8l-1,1.1l-1.2-0.2l-2.1-1l-5.1-2.3
      l-2.4-0.9l-2.2-0.3l-1.2,0l-4.6-2l-0.9,0.2l-1.6,0.9l-0.2,1.9l0.1,4.8l0.2,3.7l-0.6,2.1l-0.6,1.4l-1.8,3.8l-4.1-2.6l-2.8-1.7
      l-1.8,2.3l-4.5,4.2l-2.3,8.2l-0.1,0.3l-1.4,2.1l-1.8,1l-1.3,0.5l-0.8,2.4l1.8,3.4l0.9,1.8l0.9,2.9l-0.2,1.8l-0.2,1.1l-1.9,2.3
      l-4.1,6.2l-3.8,6.4l-1.6,1.8l0.7,5.4l-1.3,1.6l-2.6,1.8l-1.3,0.7l-1.4,0.4l-4.4,0.7l0.8,5.7l0.3,2.5l0,1.5l-0.5,1.4l-0.5,2.7
      l-0.8,9.7l-0.6,1l-0.9,2.6l-2.8,6.2l-2.2,4.1l-3.2,5.9l2.6,1.8l2.4,1.3l0.5,2.1l0.3,3.4l-0.1,2.3l-0.9,2.1l-0.7,1.5l-0.5,0.7
      l-3.4-0.7l-4.4-0.9l-1.1,0l-2.5,0.7l-2.3,1.3l-1.2,1.1l-0.3,0.4l-1.5,2.6l-2.7,4.5l-1.5,2l0.4,2.7l-2.5,5.2l1.6,5.2l0.1,0.2l0.9,2.1
      L265,248l-0.4,0.7l0.1,2.4l0.2,2.7l-0.2,1.6l-0.1,1.7l2.2,7.7l0,1.8l-0.1,1.2l-0.7,4.7l-0.9,6.3l1.6,1.6l2.3,2l1.3,0.8l1.9,2.3
      l1.4,2.3l-0.2,1.5l-0.4,1.7l-0.6,1.2l-0.6,1.6l-0.3,1.2l-0.3,0.3l-2.5,0.1l-1.3,0.4l-0.7,0.5l0.2,2.7l1.5,4.8l1.3,3.4l0.4,2.2
      l-0.4,2.3l-0.4,1.2l0,1.6l-0.3,3.1l-1,1.6l-1.3,1.7l-1.4,1.3l-1.1,0.4l-1,0.1l-0.7,0.6L262,325l-0.6,2l-1.8,2.4l0,0.8l0.7,2.9
      l0.6,3.2l-0.5,3l-0.5,3.1l-0.8,2.1l-1.2,0.7l-0.9-0.4l-1-2.9l0-0.7l-0.2-0.9l-2.7-0.9l-0.5,0l-1.1-0.5l-0.6-0.1l-1.3-0.3l-1.1-2.6
      l-1.1-2.1l-0.2-0.9l0-4l-0.4-1.8l-0.1-2l-0.7,1.6l0.4,2.5l-0.9,1l-1.1,0.5l0.1,1.5l0.5,0.3l0.1,1.5l-0.3,2.2l-2.2,4.9l-0.4,0.5
      l-0.3,0.7l-1.1-0.4l-1.4,1.4l-1.4,0.2l-0.5-1.5l-1.9-2.1l-0.9,0.1l0.8,1l0.8,1.3l-0.5,0.9l-0.5,0.6l-0.8,0.3l-2.8,1.7l1,1.2
      l-0.9,1.3l-1,0.2l-0.5,0.6l-0.2,0.8l-2.9,2.3l-4.8,6l-2.5,1.7l-1.7,1.7l-1.5,0l-1.9,1.5l-4.8,1.3l-3.2-0.6l-2.2,0.5l-1.2-1l-0.2-0.7
      l0.1-0.4l0.2-0.5l-0.4-0.2l-0.9-0.1l-0.4,0.5l-0.1,1.1l-0.4,0.3l-1.7-0.6l-0.4-0.6l0.6-1.2l1-1.1l-0.2-0.2l-0.2-0.6l-0.5-0.1
      l-1.5,0.2l-1.2-0.2l-3.9-2.4l-0.9-1.3l-3.2-2l-1.4-2.2l-0.8-2.4l0.1-2.2l0.4-3.4l0.7-0.9l2.8,1.2l2.9,2l0.4-0.1l0.9-1.6l1.7-1.3
      l-0.5-0.3l-2.6,1.5l-1-0.8l-1.5-1.6v-0.9l0.7-0.9l0.2-1.2l-0.4-1.1l0.2-1.5l1.2-1.6l1.7-1.6l1.3-1.6l1.3-1l-0.1-0.3l-1.5,0.6l-1.4,1
      l-1.7,1.7l-2,1.4l-1.5,0.5l-0.7,0.4l-1.1,0.4l-1.1,2l-1.2,0.9l-2.2,0.1l-0.5-1.5l0.6-5.2l0.7-2.5l0.7-1.8l1.2-0.3l0.8-1.3h0.7
      l0.6,0.6l2.3,0.6l1.1-1.7l1.4-0.2l2.6-1.7l-0.1-0.3l-1.8,0.4l-1.1,0.1l-1.6,0.4l-0.8-0.3l-0.4-1.3l0.6-1.2l2.5-2.8l0.9-1.2l0.5-1.1
      l-0.1-0.8l0.4-1.6l2.4-2.8l2-1.3l0.7,1.1l-0.6,3.5l0,1.4l1.6-5l0.7-1.2l0.8-0.8l1.9-0.6l0.5-0.8l-2.2,0.3l-5.4,1.9l-2.3,1.7
      l-0.6,1.3l-1.6,2l-0.7,1.3l-0.3,1.9l-0.9,1l-1.2,0.4l-1.7,2.4l-0.7,1.9l-1.7,1.5l-1,1.2l-0.3,0.4l-0.6,1.1l-0.5,0.1l-0.4-0.7
      l-0.1-1.5l0.2-2.4l0.8-1.7l0.4-1.7l-0.5-1.6l0.4-1l0.7,0l1.3,0.4l1.4-0.1l2.3-1.2l-0.4-0.7l-1-0.1l-1.8,0.1l-1.5-1.2l-1.2-2.4
      l-0.5-3.2l0.4-0.9l4.4-3.3l1.2-1.5l-0.7-0.2l-1.7,1.8l-2.4,1.1l-1.5-1.6l-0.8-1.7l-0.5-3.6l0.2-1.9l-0.2-2.4l1-0.8l1.1,0.4l1.1,0.2
      l2.5-0.2l5.6-1.5l3.6,0.9l1.5-0.1l2.2-1.3l2-0.1l1.5,1l0.8,1.1l0.1,1.5l0.7,1l0.5-0.3l-0.4-1.2l-0.1-1.8l5.9-2.1l0.7-0.8l-2.4-0.3
      l-0.7-1.9l1.2-2.9l-0.1-0.4l-1.3,1.5l-0.6,2.1l0.3,1.7L208,292l-1.2,0.4l-2.7,0.1l-1.7-0.7l-1.6-0.4l-0.5-0.5l0.2-1.2l-0.3-0.3
      l-0.7,1.1l-0.6,2.2l-1.3,0.5l-3.5-0.8l-5.1,0.5l-2.3,1.1l-1.5-0.1l-2.6-1.9l-1-1.5l-0.4-3.1l0.1-1.4l2-0.6l1,0l0.9-0.7l-0.8-0.5
      l-1.2-0.9l-0.8-1.9l-1.2-0.6l-0.8-1.6l-0.2-2.4l0.2-1.7l0.6-0.5l1.5,0.4l4.1-0.3l3.8,1.7l2.6,1l5.3-0.5l3.1-1.5l-0.6-0.4l-3.4,0.9
      l-3.1,0l-5.5-1.7l-2.2-0.6l-2.4,0.2l-1.3-0.5l-0.7-1.7l0.5-3.3l1.1-0.7l0.6,0.9l0.7,0.1l0.8-1.4l0.7-0.8l0.6-1.8l2.2-1.7l0.9-0.1
      l1.3-0.8l0.8,0.2l0.5,0.8l0.7,0.7l1.5-0.1l4.4-1.4l0.5-0.4l0.8-1.1l-2.8,0.5l-2.3,0.8l-1.5,0.2l-0.2-1l0.5-0.9l0.9-0.9l0.4-1.6
      l0.9-0.7l1,0l2.1-0.3l1.5-0.4l2.5,0.3l3.8,0.6l2.4,1.5l0.9-0.1l1-0.4l0.4-0.5l-1.9-0.6l-0.1-0.9l0.2-0.7l3.1-1.2l3.4-0.3l-0.6-1
      l-7.5,1.5l-2-1l-1.5,0l-1,0.6l-2.9,0.7l-0.5-0.5l0.5-1.8l1.7-2.9l0.1-0.7l0.8-0.7l4.5-1.7l2.1-2l1-0.2l1,0.1l1.5-0.2l2.8,0.6
      l1.3,2.5l1.2,0.8l3.7,3l-0.2-0.9l-3.2-4.1l-1.2-1l-0.9-2l0.3-1.9l1-1.3l3.6-0.7l0.6-0.8l0.1-1.3l-0.6-0.9l-1.3,0.1l-1.1-0.5
      l-0.3-1.4l0.4-0.9l2.1-1.7l1.1-0.6l2-0.6l3.4,1.4l0.3,0.7l-1,1.7l0.1,1l0.8,0.1l2-2.9l2.3-0.4l1-0.6l1.1-0.4l1.6,2.6l0.7,0.8
      l0.5,0.3l0.5,2.2l0.5,0.1l0.7-1.1l1.3-0.6l1.8-0.4l2.9,0.6l1.3-0.4l0.7,0.1l-0.6-2l-0.4-0.6l0.6-1.8l0.6-0.7l2-1.2l1.9-0.6l1.3-1.2
      l1.7-1.1l-0.3-0.9l-0.5-1l-1.1-0.1l-0.4-0.5l1.4-1.3l1.9-1.5l-0.3-0.6l-1.4-0.6l-1.1,0.5l-1.6,1.1l-1.9,1.8l0.6,0.5l0.9,1.5l-1.3,2
      l-7,5.2l-3.3,1.5l-1.5-0.2l-0.4-1.4l-0.7-1.1l-0.8-2.2l-1.3,0.1l-0.7,0.5l-0.3-0.7l0.6-2.3l1.1-1.9l1.8-1.4l0.9-1.7l0.8-2.7l2.7-2.6
      l3.9-6.4l3.1-2.1l1.2-2.3l1.9-1l1.6-1.8l1.2-0.2l2.3-1.6l1.3-1.9l-0.8-0.1l-2,1.2l-1.1,0.5l0.1-2l0.5-2.1l1.6-1.9l7.9-5.6l0.8,0.9
      l0.9,1.6l2.4-0.4l2.7-3.2l2.1-3.5l-1.1,0.6l-1.2,1.4l-2.4,2l-1.1,0.3l-0.6-0.3l-0.4-1.3l-0.8-0.4l-0.7,0.3l-0.8-0.9l-0.1-2.3l1-3.5
      l0.8-2.3l0.8-1.8l3.3-5l0.7-2.7l1.5-1.4l1.9,0.3l0.6-0.4l-0.7-1.8l-2.1-1.4l-0.1-0.9l7.1-2.4l3.4,0.1l1-1.2l1.8-0.8l1.4-1.5
      l-0.7-0.6l-3.4,1.3l-2.1,0.6l-1,0l-0.8,0.5l-2.7,0.2l-0.6-5.8l0.4-3.2l1,0.1l0.2-3l1.2-1.8l1.6-0.4l0.8-0.8l1.2-1.6l2,0.4l2-0.3
      l-0.5-0.7l-2.5-1l-0.6-1.6l0.9-0.9l1-0.7l0.8-0.1l1.7-3.2l1-1.4l1.1,0.2l1.6-1.4l1.6,0.5l1.5-0.9l2.1-0.6l7.5-0.2l0.2-1.3l-1.6-0.3
      l-5.6-0.3l-2.8,0l-1.2,0.3L292,128l0.1-0.8l1-1.3l0.5-1.4l2.1-3.4l2.4-2.3l1.9,0.6l2,2.2l1.4,0.3l0.7,0.7l1,3l0.5,0.1l-0.2-2.9
      l1.4-2.4l-0.3-0.7l-2,0.9l-1.6-0.9l-1.2-1.8l-0.4-1.6l0.8-1.6l0.7-0.8l-0.5-1l-3.1,2.5l-2.2,0.6l-0.9-0.4l0.5-2.3l-0.3-1.8l2.9-4.5
      l1-0.5l1.6,0.4l1.5,1.3l1.2-0.2l1.3-0.7l-0.2-1.2l-2.9-0.5l-0.8-1l0.3-1l2-1.1l2-1.9l2.3-0.6l1.8-1.5l0.4,0.3l0.3,0.6l0.7,5.4
      l1.6,4.4l0.6,0.2l-0.6-3.7l0.6-1.1l0.7-0.8l0.2-0.9l-0.9-0.3l-0.7-1.3l-1-4.3l0.3-1.1l2.2-2.3l2.7-0.5l2.9,1.6l1,0l1.7-0.4l2.8-1.3
      l1.7-0.5h0.9l0.2-0.7l-0.8-0.4l-0.3-0.4l-0.6-0.2l-2.6,0.7l-7.2-0.2l-0.7-0.8l-0.1-1.3L317,87l0.8-1.1l2.7-1.9l2.9-0.3l3-3.4
      l1.2-2.6l0.7-4l1.9-3.2l4.7-1.8L335,68l-0.5-1.7l0-3.1l1.3-3.6l0.8-1.2l0.4-0.1l1,1.2l1.2,2.5l1.9,1.4l2.5,0.2l0.7-0.7l-1.9-1.4
      l-1.5-1.9l-0.1-1.9l0.7-1l1.1,0.1l1.4-0.2l1.3-1.3l0.2-0.9l0.1-1.2l0.3-1.2l1.9-2.9l5.7-1.9l0.4,0.8l-0.3,5.7l-0.7,3.6l0,2.7
      l1.1-2.7l1.5-7.3l1.1-3.5l1.3-2l0.9-0.4l0.9-1l1.2-0.6l0.4,0.8l0.4,1.8l-0.6,6.4l0.1,2l-0.7,2.7l-2.7,6l0.1,0.8l0.6-0.3l1.1-1
      l3.4-5.7l3,0.7l0-0.5l-1-1.7l-1.2-1.6l-0.4-2l0.2-5.4l0.9-2.1l2.6,0.2l1.5-0.3l0.7,1l1.5-0.1l1.1-3.9l2.1-0.3l1.8,2.6l2.2,1.7
      l1.8,2.5l0.5-0.7l-1-5.8l-1.1-2.1l-2.3-1.1l-2.4-2.6l-0.6-1.2l0.1-0.9l2.1-0.8l2.8,1l2.5-2.2l0.7,0.6l1.9-1.1l1.2,1.5l0.7-0.5
      l0.4-2.1l3.1-1.3l2,1.2l1,1.2l0.5,2.4l0.7,4.6l1.5,2.5l1,1.2l1.1,0.3l0.5-1.2l-1.1-1.5l-0.3-1.4l0.5-3.5l0.6-1.4l3.3-5.3l2.8-2.8
      l1.7-0.2l2.9-6.2l0.8-1.1l0.8-0.3l-0.2-1.5l-1.6-1l-0.1-1.9l2.1-2.3l2.5-3.9l1.2-0.3l0.8,1.1l2.5,1.8l1.5,2l1.1,1l0.7-0.2l0.6-1.5
      l0.7-0.7l1.6,0.4l0.9,1l0.7,0.1l0.7,0.6l0.2,1.3l-1.4,1.4l-2.3,3.7l-2.3,4.2l-0.8,2.2l-0.7,5.7l-1.8,3.7l-0.1,2.6l0.7,1.2l2-0.9
      l2.4-3.4l0.6-3.6l6.1-9.9l2.8-5.5l3.2-4.6l1.8-1l0.9,3.1l-0.7,4l-1.4,2.5l1,1.2l-0.2,3l-0.3,1.7l-0.2,1.7l0,1.5l1-0.4L439,23l1-3.4
      l0.9-2.6l0.4-2.2l1.5-2.1l2.8,0l0.1-0.8l-3.4-2.9l-0.4-1.3l1.1-1.8l3.1-3.4l1.6,0.4l1,0.8l3.8,0.6l2.9,2.4l-0.1,3.8l-0.7,1.6l-0.6,1
      l-3.8,2.8l-0.6,1.4l1.2,0.5l2.5-1.4l0.7,1.3l-0.8,3.2l-0.1,4.8l-0.3,2.8v2.5l0.3,1.4l1-5.4l0.3-1.4l1.5-2l0.6-4.1l1.5-5l1.6-2.9
      l1-0.8l3.2,0.1l1.4,1.1l1.2,2.4l0.9,1l2.8,1l1,1.3l0.2,0.8l0.7,0.2l1.9-1.8l1.2-0.3l2,2.8l-0.4,2.1l0.1,0.7l2.4-0.1l2,0.8l3.9,4.3
      l0.4,2l-0.2,2.4l-5.6,2.6l-2.4,2.5l-4,1L460,35.7l0.3,1.8l9.3,4l0.5,1.1l-0.3,2.4l0,1.9l0.2,1.3l0.7,1.2l1.1,0.6l2.3-0.3l1.1,0.6
      l0.8-1l0.3-3.2l0.7-0.7l1.3,0.9l0.6,3.4l0.4,0.4l0.7-2.4l1.3,0.2l1.4-0.2L484.5,48z M397.4,12.3l-0.2,1.1l-0.4,1.1l-1,1.1l-2.8,4.5
      l-1.6,0.7l-0.5,0.7l-0.7,0.4l-2-0.6l-0.6,0.9l-0.6,0.6l-1.5,0.2l-0.9-0.2l-2.2-1.7l-1.3-1.8l-0.7-1.5l2.1,0l0.7-0.4l1.4,0.3l0.8-1.6
      l1.8,0.2l3.4-1.1l1.2,0.6l2.8-3.7l0.9,0.1l1.4-1.1L397.4,12.3z M404.2,21l-2.2,1.4l-1.3-1.2l-0.5-1.2l-0.1-2.7l0.3-1.7l1-0.9
      l0.7,0.6l0.2,0.5l1.2,0.5l1.4,1.7L404.2,21z M399.5,21.7l0.2,1.6l0.1,1.3l-1.1,1.9l-2.5,2.5l0.1,0.7l-0.9,0.7l-1.3,0.5l-0.7-0.5
      l0.1-2.2l-0.2-0.6l-1,0.9l-1-1.1v-1.1l0.3-1l1-1.5l1.6-1l1,0.4l3.5-3.9l0.4,0.8L399.5,21.7z M354.4,33.2l0.6,0.4l0.6-0.2l0.5,0.4
      l1,1.8l1.1,0.7l0,0.9l-1,0.9l-1.3,0.3l-1.4-0.3l-0.4-1.1l-0.4-1.9l-1.1-1.8l-0.2-1.6l1-0.3L354.4,33.2z M348.4,38.4l1,1.9l0.9-0.2
      l0.3-0.7l0.6-0.3l1.3,1l-0.2,1.7l-1.8,2.1l-1.3,3l-1.6,0.7l-0.8-0.4l-1.4,1.7l-1.2,1.8l-1.2,2.2l-0.1,1.2l-0.2,0.9l-4.4,0.9
      l-1.6,0.6l-1.7-0.7l-0.8-1.5l0.3-0.8l1.7-0.3l0.1-1.4l0.4-0.8l0.5-0.5l0.4-1.8l0.7-0.5l1.2,0.4l0.8-1.3L341,47l0.6,1.1l0.3-1.5
      l-0.3-1.3l0.1-0.9l1.6-2.3l0.7-1.7l1-1.1l1,0.2l0.3-1.6L346,36l0.1-1.1l0.9-2.7l0.9-0.1l0.4,2.4L348.4,38.4z M366.2,37.6l-0.6,0.8
      l-1,0.3l-0.5-0.5l-0.7-0.3l-0.8,0.1l-0.7-1.5l0.1-1.2l0.9-1.7l1.9-0.9l1.5,0.4l0.4,0.5L366.2,37.6z M473.8,47.5l-2.2,1l-0.3-0.8
      l0.5-1.3l0.6-2.6l0.9,0.1l0.9,1l0.7,1.2L473.8,47.5z M327.9,54.3l1.4,1.9l0.6-0.6l1.2-0.2l0.9,0.7l0.8,1.2l0.9,0.1l0.6,1.6l0.3,2
      l-0.7,1.5l-0.9,0.7l-0.3,1.8l0.4,2.5l-2.1,0.9l-2.4,0.4l-0.9-1.2l-1.9,2.2l-1.9,3.4l-0.9,0.4l-0.1-1.1l-1.4-0.7l-1.8,0l0.1-0.8
      l0.3-0.6l1.5-0.8l0.3-1.7l-0.3-3.1l0.3-1.5l0.1-1l1-1.2l3.3,0.6l0.4-1.2l-0.2-0.7l-1.7-1.3l0.3-0.9l1.2-0.8l1.1-0.1l0.3-1.3l0.1-0.6
      L327.9,54.3z M307.4,88.1l0.1,0.2l1.6-3.1l1.8-1l0.1-1.1l0.7-1l-0.1-1.7L312,79l0.9-0.4l0.6-0.5l0.6-0.3l1.1,1.1l0.6,1.2l0.8,2.8
      l-0.3,2.7l-2.1,2.1l-1.7,0.9l-1.7,2.4l-0.9,1.9l-0.7,0.4l-0.5-0.2l-0.4-0.5h-0.9l-1,1.7l-2.9,1.4l-1.1-0.4l-0.1-1.7l-0.7,0.1l-1.1,2
      l-1.1,0.7l-0.7,0.2l-1.3-0.8l-3.5,3.4l-3.3,0.6l-1.1-0.4l0-2.1l2.1-2.7l1.7-1.9l6-1.3l3.7-5.6l0.9-6.1l0.9-2.2l-0.4-1.3l-1-0.2
      l-0.1-1.9l0.5-2.1l2-2.9l1.1-1.2l1.8-3.5l0.9-0.8h1l1,0.9l-0.2,1.9l-1.4,3.4l-2.1,2.9l0.3,2.1l0.8,1.7l0.2,2.9l0.1,2.8l-1.6,3.7
      L307.4,88.1z M300.9,75.8l1.5,3.3l0.7,1.9l-0.6,3.6l-1.5,1.8l-2.3,0.3l-1.6-0.1l-1-0.9l-0.1-1l-0.6-0.3l-1.5,1.2l-1.1,0.2l-1.4-1
      l-0.4-1.5l1.4-1.9l0.7-1.5l1.6,0.1l0.4,0.5l0.9,0.3l0.6-1.8l-0.1-1.3l0.4-0.9l1.9,0.6l0-3.4l0.8-0.2l0.3,0.1l0.6,0.7L300.9,75.8z
      M285.3,97.4L286,98l1.8-0.2l0.4,0.2l-0.3,0.9l-0.8,1l-1.7,0.6l-0.7,1.5l-0.5,0.5l-1.4,0l-0.8,0.3l-1,1.3l-0.8-1l-0.2,0.7l-0.1,1.2
      l-0.5,0.4l-1.4,0.4l-0.3-2.9l0.7-1l0.5-1.2l0.8-0.2l0.7,0.1l1.3-2.7l1.8-0.8l1.1-0.1L285.3,97.4z M274.7,109.6l-1.7,1.6l0.6-3l0.9-3
      l1.3-1.7l0.6,0.7l-0.3,1.5l0,1.4l-0.3,0.7L274.7,109.6z M268.3,164.2l-1.1,0.2l0.2-1.3l0.9-1.2l0.3-0.8l0.2-1l0.8-0.7l1.1,0.9l0,1.6
      l-0.5,1.5L268.3,164.2z M269.3,168.2l-0.9,0.1l0-1.2l0.5-1.1l0.9-0.7l1.1-0.2l1.2-0.1l0.4,0.6l-0.7,0.8L269.3,168.2z M263,176
      l-0.8,0.9l-1.4-0.2l-0.2-0.7l0.4-1.5l0.9-0.6l1.1,0.1l0.4,0.6L263,176z M254.4,197.2l-0.6,0.8l-1.4-0.6l-2.7,0.5l-1.1-0.7l0.9-1.5
      l2.4-1.5l1.3,0.1l1.3,1.9L254.4,197.2z M222,229.4l-1.3,0.1l-0.8-0.6l1.9-1.2l3-1.1l0.3-0.7l0.4-0.1l0.5,0.9l0.1,1.2l-0.3,0.6
      L222,229.4z M217.7,238l-1.1,0l-1.4-0.4l-0.9-0.9l-0.1-0.8l1.6-0.9l1.6-0.5l0.7,1l0.1,1.7L217.7,238z M180.9,294.3l-1,0.3l-0.8-0.3
      l0.3-2.3l0.4-0.4l0.6-0.1l0.7,1.2L180.9,294.3z M182.3,312.8l0,2.8l-1.1-0.2l-0.5-1.1l-0.1-0.7l0.1-1.6l-0.2-1.7l0.3-0.8l0.4-0.1
      l0.7,1.5L182.3,312.8z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="poland"
        d="M352.5,439.4l0.5,0.2l3.3,0.3l3.3,0.3l5.3,0.3l5.6,0.3l5.8,0.3l6.3,0.3l6.6,0.2l0.4-0.1l0.7-0.8
      l0.8,0.1l1,0.5l0.5,0.4l0.2,0.4l0.1,0.5l0.5,0.1l1,0.4l1.3,0.8l1,0.8l1,1.1l0.3,1.3l0,1.5l-0.1,0.9l0.1,0.4l1.3,6.7l2.2,6.4l0.8,3.1
      l0.3,1.6l0.3,2.4l0.1,1.7l0,0.9l-0.2,1.3l-0.7,0.8l-4.3,2.2l-0.8,0.7l-1.3,1.7l-1.2,1.7l-0.3,0.6l-0.1,0.4l0.3,0.6l1.5,0.9l1.5,0.7
      l0.5,0.6l1.1,0.7l0.4,0.6l0.2,0.5l0,1.3l-0.5,1.8l0.2,1.3l-0.5,0.9l-0.4,1l-0.1,1.7l0.8,1.9l0.6,1.3l0.2,1l-0.3,0.8l0.1,0.8l0.6,0.8
      l1.8,2.6l0.9,2.5l0.6,1l1.3,1.2l0.1,0.5l-0.5,0.5l-0.4,0.1l-0.3,0.1l-0.2,0.4l0.3,0.5l0.5,0.7l0.6,1.9l-0.1,1.6l-0.4,0.4l-0.6,0.9
      l-0.4,0.9l-3.1,0.6l-0.7,0.9l-1.7,1.8l-1.1,1l-1.7,1.8l-2.7,3.1l-1,1.3l-0.7,1.1l-2.2,2.9l-0.7,1.2l0.1,1l0.7,2.3l0.1,1l-0.1,1
      l-0.2,0.9l0,0.4l0.6,0.6l1,1l0.1,0.3l-0.1,0.4l-0.4,0.3l-1.3-0.3l-1.4-0.7l-0.5,0.1l-0.8-0.2l-3.2-1.3l-2.1-1l-0.2-0.7l-0.4-0.9
      l-0.9-0.8l-2.1-0.7l-0.8-0.5l-3.4-0.3l-1.5,0l-1,0.2l-0.7,0l-0.9,1.4l-0.6,0.4l-0.9,0l-0.8-0.2l-0.8-0.7l-1.3-0.4l-1,0.2l-0.7-0.2
      l-0.6,0l-0.2,0.2l-0.5,0l-0.7,0.4l-0.8,0.5l-0.9,0.4l-0.7,0.8l-0.6,1.6l-1.7-0.7l-0.6,0.3l-0.8,0.2l-0.5-0.2l0.1-0.5l0.2-0.6l0-0.9
      l-0.1-1l-0.5-0.3l-0.8-0.1l-0.4-0.2l0-0.3l-0.4-0.4l-0.7-1l-0.6-1.3l-0.4-0.4l-0.6,0.6l-1,0.7l-0.6,0.2l-1.2,2l-2.1,0.1l-0.1-0.9
      l-0.2-0.9l-1.2-0.2l0-0.5l-0.3-1.3l-2.5-2.6l-0.3-1.1l0.1-0.4l-0.2-0.7l-0.5-0.4l-2-0.5l-0.5,0.3l-0.5-0.3l-0.7-0.6l-1.2-0.5
      l-0.1-0.3l-0.4-0.4l-0.2-0.1l-0.2,0.3l-0.4,0.4l-1.3,0.5l-0.5-0.2l-0.5-0.4l-0.5-0.9l-0.8-0.8l-0.6-0.3l-0.4-0.4l-0.1-0.3l1.4-0.7
      l0.3-0.7l-0.2-1.2l-0.2-0.2l-0.6,0.4l-1.2,0.4l-1.1,0.2h-0.6l-3.1-2.2l-2-0.7l-1.2-0.2l-0.1,0.2l0.5,1.3l0.9,1.6l0,0.4l-1.1,0.6
      l-0.6,0.3l-0.7,0.5l-0.6,0.7l-0.5,0.3l-0.5-0.1l-0.5-0.4l-1.3-2.3l-1.6-1.8l-0.2-0.4l-0.5-0.1l-0.7-0.4l-0.2-0.5l0.4-0.6l0.5-0.5
      l0.9-0.3l0.3-0.3l0.2-0.5l0.3-0.6l-0.1-0.2l-0.6-0.7l-0.9-0.6l-2.5,0.5l-0.7,0.3l-0.4-0.4l-0.3-0.6l-0.6-0.1l-0.9-0.6l-1-0.6l-1-0.2
      l-2.1-0.8l-0.8,0l-0.5-0.3l-0.5-0.6l-0.4-0.7l-0.2-1.4l-1.6-0.6l-1.5-0.4l-0.1,0.2l0.1,1.4l-0.1,0.8l-1,0.5l-1,0l0.1-0.2l1.2-2.5
      l0.5-1.6l0.6-2.9l-0.7-2.3l-0.2-1.1l-0.3-0.5l-2.1-1.1l-0.2-0.4l0.3-1.5l-0.2-0.6l-0.5-0.7l-0.7-1.4l-0.3-1.2l0.9-1.4l0.2-1l0.4-1.4
      l0.3-0.8l0.1-0.2l-0.6-0.5l-0.1-0.8l0.2-1.1l-0.3-0.8L294,482l-0.5-0.7l-0.2-0.9l0.2-1.4l0.6-1.9l-1.2-2.2l-3.1-2.6l-1.5-1.8
      l0.1-1.1l0.6-1l1.2-0.9l0.9-1.5l0.5-1.8l0-0.3l0-1.3l-1.4-5.3l-0.2-1.3l-0.2-1.6l-0.1-0.4l2.7,1.1l1.1,0.6l-0.1-0.7l-0.2-0.6
      l0.1-0.9l-0.1-1.4l-2.4-0.7l-1.6-0.2l-0.2-1l0.1-0.6l0.4,0.4l1.6,0.1l3.9-1.8l6.7-2.4l7.2-2.3l1.7-0.2l1.7-0.5l0.6-0.9l0.6-0.6
      l1-1.5l2.2-2.3l3.8-0.9l1.4-1.1l3-1.6l6.8-1.8l2.8-0.4l2.8,0l2.5,1.4l2.6,1.7l0.5,1l-1.4-0.6l-2.1-1.5l-0.8-0.1l1.8,4.7l1,1.6
      l1.9,1.2l1.6,0.4l5.1-0.7l1.8-1L352.5,439.4z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="portugal"
        d="M26.7,661.3l0.2,0.1l0.7,0l1.2-0.6l0.8-0.5l0.3,0.2l2.4-0.3l0.6,0.2l0.4,0.3l1.2,0.3l1.3,0
      l1.6-0.5l0.7-0.5l0.1-0.5l0-0.4l0.2-0.3l0.4-0.1l0.6,0.3l0.8,0.2l1.9,0.1l0.4-0.3l0.7,0.1l0.9,0.4l1-0.1l0.5,0.5l0.2,0.6l0.1,1.3
      l-0.1,1.3l0.2,0.5l0.7,0.1l1.1,0l1,0.4l0.8,0.6l0.3,0.6l0.1,0.4l-0.4,0.2l-0.5,0.9l-1.3,1.2l-1.9,1.1l-1.5,1.4l-1,1.6l-1.3,0.7
      l-0.4,0.4l-0.2,0.4l0.8,2l0.3,1.5l0.2,1.9l-0.1,0.5l-0.1,2.1l-0.2,0.6l0.1,0.5l0.3,0.5l0.1,0.5l-0.6,0.6l-1,0.7l-0.8,0.7l-0.2,0.6
      l0.1,0.4l1.3,1.3l0.2,0.5l-0.2,1.3l-0.8,2.1l-0.7,1.3l-0.1,0.1l-0.8,0.4l-3.9,0l-1,0.3l0.1,0.3l0.9,1.6l1,0.9l0.3,0.2l0.3,1.9l1.6,3
      l1.5,0.4l0.5,0.8l-0.1,1.1l-0.5,1.2l-0.9,1.2l-1.1,0.8l-0.7,0.8l-0.1,1l-0.2,1.2l-0.3,1l-0.1,0.7l2.8,4.1l1.5-0.2l0.2,0.1l-0.3,1
      l-0.5,1.1l-0.6,0.2l-1.3,0.3l-1.3,1.5l-1,1.8l-0.8,0.9l-0.7,2.1l0.1,0.9l0.3,1.4l0.7,3.6l-1,0.2l-4,2.4l-1.2,0l-2.3-1l-4.1-0.3
      l-1.3-0.3l-1.7,0.7l-1.3,0l-1,0.9l-0.7-0.2l0.8-1.9l1.3-3.9l-0.1-2.4l0.3-2.1l-0.4-2l-0.7-1.3l0.9-3.3l-0.1-1.7l-0.8-2.2l2.5,0.3
      l-0.8-0.9l-0.8-0.5l-0.7,0.1l-0.6,0l-2.1,0.8l-1.1,0.3l-0.3-0.2l0.1-1.3l-0.6-1.8l0.9-0.5l1-0.1l0.8-0.7l0.5-0.8l-0.3-1.5l0.7-1.4
      l1.7-1.2l-0.9,0.2l-1,0.7l-1.6,2.7l-0.5,1.4l-1.4,0.4l-1.2,0.2l-0.6-0.1l-0.8-0.3l-0.1-1l0.1-0.8l0.5-1.6l0.2-2.3l0.7-2l-0.1-0.5
      l-0.2-0.8l0.6-0.8l0.8-0.5l1.2-1.8l1.7-4.2l1.9-4.5l-0.2-0.5l-0.4-0.4l0.2-1.2l1.2-5.3l0.5-0.7l0.6-1.6l0.1-2.5l0.2-1.7l-0.1-0.9
      l-0.2-1.1l-0.7-2l-0.8-4.3l-0.1-1.4l0.6-0.7l-1.1-0.1l-0.5-0.9l0.1-1.1l1.2-1.7l1.1-1.1l1.1-0.7l0.6-0.3l2.5-0.7l0.7-0.3l0.6,0.1
      l0.1,0.3l0.4,0.7l0.4,0.5l0.1,0.3l-1,1.4l-0.1,0.5l0.5,0.9L26.7,661.3z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="romania"
        d="M453.3,603l1.2,1.7l1.6,0.9l3.6,1l0.3-0.1l0-0.2l-0.2-0.2l0-0.3l0.2-0.4l0.5,0l0.8,0.3l1.6-0.5
      l2.3-1.4l2.1-0.3l1.9,0.8l1,0.9l0.6,0.9l-0.2,1.1l-0.1,0.7l-0.5,2.8l-0.4,1.1l-0.6,1.2l-6,1.4l0.4-0.7l-0.1-1.2l-0.2-0.9l0.6-0.8
      l-1.3-0.3l-0.6,0.4l-0.5,0.8l0.4,1.8l-0.7,1l-0.2,0.5l0,1.3l-0.4,0.6l-0.1,0.6l1-0.2l-0.4,1.1l-1.8,2.1l-0.6,1.3l0.2,5l-0.8,3
      l-0.1,0.9l-1.9,0l-0.6-0.1l-1.8-0.4l-2-0.8l-1.2-1.6l-0.8-1.1l-1.7,0.5l-0.3-0.1l-0.5-0.5l-1.3-0.4l-1.6,0l-3.6-2l-0.4-0.3l-2.8,0.3
      l-4.2,1l-3.2,1.2l-3.3,2.2l-1.3,1.7l-1.6,0.9l-2.2,0.7l-4-0.2l-4.1-0.8L409,630l-2.4,0.5l-3.2-0.4l-4.9-1.1l-3.6-0.3l-3.6,0.6
      l-0.6-0.5l-0.1-0.6l0.1-0.8l0.5-0.6l0.9-0.5l0.5-0.5l0.1-0.5l-1-0.8l-2-1.1l-0.8-0.7l-0.2-0.2l-0.1-0.6l-0.4-0.5l-0.8-0.4l-0.6-0.6
      l-0.4-0.9l0.1-0.9l0.6-0.8l0.8-0.4l0.9,0.1l0.4-0.2l-0.2-0.6l-0.9-0.7l-1.7-0.9l-1.7,0.5l-1.8,1.9l-1.3,0.3l-0.8-1.3l-1.4-0.8
      l-2-0.2l-1.2-0.5l-0.5-0.7l-0.9-0.6l-1.9-0.6l0-0.6l0.3-0.1l0.7-0.1l0.9-0.1l0.1-0.3l0-0.3l-0.7-0.4l-0.7-0.3l-0.4-0.3l-0.2-0.3
      l-0.1-0.3l0.2-0.2l0.3,0l0.3-0.2l0.2-0.7l0.4-0.6l0.3-0.2l0-0.4l-0.3-0.4l-0.4-0.3l-0.6-0.2l-1.8-0.6l-0.9-0.8l-0.6,0l-0.9-0.5
      l-1-0.7l-0.8-1l-0.9-0.7l-0.2-0.3l0-0.3l0.2-0.3l0-0.3l-0.2-1l0.2-1.1l0-1l0-0.5l-0.2-0.1l-0.2,0.2l-0.2,0.2l-0.2,0l-0.7-0.7
      l-0.8-1.5l-0.6-0.5l-1.1-0.7l-0.9-0.6l-0.7-1.3l-0.7-1l0.5-0.4l2.7-0.6l1.2,0.6l0.6-0.2l0.5-0.5l0.3-0.4l0.1-0.4l0.3-0.5l0.9-0.2
      l2.4,0.3l1-0.7l0.4-0.4l0.2-0.8l0.2-0.7l0.9-0.3l0-0.6l-0.1-0.6l0.5-1.5l0.3-0.6l0.5-0.2l0.6-0.5l1-1l-0.2-0.8l0.2-0.6l1.1-1.5
      l0.8-1.5l0-0.7l0.1-0.6l0.7-0.7l0.7-0.9l1-2.9l0.4-0.5l0.6-0.5l0.5-0.5l0-1.9l0.5-0.5l0.9-0.6l0.9-1l0.7-1.2l0.5-0.5l0.7-0.1
      l0.8-0.5l0.9-0.2l0.8,0.2l0.5-0.1l0.8-0.6l2-2.1l0.3-0.4l0.4-0.3l1.7-0.7l0.4-0.7l0.6-0.7l0.7,0.1l2.4,1.6l2.6-0.1l0.5,0.1l0.2,0
      l0.3,0.1l3.4,0.8l0.5-0.1l0.1-0.1l1.4,0.7l1.2-0.1l1.2-0.5l1.2-0.2l1.1,0.3l0.8,0.9l2.2,2l0.7,0.7l1-0.1l1.1-0.4l1.1-1.3l3.5-1.5
      l2.6-0.4l2.6-0.6l3-0.4l0.9-1.2l0.5-0.9l0.3-1.6l1.6-0.5l1.5-0.3l0.5-0.2l1.1-0.1l0.9,0.1l1.3,0.8l0.9,1l0.4,0.8l0.8,1.1l0.8,1.5
      l0.9,2l0.2,1l0.4,1.1l0.7,1.4l1.3,1.5l0.2,0.3l0.6,1.1l1.2,2.3l1,0.9l0.8,1l0.4,1l0.6,0.9l1.4,1.2l1.1,1.1l0.9,3.2l0.6,1.5l0.4,1.1
      l-0.2,2.2l0.3,1l-0.5,1.7l-0.9,3.5l-0.2,2.8l0.2,1.5l0,1l0.2,0.6l0.3,1.3l0,1.1l-0.3,0.3l-0.5,0.3l-0.2,0.2l0.4,0.5l0.6,0.9
      L453.3,603z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="serbia"
        d="M361.1,645.4l0-0.4l-0.1-0.6l-0.8-0.7
      l-1.2-0.5l-2.6-1.8l-1-0.2l-0.9-0.2l-1.3-0.9l-0.7-0.2l-0.7-0.6l-1.6-2.1l-1.4-1.1l-0.9-0.6l-0.3-0.6l-0.1-0.6l0-0.2l0.7-0.8
      l0.5-0.1l0.7,0l0.5,0.4l0.6,0.1l0.3-0.5l0.2-0.8l-0.1-1l-1.5-2.3l-1.2-1.6l-0.1-0.4l0.3-0.3l0.4-0.2l0.5,0.1l1.2,0.1l1.2-0.2
      l0.4-0.4V626l-0.4-0.5l-1.4-1.3l-1.1-1.2l-1.3-0.9l-0.9-0.4l-0.3-0.5l-0.1-0.5l0.1-0.9l0.1-1.1l0.2-0.7l0.8-1.3l0.8-1.4l0.5-1.4
      l0.3-1.3l-0.1-0.4l-0.4-0.3l-0.9-0.3l-1.2,0.2l-1,0.5l-0.4,0l-0.1-0.6l0.2-0.3l0.3,0l0.3,0.1l0.3-0.3l0.2-0.8l-0.4-2.7l0.8-0.2
      l0-0.4l0.1-0.3l0.8,0.5l1.1,0l1-0.1l0.1-0.3l0-0.4l-0.2-0.3l-0.4-0.2l-0.3-0.4l-0.7-0.2l-2.1-1l-1-1l0-1.1l0.3-0.6l0.4-0.2l-0.1-0.2
      l-1.2-0.5l-0.4-0.7l0.3-0.9l-0.6-1.9l-0.7-1.1l0.6-0.5l0.1-0.7l0.1-0.4l0.3,0l1-0.5l0.4-0.4l0.2-0.5l0.2-0.1l0.7,0.5l0.7,0l0.8-0.3
      l0.6-0.4l0.7-0.4l0.3-0.2l0.4-0.4l0.9-1.1l1-0.2l1.3,0.3l1.4,0.1l1.1-0.3l2.7,0.3l0.6,0.3l0.4,0.3l0.7,1l0.7,1.3l0.9,0.6l1.1,0.7
      l0.6,0.5l0.8,1.5l0.7,0.7l0.2,0l0.2-0.2l0.2-0.2l0.2,0.1l0,0.5l0,1l-0.2,1.1l0.2,1l0,0.3l-0.2,0.3l0,0.3l0.2,0.3l0.9,0.7l0.8,1
      l1,0.7l0.9,0.5l0.6,0l0.9,0.8l1.8,0.6l0.6,0.2l0.4,0.3l0.3,0.4l0,0.4l-0.3,0.2l-0.4,0.6l-0.2,0.7l-0.3,0.2l-0.3,0l-0.2,0.2l0.1,0.3
      l0.2,0.3l0.4,0.3l0.7,0.3l0.7,0.4l0,0.3l-0.1,0.3l-0.9,0.1l-0.7,0.1l-0.3,0.1l0,0.6l1.9,0.6l0.9,0.6l0.5,0.7l1.2,0.5l2,0.2l1.4,0.8
      l0.8,1.3l1.3-0.3l1.8-1.9l1.7-0.5l1.7,0.9l0.9,0.7l0.2,0.6l-0.4,0.2l-0.9-0.1l-0.8,0.4l-0.6,0.8l-0.1,0.9l0.4,0.9l0.6,0.6l0.8,0.4
      l0.4,0.5l0.1,0.6l0.2,0.2l-0.4,0.3l-0.5,0.4l-0.3,0.7l-0.1,1.2l-1.5,0.9l-0.6,0.2l-0.3,0.6l-0.4,1.7l0,1.3l0.2,0.7l0.1,0.5l0.5,0.7
      l0.4,1l0.3,1.3l0.7,1l1.7,1l0.8,0.6l0.6,0.9l0.5,0.8l1.4,1l-0.1,0.7l-0.3,0.7l-0.3,0.3l-0.7,0.9l-0.7,0.5l-1.1,1.6l-1.7,0.1
      l-0.4,0.1l-0.7,0.4l-0.3,0.8l0.3,0.6l0,0.7l-0.3,1.3l0.4,1.4l0.6,0.6l0.1,0.4l-0.1,0.6l-0.9,1.3l-0.3,0.5l-0.9,0.2l-0.3-0.1
      l-0.5-0.4l-0.4-0.1l-1.1,0.5l-1.1,0.3l-0.9-0.2l-0.9,0l-0.6,0.2l-0.5,0.1l-0.9,0.6l-1.4,0.4l-0.7-0.1l-2,0.4l-0.7,0.5l-0.4,0.9
      l-0.1,0.5l-0.4,0l-0.6-0.5l-0.7-0.7l-1,0.1l-3.3,1.6l-0.3,0.8l-0.1,1.8l-0.2,0.5l-0.4,0.3l-1.4-0.2l-0.1-0.1l0.2-0.7l-0.1-1.5
      l-0.6-2.5l-0.4-0.8l-1.6-1.3l-1.3-0.5l-0.6-1.4l-1-1.6l-0.5-0.4l0.1-0.2l0.2-1.2l-0.3-0.9l-0.4-0.7l0.3-0.4l0.9,0l0.7,0.1l0.3-0.7
      L361.1,645.4z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="sweden"
        d="M405.8,171l-3.1,0.7l-2.3-1.3l-1.2,0.7l-2,0l-2.3,0.5l-0.8,1l-0.6,0.4l-2.1-1.5l-2-2.4l-1.5,1.8
      l-1,0.3l-0.9-1.7l-0.8-0.3l-0.4,0.6l-0.4,1.5l-0.6,1.2l-0.2,0.7l-0.1,2.9l-0.1,0.7l-2-0.4l0.1,0.8l0.4,0.4l0.2,0.5l-0.7,0.6l-2-0.1
      l-0.2,0.7l0.5,1.1l-0.4,0.9l-0.4,0.4l-2.3,0.6l-1.3-0.1l-0.4,0.6l-0.1,0.8l0.3,0.8l0.6,0.4l0.2,0.5l0,1.1l-0.5,0.2l-1.4-1.8
      l-0.4,0.1l0.3,1l0.8,1l0.5,1.1l0.4,1.3l-0.1,1l-1.7,3.1l-1.5,2l-1.1,1.8l-0.7,1.9l0.8,0.9l0.9,1.4l0.6,2.6l0.7,2.3l1.5,2.2l-0.3,1.3
      l-0.3,1l-2.5,2.2l-2.8,3.3l-3,8.2l-1,1.1l-2.6,1.4l-1,1.3l-2,1.6l-3.4,1.4l-1.5,1.9l-0.7,2l-0.8,0.1l-0.8-0.8l-1-0.6l-0.1,1.3l0,0.9
      l-1.6-1.4l-0.8,1.3l-0.6,2.1l-2.4,2.8l-2.6-0.5l-0.3,0.5l0.7,0.4l0.1,0.5l-0.5,0.2l-0.7,0l-1.1,0.6l-0.7,0l-0.3,1.3l-0.6,1.6
      l-1.4,0.7l-0.7,0.1l-0.4,0.9l2.2,0.2l-0.2,0.8l-0.1,0.8l-0.2,0.8l-2.5,1.2l-0.4,0.9l-0.5,0.6l-1.1,0l0-0.5l0.2-0.6l-1.7,0.1
      l-0.5-1.4l-0.3,0.3l0.2,1.1l0.4,1.1l0.5,1.7l-0.4,1l-0.4,0.5l0.3,0.5l0.9,0.4l0.4,0.7l-1.1,0.6l-1.3,1.9l-1.4,0l-0.8,1.3l-0.9,0
      l-0.7-0.8l-1.2-0.7l-0.4,1.1l-0.1,0.9l0.7,2.3l1.2,1.8l1.2,0.8l-0.8,0.5l-0.6,1.1l-0.7,3.6l-0.4,1.4l-0.4,2.5l0.3,2.1l0.2,1l0.6,1.4
      l-1.5-0.2l-1.6-0.8l0.2,1.7l-1,2l0.2,1.7l0.2,1.1l-0.3,1.9l0.5,0.6l0.3,1.1l-0.4,0.8l0.2,0.7l0.1,2.5l0.3,3.9l-0.1,0.8l0.9,3.3
      L325,302l-0.1,1.5l1.3,1.4l1.1,0l1.2,0l0.4,0.4l0.4,1l0.4,1.2l0.9-0.1l1.5-1l1-0.2l0.7,1.9l1.8,2.4l1,1.1l1.8,0.6l1.8,2l-0.3,2.4
      l0.8,0.8l2.2,0.9l0.8,1.3l0.4,1.1l0.6,0.9l0.7,2.7l-0.2,1.6l-0.9,0.6l-2.1,1.7l-0.9,1.3l-0.7,0.8l-2.1,1.7l-0.8,0.3l-0.7,0.9
      l-0.7,0.4l-0.6-0.2l-2.3,1.6l-1.6-0.4l-0.7-0.8l0.4-3.2l-0.3-1l-0.3-0.5l-1,0.6l0.2,1.2l-1.8,0l-1.7-1l-0.2,2l-3.6-1.7l-1,0.4
      l-1.6-0.8l-1,1l-1.2-0.4l-0.9-1.2l-1.2,2.1l-2.6,0.6l-2.4,0.3l1.2,0.7l5.5-0.1l1.5,0.5l2-0.3l1.8,1.7l1.3,0.4l1.5,1.9l0.8-0.5
      l1.2,0.1l1.1,0.5l3.6-0.1l1.2-1.3l1.8,0.3l0.9-0.3l0.7-0.8l0.8-0.2l0.7,0.2l0.7-0.6l0.6-0.3l0.6,0.3l0.7,1.5l-1.4,0.8l-1,0l-0.5,2.5
      l-0.6,1.1l-0.4,0.5l-2.2,1.1l-1.4,1.4l-1.7,1.1l-0.7-0.2l-1.1,1.1l-2.5,1.3l-1.3,1.8l-2.9,1.6l-1.5,1.3l-4,0.1l-3.8-0.3l-1.2,0.6
      l1.2,0.2l0.9,0.6l1-0.2l2.4,0.3l1.2,0.3l1.6,2.1l-1.2,0.7l-2,0.6l0.8,2.9l0.6,2l-0.8,1.2l-0.1,5.4l-1.1,0.1l-0.5,2.2l0.4,1.1l0,2.6
      l0.2,1.6l0.6,1.5l-0.2,1.5l-1.8,3.6l0,1.7l0.3,1l0.2,1.6l-0.8,3L315,391l-0.7,2.1l-1.5,2.5l-0.8,1.9l-1.8,5.8l-0.9,1.2l-1.1,0.9
      l-1.2-0.8l-1.1-0.4l-1.4,0.1l-2.1,0.7l-3.2-0.4l-3.2,0.2l-0.8,0.6l0.5,2.1l-1.2,0.3l-1.1-0.6l-1,0.7l-0.8,0.8l-1.6,1.9l-0.6,1.1
      l-0.1,2.1l0.9,1.9l0.8,2.2l-2,2.7l-1.1,0.1l-3.2-0.7l-5.7,1.7l-5.1-1.3l0.6-1.4l0-1.1l0.3-1.6l0.2-1.7l0-1.1l-0.4-1.2l-1.3-1.6
      l-2.8-5.3l-0.8-2.3l-0.6-0.9l0.4-0.1l2.3,1.2l0.5-0.1l0.6-0.5l-0.7-1.7l-0.6-0.8l-0.4-1.2l1.4-0.3l1,0.1l0.7-1.3l-0.4-2.2l-1.1-0.7
      l-0.9-0.3l-1.7-3.4l-1.8-1.8l-3.2-6.9l-1.2-4.7l-1.1,0.5l-0.5-2.1l-0.4-2l-0.1-1.4l-1.7-0.8l-0.1-1l-0.3-4.6l-1.8-0.6l-1.2-2.6
      l-0.2-4.9l-1.2-0.9l-1,0.2l0-1.2l0.2-1.1l-0.6-4.6l-0.2-4.2l-0.5-1.3l-0.3-1.5l0.2-1.3l0.3-0.7l1.2-0.2l1.1,1.1l1,2.9l0.9,0.4
      l1.2-0.7l0.8-2.1l0.5-3.1l0.5-3l-0.6-3.2l-0.7-2.9l0-0.8l1.8-2.4l0.6-2l0.6-1.9l0.7-0.6l1-0.1l1.1-0.4l1.4-1.3l1.3-1.7l1-1.6
      l0.3-3.1l0-1.6l0.4-1.2l0.4-2.3l-0.4-2.2l-1.3-3.4L267,299l-0.2-2.7l0.7-0.5l1.3-0.4l2.5-0.1l0.3-0.3l0.3-1.2l0.6-1.6l0.6-1.2
      l0.4-1.7l0.2-1.5l-1.4-2.3l-1.9-2.3l-1.3-0.8l-2.3-2l-1.6-1.6l0.9-6.3l0.7-4.7l0.1-1.2l0-1.8l-2.2-7.7l0.1-1.7l0.2-1.6l-0.2-2.7
      l-0.1-2.4l0.4-0.7l0.9-1.4l-0.9-2.1l-0.1-0.2l-1.6-5.2l2.5-5.2l-0.4-2.7l1.5-2l2.7-4.5l1.5-2.6l0.3-0.4l1.2-1.1l2.3-1.3l2.5-0.7
      l1.1,0l4.4,0.9l3.4,0.7l0.5-0.7l0.7-1.5l0.9-2.1l0.1-2.3l-0.3-3.4l-0.5-2.1l-2.4-1.3l-2.6-1.8l3.2-5.9l2.2-4.1l2.8-6.2l0.9-2.6
      l0.6-1l0.8-9.7l0.5-2.7l0.5-1.4l0-1.5l-0.3-2.5l-0.8-5.7l4.4-0.7l1.4-0.4l1.3-0.7l2.6-1.8l1.3-1.6l-0.7-5.4l1.6-1.8l3.8-6.4l4.1-6.2
      l1.9-2.3l0.2-1.1l0.2-1.8l-0.9-2.9l-0.9-1.8l-1.8-3.4l0.8-2.4l1.3-0.5l1.8-1l1.4-2.1l0.1-0.3l2.3-8.2l4.5-4.2l1.8-2.3l2.8,1.7
      l4.1,2.6l1.8-3.8l0.6-1.4l0.6-2.1l-0.2-3.7l-0.1-4.8l0.2-1.9l1.6-0.9l0.9-0.2l4.6,2l1.2,0l2.2,0.3l2.4,0.9l5.1,2.3l2.1,1l1.2,0.2
      l1-1.1l2.2-2.8l-3.2-2.1l2.1-2.1l1.1-2.1l0.9-2.6l0.3-3.1l-0.1-1.6l-0.6-1.1l-1.9-2.8l4.4-0.4l1.5-0.1l3.2,1.9l0.1,0.4l0,1l0.1,1
      l3.1,2.5l0.9,1.3l1.9,2l0.5,1.1l1.8,1.3l1.3,1.4l1.5,1.1l1.7,1.7l2.3,1.4l2,0.4l4.9,2.3l0.8,0.7l1.4,1.6l1.4,1.9l1,3.8l1.6,0.2
      l0.4,1.3l1.4,2.2l1.9,2l-0.1,0.7l-1.5,1.8l-0.2,2.4l0.1,3.1l0.5,2.5l0,0.7l-0.4,0.9l-0.5,1.4l-0.2,1.2l0,0.6l0.2,0.3l0.8,0l1.4,0.3
      l0.9,0.5l0.5,2.9l-0.2,0.5l-1.2,1.3l-0.4,1l0,1.5l0.2,1.6l0.4,1.8l1,2l1.3,2l0.9,1.7l0.4,1.2l0.1,0.8l-0.6,1l-0.5,2l-0.1,2.3
      l-0.2,1.5l-1.1,2.1l-0.8,0.7l-0.2,1.1l-0.1,1.8l0.2,2.2l0.1,1.5l0.2,1.1l0.4,0.7l1.8,1.2l1,2.5l0.6,2L405.8,171z M340.7,332.2
      l-0.3,0.8l-0.3-0.9l0.1-0.2l0.2-0.9l0.6-0.5l0.9,0.3l0,0.2l-0.9,0.7L340.7,332.2z M338.5,342.3l-0.5,0.2l-0.3-0.1l0.3-1.1l0.2-0.5
      l0.8-0.4l0.2,0.1L338.5,342.3z M347.2,366.9l-0.2,1.4l-0.6-0.1l-0.6-1l1.1-1.5l1.7,0.1l0.6,0.3L347.2,366.9z M346.3,368.8l-1,0.5
      l-0.6,1.5l-0.8,0.3l-0.8,0.5l-0.3,4.8l1.4,1.8l-0.8,0.3l-0.7,0.5l-0.5,0.8l-0.5,1.7l-1.9,1l-0.7,0.7l-1.1,1.6l-0.6,2.3l-1.1,1
      l-1.2,0.2l0.7-1.9l0.9-1.6l-0.9-1.1l-0.5-1.7l-0.7-1.3l0.6-1.4l-0.3-2.4l0.1-2.3l0.8-1.2l0.9-1l1.4-2.2l1.5-1.6l2.2-0.7l1,0.7
      l0.4-1.5l0.7-0.3l0.7,0.3L346.3,368.8z M316.4,401.9l-0.6,1.1l-0.5-0.1l-0.4-1.4l-0.1-3.6l0.2-1.8l2.6-6.5l1.1-0.5l1.6-4l0.4-1.8
      l0.7-1.6l0.4-1.5l0.3-0.6l0.7,0.3l0.3,0.3l-0.8,0.8l0.1,1.1l-0.1,0.5l-2,4.8l-0.5,3.1l-0.7,0.8L316.4,401.9z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="slovenia"
        d="M316.3,585.4l-1-0.4l-1.2-0.2l-0.2,0.2l-0.5,0.2l-0.3,0.4l0.2,1.6l-0.3,0.3l-1.4-0.2l-0.5,0.2
      l-0.8,1.1l-0.8,0.5l-1,0.3l-0.7,0.4l-0.9,0.3l-0.8,0.2l-0.3,0.5l-0.2,0.5l0.1,0.5l0.8,1l0.1,1.1l-0.1,1.3l-0.2,0.7l-0.3,0.5l-2,0.6
      l-2.1,1.1l0,0.2l0.9,1l0,0.2l-0.8,0.5l-0.1,0.5l0.1,0.6l0.4,0.7l0.1,0.6l-1.1,0.4l-1.5-0.2l-1.8-0.8l-0.6,0.1l-0.6,0.4l-0.6-0.2
      l-0.7-0.5l-1-1l-0.5-0.6l-0.2-0.7l-0.3-0.1l-0.4,0.2l-0.3,0.8l-0.9,1.5l-0.7,0.4l-1-0.1l-1.4,0l-0.9,0.1l-1.1-0.5l-0.3,0.1v0.3
      L286,603l-0.7,0.4l-3.1-0.8l-0.4-0.7l0.7-0.3l1-0.9l0.7,0.1l0.8-0.2l0.4-0.4l-0.5-1.1l-1.3-1.3l-0.7-0.5l-0.9-0.3l-0.2-0.4l0.5-2.1
      l-0.2-0.3l-1.1,0.1l-0.2-0.2l-0.1-0.4l0.1-0.5l0.7-0.8l0.8-0.7l0.2-0.4l0-0.3l-1-0.3l-0.6-0.3l-0.5-0.1l-0.3,0.2l-0.2-0.2l-0.3-0.6
      l0.2-0.9l0.9-0.9l1-0.8l0.9-0.6l0.5-0.2l0.2-1l0.5,0.1l1,0l1.1,0.2l1.1,0.3l0.9,0.3l2,0.4l1.8,0.2l0.5,0.2l0.4,0l0.5,0.3l0.3-0.2
      l0.2-0.4l1-0.5l0.9-0.6l0.6-0.8l0.4-0.6l0.6-0.4l0.7-0.1l0.6-0.2l2.5-0.3l2.6,0.2l1.2-0.4l1-0.7l1.5-0.2l0.1,0l2.2,0.6l0.2-0.3
      l0.1-0.1l0-1.6l0.7-0.7l0.7-0.3l2.2,0.1l0.3,0.5l0.1,0.8l0.2,1l0.4,0.3l0.2,0.4l0,0.7l0.4,0.5l1,1.4L316.3,585.4z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="slovakia"
        d="M386.8,540.9l-0.2,0.7l-0.5,0.9l-0.6,0.9l-0.5,1.1l-0.7,2.3l-0.4,1l-1.8,2.1l-0.1,2.9l-0.2,0.2
      l-4,1l-0.5-0.1l-0.6-0.6l-0.3-0.4l-0.2-0.3l-0.4-0.8l-0.5-0.6l-0.7-0.5l-0.6-0.5l-0.8,0l-2.2,0.8l-1.5,0.1l-1-0.2l-1.3-0.5l-2.6-0.1
      l-1.8,0.4l-0.2,0.6l-1.7,3.5l-2.4,1.3l-2.1,1.3l-0.6,0.3l-1-0.4l-1.2-0.8l-1-0.4L352,556l-0.8,0.9l-0.4,0.9l-2.4,0.7l-4.1,0.4
      l-1.4,0.9l-0.5,1.1l0,0.8l0.4,0.7l-0.4,0.8l-0.2,0.3l-2.9,0.2l-3.9,0.2l-2.3-0.1l-2.2-0.1l-1.5-0.7l-1.8-1.3l-1.9-1.8l-0.2,0
      l-0.3-0.2l-1.2-0.1l-0.3,0.1L323,559l-0.2-0.8l-1.1-2l-1.3-3.3l0-1l0.5-1.1l0.5-0.8l0.1-0.7l0-0.2l0.4-1.4l0.9-1.8l0.9-1.1l0.6-0.4
      l1.3,0.3l2.2,0.3l1.7-0.2l1.6-0.8l0.8-0.7l0.7-0.8l0.2-0.5l0.3-0.2l1.3-0.4l0.4-0.5l0.2-1l0.1-1.1l0.3-0.8l0.3-0.6l2.4-1.4l0.2-0.5
      l0.4-0.5l0.7-0.5l0.7-0.8l0.7-0.5l0.9,0.1l0.9-0.1l0.7-0.3l0.3,0l1.2,0.2l0.2,0.9l0.1,0.9l2.1-0.1l1.2-2l0.6-0.2l1-0.7l0.6-0.6
      l0.4,0.4l0.6,1.3l0.7,1l0.4,0.4l0,0.3l0.4,0.2l0.8,0.1l0.5,0.3l0.1,1l0,0.9l-0.2,0.6l-0.1,0.5l0.5,0.2l0.8-0.2l0.6-0.3l1.7,0.7
      l0.6-1.6l0.7-0.8l0.9-0.4l0.8-0.5l0.7-0.4l0.5,0l0.2-0.1l0.6,0l0.7,0.2l1-0.2l1.3,0.4l0.8,0.7l0.8,0.2l0.9,0l0.6-0.4l0.9-1.4l0.7,0
      l1-0.2l1.5,0l3.4,0.3l0.8,0.5l2.1,0.7l0.9,0.8l0.4,0.9l0.2,0.7l2.1,1l3.2,1.3L386.8,540.9z"
      />
      <path fill="#333333" stroke="#F4F4F4" strokeWidth="0.560223" d="M269,628.3l-0.7,0.1l-0.3-0.7l0.5-0.8l0.7-0.1l0.1,0.6L269,628.3z" />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="turkey"
        d="M451,659l-0.3,1.8l0.7,2l1.7,2.7l1.7,1.4l7,3.4l1.3,0.3l-0.3,1.4l-0.4,1.2l-0.5,0.8l-2.1,0.5
      l-5.7-1.5l-1.4-0.1l-1,0.3l-1.9,1.1l-2.1-0.4l-2.9,0.6l-0.8,2l-2,2.3l-3.3,1.9l-2.4,1l-3.6,3.6l-1.6,2.1l-0.7,0.4l-0.8,0.3l0.3-1
      l0.4-0.9l-0.1-0.7l0-1l1.2-1.2l1.1-0.8l3.2-1.5l0.8-1.3l-2.5,0l-2.5,0.3l-1.6-0.2l-1.4,0.1l-0.4-1.1l-0.3-0.7l0.4-0.2l0.5-0.1
      l0.8-1.2l0.7-0.9l1.1-1.1l0.3-0.7v-0.6l-0.3-0.4l-0.1-0.5l0-0.7l0.1-1.5l2.4-1.6l0.8-0.2l0.3-0.7l-0.2-1.7l-0.3-1.4l-0.4-0.1
      l-0.6-0.4l-0.4-0.5l-0.6-0.5l-1-0.3l0-0.4l0.1-0.4l0.4-0.4l1.8-0.4l0.2-0.3l0.2-0.8l0.4-0.8l0.4-0.3l0.7,0l1.4-0.2l1-0.3l1-0.5
      l0.5-0.5l2.1-0.3l0.6-0.3l0.6,0.2l0.8,0.9l1.3,1.2l0.7,0.4l0.5-0.2l1-0.4h0.9l0.7,0.1l0.4-0.4l0.6-0.1L451,659z M609.2,666l0.8,0.3
      l1.5,0.4l0.9,0.5l0.5,0.1l1.2-1l1.8,0l1.6,0.1l0.8,0.2l1,0.3l1.2,0.2l0.5-0.5l0.7-1.4l0.3-0.2l0.2-0.1l0.9-0.1l0.8,0.1l0.4,0.2
      l0.4,1.1l1,0.4l1.8,1.8l1.1,0.7l0.3,0.3l-0.4,0.4l0.1,0.4l0.6,0.6l0.9,0.2l0.9-0.1l0.5,0.2l0.4,0.3l0.1,0.5l0,0.3l0.2,0.7l0.7,0.9
      l0.9,0.6l0.5,0.6l0.8,2.1l0.3,1.1l-0.1,1.1l-0.5,1.1l-1.2,1.4l0.3,0.6l0.3,0.8l-0.1,0.6l0.8,1.8l0.4,1.1l-0.3,0.3l-0.2,0.4l1.5,0.9
      l1.8,0.7l0.7,0.1l2-0.3l1.3-0.1l1.3,0.7l1.9,1.6l2,2.1l0.4,0.6l0.2,0.3l0.4,0.5l-0.4,0l-0.7-0.5l-1.6-1.3l-0.8,0.6l-0.7,1l-0.8,3.7
      l-0.6,0.4l-1.1,0l-1.4-0.1l-0.9,0.2l-0.2,0.2l0.1,0.4l0.3,0.6l0.2,0.8l0.1,0.6l0.5,0.6l0.7,0.5l0,0.5l-0.1,0.8l-0.2,0.6l-0.2,0.3
      l0.3,0.9l0.7,1.1l0.5,0.4l-0.2,2l0.3,0.9l0.2,1.2l-0.1,2l0.1,0.5l0.2,0.2l0.7,0.1l0.6,0.2l0.2,0.3l0,0.2l-0.8,0.9l-0.1,0.7l-0.3,0.9
      l-0.2,0.5l-0.7,1l-0.5,1.1l-0.2,0.9l0.1,0.4l1.3,0.1l0.7,0.6l1.9,1.2l0.3,0.5l-0.5,0.8l0,0.3l0.3,0.4l0.1,0.7l0,0.8l0,1l0.4,0.2
      l1.3,1l0.9,1l0,0.3l-0.4,0.8l0.1,0.9l0,0.2l-0.4-0.3l-0.7-0.1l-0.7,0l-0.5,0.3l-0.8,0.7l-1.1,0.8l-0.9,0.7l-0.5,0.5l-0.4-0.1
      l-0.3-0.4l-0.2-0.6l0.1-2.2l-0.2-0.7l-0.4-0.5l-0.5-0.3l-0.6-0.2l-0.6,0l-0.9,0.6l-1.2,0.7l-1.9-0.1l-1.3-0.1l-0.6-0.1l-2.5-1
      l-0.5,0l-0.9-0.4l-1.1-0.3l-1.8,0.6l-0.8-0.2l-1.1-0.5l-0.4,0.1l-1.2,1.6l-2.1,1.8l-1.1,0.3l-0.5-1.8l-0.5-0.7l-0.2-0.1l-0.5-0.2
      l-0.4,0.1l-1.3,1.2l-2,0.7l-1.7,0.4l-2.7,0.5l-2.1,0.3l-0.9,0l-1.9-0.2l-1.7-0.3l-1.7,0l-1.3,0.2l-3,1.3l-5.1,2.7l-3.9,1.3l-2.2,0.5
      l-1.7,0.3l-2.9,0l-2.4-0.2l-1.6,0l-0.9-0.3l-1.3-1.1l-1.6-1.1l-0.7-0.2l-0.9-0.2l-1.3-0.1l-3.3,1.6l-1,0.4l-1.1,0.3l-2.3,0.9l-1,0.5
      l-1.3,0l-1.6-0.1l-1.4,0l-0.9-0.7l-0.5-0.8l-1.9-0.5l-1.4-0.1l-0.4,0.4l-0.4,1.1L551,741l-0.1,0.7l1.2,2.8l-0.1,0.4l-0.9,0.1l-1,0
      l-0.7,0.2l-0.5,0.5l-0.3,2.4l-1.2,0.4l-0.6,0.5l-0.6,1.5l-0.3,0l-1.9-1.1l-0.9-0.1l0.8-1.2l-0.8-2.3l-0.9-2.2l0.8-1.4l1.7-1.7l1.8-2
      v-1.2l-0.1-0.9l-0.5-0.6l-1-0.9l-1.7,0.9l-1.2,1l-0.8,0.2l-0.9,0.6l-0.4,1l-1,0.8l-1.7,0.3l-2.5-0.9l-2.7-1.3l-1.5-1.1l-1.3-0.3
      l-1.2,0.5l-3.5,2.6l-3.2,3.8l-0.8,0.7l-3,1.6l-2,0.5l-0.9-0.1l-4,0.7l-2,0.1l-1.6,0.9l-3.1-0.9l-1.8-1.2l-1.1-1.2l-1.8-2.6l-1.3-1.2
      l-2.9-1.1l-5-2.7l-1.3-0.3l-3.4-0.4l-3.6-0.2l-0.7,1l-0.3,3.9l-0.6,1.1l-0.3,2l-0.4,0.6l-0.7,0.4l-1.1-0.6l-0.7-0.3l-1.7,0.8
      l-3.4,1.2l-1.2,0.2l-4-1.5l-1.5-0.9l-0.9-1l-0.3-1.8l-0.6-1l-0.1-0.7l-0.2-0.8l-0.8-0.3l-0.9,0.6l-0.9,0l-1.2-0.4l-2.7-1.5l-2.1-0.1
      l-1.3,1.8l-1,0.6l-1.1,0.2l-0.1-0.5l0.8-1.2l-3.3,0.2l-1.7,0.9l-1.4-0.1l-1-0.4l0.2-0.5l1-0.2l0.9-0.4l3.6-0.3l0.8-0.3l0.9-1.3
      l1.7-1.1l0.2-0.5l-1.3,0l-5.5,0.3l-3.7-0.2l-0.4,0.5l-0.6,0.1l-0.2-1.5l0.6-0.7l0.8,0.1l2-0.6l-0.2-1.2l-1.4-0.8l-0.3-0.5l-1-0.1
      l-0.8-0.6l-0.2-1.5l-0.7-1.6l-0.9-0.8l0.1-0.4l1.7-0.6l0.4-2.3l-0.3-1.4l-0.9-0.1l-2.5-1.1l-0.8,0.1l-0.8-1.2l-1.5-0.9l-0.7,0.3
      l-0.5,0.4l-0.7-0.2l-1.1-0.8l-1.1-0.4l-0.5-0.5l0.6-1.4l0.9,0l0.2-1.1l-0.7-1.8l0.1-0.9l0.7-0.3l0.8,0.2l0.9,1.1l0.3,1l-0.2,1l0.5,1
      l0.4,0.2l0.3-1l0.4-0.2l0.5,0.4l1.1,0.2l2.8-0.6l0.5-0.5l-2,0.1l-0.7-0.5l-0.8-1.1l-0.5-1l-0.1-0.5l-0.3-0.7l0.3-0.4l1.4-0.6
      l1.2-1.7l-0.5-0.5l-0.6-0.2l-0.6,0.2l-0.6-0.6l-0.1-0.8l0.5-0.6l0.1-0.9l-1.6-2.2l-0.4-0.5l0.3-0.7l1.2-1.2l1.1-1.5l-0.1-0.5
      l-0.8-0.2l-4,0.6l-1.6,0.5l-2.8,0.3l-0.2-0.8l0.1-0.7l0.6-1.3l-0.1-3.3l0.4-1.8l1.5-0.5l1.9-2.6l3.1-3.1l3.2,0.1l1.3-0.9l1.9,0
      l0.3,0.6l0.2,0.6l1.7,0.9l3-0.1l0.7-0.3l0.7-0.5l-1.4-1.5l0.4-0.4l1.2,0l1.3,0.4l0.1,0.3l-0.4,0.5l-0.4,0.8l0.4,0.2l3.8-0.5l4,0.4
      l1.3-0.2l3.1,0l0.6-0.5l-1-0.7l-0.9-0.2l-0.6-0.3l-0.6-0.5l2-1.5l1.1-0.3l5.3-0.9l4-0.5l0.1-0.3H472l-5.1-0.8l-1.2-0.6l-1.7-1.4
      l-0.4-0.4l-0.4-0.7l0.3-1.5l0.3-1.2l0.6-0.7l2-0.1l7,1.2l5-0.7l5.4,1.7l5.2-0.3l1.1-0.8l1.3-2.5l7.4-4.2l2.6-2.2l2.8-1.2l4.7-1.3
      l4-1.8l1.1-0.2l9.5,0.8l6.5,0.1l3-1.7l1.7,0.6l-0.2,0.6l-0.3,0.5l0.1,1l1,1.5l1,1l3,1.5l4.2-1.2l0.7,0.1l0.9,0.3l1.5,4l1.2,1.4
      l1.5,1l1.2,0.2l0.9-1l0.7-0.4l1.5-0.2l2.5,1.4l0.9,1.4l4.3,1.1l3.9,0.5l1.7,1.2l5.5,1.2l2.1-0.2l3.5-1.2l6.7-1.4l4.5,1.9l1.2,0.2
      l1-0.2l1.5,0.5l1.6-0.3l4.9-2.3l1.6-1.3l1.6-0.3l1.5-0.8l3.9-2.5L609.2,666z M427.1,687.2l-2.7,0.5l-0.8-0.5l0.8-0.9l1.6-0.6
      l0.5-0.1l0.7,0.9L427.1,687.2z"
      />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="ukraine"
        d="M570.5,575.3l-0.4,0.2l-4.1-0.3l-3.3,0.4l-2.4,2.7l-1.4,0l-2,0.7l-1.4,0.9l-1.6,1.9l-1.2-0.8
      l-1.5,0l-1.5,0.5l-1.8,1.3l-1,0.2l-2-0.4l-2.3,0.7l-5,4.1l-1.7,3l-0.6,0.6l-0.8,0.7l-0.9,0.4l-0.5,0l2.4-2.1l0.7-0.8l0.1-0.6l0-1
      l-0.7-1.2l-2,2.9l-1.1,0.4l-1.4,0.9l-0.1,1.9l0.2,1.4l0.6,1.8l1.4,3l2.8,4.2l1.3,1.5l1,0.6l1.2,0.1l2.3-1.3l1-0.2l2.1,0.5l0.8-0.9
      l1.1-0.5l1.4-0.1l1.6,0.4l1.7,0.7l-0.7,1.5l-0.7,1.2l-0.3,1.3l-0.4,1.5l-1.9,0.6l-2.1-0.1l-2.2,0.4l-0.8-0.6l-0.5-0.5l-1-0.5
      l-1.3-0.3l-1.1,0.3l-1.3,2l-2.4,1.3l-0.8,1.5l-2.3-0.3l-2,0.3l-2.9,1.4l-2.2,3l-2.4,1.9l-1.9,0.6l-1.8-0.2l-1.2-0.6l-2.4-2l0.1-0.7
      l0.3-0.4l0.5-1l1-3.7l-0.1-1.2l-0.5-1.9l-1.9-1.5l-1.5,0.3l-0.9-0.4l-3.1-2.5l-1.7-0.2l-1.9,0.5l-0.7-0.4l-0.5-0.9l3.7-3.1l3.7-2.6
      l1.6-0.3l2.2-1.2l2.3-1.8l-0.3-1.4l-0.5-1.1l-1.1,0.3l-0.8,0.4l-1.9-1.1l-0.7-0.8l-3.1,0.9l-1.7-0.1l-3.7,0.8l-1.7-0.8l-3.4-2.2
      l-1.3-0.4l-1.1,0.1l-0.6-0.7l0.7-0.4l0.9,0l0.9-0.3l0.2-0.4l-0.1-0.7L496,586l-1.7-0.2l-1.1-0.6l-0.8-0.7l1.9,0l1.9,0.6l3,0.2
      l2.7,0.6l0.7-0.7l1.6-1.3l0.3-0.4l-2.6,0.9l-2.7-0.5l-1-0.8l-0.8-1.1l-0.3-1.3l0.2-1.2l-0.3-2.2l-0.9-1.9l-0.3-1.1l-0.9-1l0.9,2.2
      l0.3,1.4l0.5,1.3l-0.1,3.5l-0.3,1.2l-1.1,0.3l-1.4-0.2l-1.5-0.4l0.4-1.9l-0.8,0.7l-1.1,1.9l-1,0.3l-2.2-0.2l-4,1.2l-0.3,1.3
      l-0.6,1.8l-0.6,1l-0.2,0.6l-1.7,2.7l-0.2,0.3l-3.2,3.7l-0.4,0.3l-2.1,0.9l-1.2,0.8l-0.9,0.3l-1.6-0.4l-0.7,0.5l-0.3,0.7l0,1.4l0.8,1
      l0.7,3.3l-0.2,1.4l-0.6-0.9l-1-0.9l-1.9-0.8l-2.1,0.3l-2.3,1.4l-1.6,0.5l-0.8-0.3l-0.5,0l-0.2,0.4l0,0.3l0.2,0.2l0,0.2l-0.3,0.1
      l-3.6-1l-1.6-0.9l-1.2-1.7l0.6-0.6l0.5-0.2l1.9-0.1l0.3-0.2l0-0.4l0.1-0.5l0-0.8l-0.2-0.8l0.8-1.2l1.2-1l0.7-1l0.1-1.4l1.3-0.7
      l1.1-1.2l0.3-1.3l0.4-0.8l-0.7-1.9l-0.2-1.2l0-1l0.4-0.6l1.1-0.7l1.1-0.5l0.5,0.1l0.2,0.3l0,2.1l0.2,0l0.4-0.3l0.6-1.2l0.4,0.4
      l0.6,0.1l0.5-0.3l0.3,0l0.4,0.3l0.8,0.5l0.7,0.2l0.6-0.3l0.5-0.5l0.5,0.2l1,1.5l0.5-0.2l2.3-0.3l0.4-0.4l0.3-0.4l-2.4-2l0.1-1.5
      l0.1-1.7l-0.3-1l-0.5-0.8l-1.9-0.9l-1.4-1l-0.3-0.4l0-0.6l-0.1-0.9l-0.6-0.7l0-0.6l0.4-1l0.1-1l-0.1-0.4l-0.3-0.3l-0.6,0l-0.8-0.6
      l-0.6-0.8l-1.6-1.2l-0.5-0.2l-0.3-0.6l-0.1-0.7l0.3-0.9l0.4-1.3l0.3-1.3l0-0.8l-0.2-1.9l-0.8-1.4l-0.4-0.2l-0.7,0.4l-0.7,0.3
      l-0.6-0.3l-0.7-0.8l-1.1-2.2l-2-0.4l-0.8-0.1l-0.8,1l-0.2-0.3l-0.2-0.7l-0.4-0.3l-0.6,0.3l-0.2-0.3l0.2-0.9l-0.7-0.4l-1.5,0
      l-0.8-0.3l-0.1-0.7l-0.5-0.4l-0.9-0.2l-0.9-0.6l-0.8-0.9l-1.2-0.6l-1.6-0.3l-0.1-0.1l-0.2-0.1l-1.1,0.6l-0.6,0.5l-0.8-0.3l-1.3,1.1
      l-2.6,0.1l-1.3-0.1l-0.6-0.3l-2.4,1.6l-0.3,0.6l-0.5,0.2l-1.5,0.3l-1.6,0.5l-0.3,1.6l-0.5,0.9l-0.9,1.2l-3,0.4l-2.6,0.6l-2.6,0.4
      l-3.5,1.5l-1.1,1.3l-1.1,0.4l-1,0.1l-0.7-0.7l-2.2-2l-0.8-0.9l-1.1-0.3l-1.2,0.2l-1.2,0.5l-1.2,0.1l-1.4-0.7l-0.1,0.1l-0.5,0.1
      l-3.4-0.8l-0.3-0.1l-0.1,0l-0.5-0.1l-2.6,0.1l-2.4-1.6l-0.7,0l-0.6,0.7l-0.4,0.7l-1.7,0.7l-0.4,0.3l-0.2-0.2l-0.1-0.6l0.1-0.6
      l-0.2-0.5l-0.6-0.6l-0.2-0.3l-0.8,0l-0.2,0.1l-0.1,0l-1.1-0.5l-0.7-1.2l-1.1-0.7l-0.9-0.2l-0.4-0.5L384,554l-0.3-0.5l0-0.1l-0.2-0.8
      l-0.3-0.1l0,0l-1.1,0.1l0.1-2.9l1.8-2.1l0.4-1l0.7-2.3l0.5-1.1l0.6-0.9l0.5-0.9l0.2-0.7l0.5-0.1l1.4,0.7l1.3,0.3l0.4-0.3l0.1-0.4
      l-0.1-0.3l-1-1l-0.6-0.6l0-0.4l0.2-0.9l0.1-1l-0.1-1l-0.7-2.3l-0.1-1l0.7-1.2l2.2-2.9l0.7-1.1l1-1.3l2.7-3.1l1.7-1.8l1.1-1l1.7-1.8
      l0.7-0.9l3.1-0.6l0.4-0.9l0.6-0.9l0.4-0.4l0.1-1.6l-0.6-1.9l-0.5-0.7l-0.3-0.5l0.2-0.4l0.3-0.1l0.4-0.1l0.5-0.5l-0.1-0.5l-1.3-1.2
      l-0.6-1l-0.9-2.5l-1.8-2.6l-0.6-0.8l-0.1-0.8l0.3-0.8l-0.2-1l-0.6-1.3l0.1-0.1l-0.1-1.6l0.4-0.3l0.7-0.2l1,0.1l0.9,0.2l1,0.7
      l0.3-0.1l1.7-1.4l1.8-2.1l0.5-1.2l0.4-0.5l1.6-0.3l1.4-0.1l0.9,0l2.1-0.2l1.3-0.2l1.1-0.4l2.4-0.1l3.7,0.2l2.4,0l1.6,0.2l4,1.1
      l1.5,0.2l0.7,0.6l1.3,0.2l2.4,0.6l2.1,0.3l1.4-0.1l0.8,0.2l1.5,2.6l0.3,0.3l0.6,0.1l1.2-0.2l1.7,0.1l1,0.5l-0.2,1.5l0.3,0.2l0.5-0.1
      l0.6-0.9l0.5-0.9l0.3-0.3l1.8,0.6l0.8-0.1l0.8-0.7l0.5-0.1l1.3,0.5l1.6,0.3l1.3,0l0.8,0.4l0.6,1.6l0.5,0.3l0.5,0.1l0.7-1.4l0.7-0.6
      l0.9-0.4l0.6-0.2l0.4-0.5l0.6-0.5l0.5,0l0.4,0.2l0.5,0.7l0.7,1.6l0.8,1.6l0.6,0.6l1.4-0.5l1-0.5l1.8-0.1l2.4-0.3l1.8-0.4l1.1,0.1
      l0.7,0.5l1,1l0.3,1.4l1.4,1l1.1,0.2l0.4-1l0.7-0.7l-0.2-1l-0.1-1.2l-0.5-1.1l-0.3-1.2l0.6-1.7l0.7-1.5l0.3-0.8l1-1.5l1.1-1.1
      l1.6-1.8l1.2-0.6l1,0.3l0.6,0.2l1.5-1l2.7-0.1l2.2,0.1l0.2,0l1.1,0.5l1.1,0.5l0.8,0l0.9-0.1l1.1-0.6l0.8-0.6l0.9-3l0.3-0.4l0.5-0.2
      l0.8,0l1.6,0.6l1.9,0.5l1.1-0.1l2.9-1.6l1.6-0.2l1.9,0.4l1.9,0l1.4-0.2l1,0.6l1.2,1.2l1.1,1.8l1.1,3.3l3.3,3.8l0.1,0.7l-0.3,0.5
      L524,493l-1.4,0.2l-0.1,0.6l0.4,0.7l0.6,1l0.1,1.3l0,1.2l0.3,1.1l0.5,0.4l0.1,0.5l-0.6,0.6l-0.2,0.4l0.2,0.2l3,0.1l1.5,0.6l1.1,0.6
      l0.6,0.1l1.3-0.4l1.4-0.2l0.9,0l0.3,0.4l0.3,1.1l0.5,1.1l0.5,0.3l0.8-0.1l0.5,0.1l0.3,0.4l-0.3,0.6l0.1,0.7l0.4,0.8l0.4,1.9l0.4,0.6
      l0.3,0.7v0.8l-0.3,0.7l-0.2,0.6l0.2,1.3l0.9,1.5l0.7,0.4l0.5,1.3l1,0.4l1.4-1.1l1.1-0.6l1.4,0.3l1.3,0.2l0.9,0.7l0.6,1l0.7,0.6
      l0.7-0.3l1.5,0.3l0.7,0.8l0.7,0.5l0.9-0.7l0.7-0.8l2.7-0.9l1.7-0.2l0.5-0.2l1-0.6l1-0.4l0.9,0.1l0.9,1.3l1,0.9l0.3,1.4l1.2,1.9
      l2.9,2.6l1.1,0.8l0.8-0.1l0.4-0.2l0.2-0.3l0.2-1.3l0.4-0.5l0.6,0l2.3,1.6l1.2,0.2l1.1,0l1.5,1.2l1.7,1.1l1.3,0.1l1-0.4l0.7-0.3
      l0.4,0.4l0.4,0.9l0.7,0.7l0.8,0.2l1.1,0l1.9,1.4l1.8,1.4l1.1,0.1l1-0.4l0.8-0.1l0.6,0.4l0.2,0.6l-0.4,0.8v1.2l0.8,1.1l0,1.1l-0.2,1
      l-0.5,0.9l-1.1,1.3l-1,1.2l-1.5,0.5l-0.9,0.5l0.2,0.9l0.6,0.8l1.3,0.6l1.5,0.5l0.2,0.5l-0.2,0.3l-0.9,0.2l-1.3-0.2l-0.4,0.5
      l-0.6,0.8l-0.4,1.4l-0.3,1.3l1.4,0.3l0.8,0.5l0.3,1l0.3,1.1l0.1,1l-0.5,0.5l0,0.5l0.2,0.3l0.6,0.1l0.5,0.2l0,0.5l-0.9,1.2l-0.8,2.3
      l-0.4,1.2l0,1.3l-0.5,0.7l-0.9,0.1l-3.1,0.1l-2.7-0.1l-1.2-0.2l-1.8-0.1l-0.9,0.3l-1.2,2.1l-0.9,0.8l-1.5,0.8l-1.7,0.2l-1,0.9
      l-0.3,1.4l0,1.2l-0.2,0.6l-0.4,0.5l-0.1,0.4l0.1,0.4l0.4,0.2l0.5,0.2v0.3l-0.2,0.4l-0.5,0.4l-0.2,0.6l0.1,0.7L570.5,575.3z
      M497.9,590.4l1.6,0.8l-1.6-0.2l-3.6-0.8l-1.6-0.7l-0.4-0.8l-0.2-1.1l0.9,1.2l0.6,0.5L497.9,590.4z"
      />
      <path fill="#333333" stroke="#F4F4F4" strokeWidth="0.560223" d="M268.5,660.1l-0.1,0l0-0.1l0-0.1l0.1,0L268.5,660.1z" />
      <path
        fill="#333333"
        stroke="#F4F4F4"
        strokeWidth="0.560223"
        id="hungary"
        d="M382.1,552.6l1.1-0.1l0,0l0.3,0.1l0.2,0.8l0,0.1l0.3,0.5l0.2,0.7l0.4,0.5l0.9,0.2l1.1,0.7l0.7,1.2
      l1.1,0.5l0.1,0l0.2-0.1l0.8,0l0.2,0.2l0.6,0.6l0.2,0.5l-0.1,0.6l0.1,0.6l0.2,0.2l-0.3,0.4l-2.1,2.1l-0.8,0.6l-0.5,0.1l-0.8-0.2
      l-0.9,0.2l-0.8,0.5l-0.7,0.1l-0.5,0.5l-0.7,1.2l-0.9,1l-0.9,0.6l-0.5,0.5l0,1.9l-0.5,0.5l-0.6,0.5l-0.4,0.5l-1,2.9l-0.7,0.9
      l-0.7,0.7l-0.1,0.6l0,0.7l-0.8,1.5l-1.1,1.5l-0.2,0.6l0.2,0.8l-1,1l-0.6,0.5l-0.5,0.2l-0.3,0.6l-0.5,1.5l0.1,0.6l0,0.6l-0.9,0.4
      l-0.3,0.7l-0.2,0.8l-0.4,0.4l-1,0.7l-2.4-0.3l-0.9,0.2l-0.3,0.5l-0.1,0.4l-0.3,0.4l-0.5,0.5l-0.6,0.2L363,591l-2.7,0.6l-0.5,0.4
      l-0.4-0.3l-0.6-0.3l-2.7-0.3l-1.1,0.3l-1.4-0.1l-1.3-0.3l-1,0.2l-0.9,1.1l-0.4,0.4L350,593l-0.7,0.4l-0.6,0.4l-0.8,0.3l-0.7,0
      l-0.7-0.5l-0.2,0.1l-0.2,0.5l-0.4,0.4l-1,0.5l-0.3,0h-0.1l-0.8,0.3l-1.3,0.2l-0.7-0.1l-1.2,1.6l-0.4,0.3l-1.1,0.5l-0.9,0.2l-0.8-0.2
      l-0.3,0l-3.5-0.1l-1.8-0.3l-1.2-0.6l-0.8-0.7l-0.4-0.8l-0.9-0.5l-1.4-0.2l-1.1-0.8l-0.8-1.3l-1.1-1.1l-1.4-0.8l-1.1-1.1l-0.8-1.4
      l-1.4-1.3l-2.1-1.2l-0.6-0.3l-0.1-0.4l-1-1.4l-0.4-0.5l0-0.7l-0.2-0.4l-0.4-0.3l-0.2-1l-0.1-0.8l-0.3-0.5l-2.2-0.1l1.9-1.8l0.9-0.5
      l1.1,0.1l0.3-0.2l0.1-0.3l0.2-0.6l0.1-0.6l0.1-0.5l-0.1-0.3l-0.5-0.1l-0.2-1.3l0.3-0.5l0.3-0.3l-0.3-1.6l0.1-0.6l0.8-0.1l0.7-0.3
      l0.6-0.4l0.2-0.5l0.5-1l-0.4-1.3l-2.4-0.8l-0.1-0.3l0.6-0.4l0.6-0.5l0.4-0.4l0.5-0.1l0.7,0.2l1.2,0.9l0.4,0.1l0.4-0.3l0.5-0.1l1.3,0
      l1.1-0.2l-0.2-1l0-0.7l-0.2-0.6l0.1-0.6l0.4-0.5l0.1-1.1l0.7-0.7l0.3-0.1l1.2,0.1l0.3,0.2l0.2,0l1.9,1.8l1.8,1.3l1.5,0.7l2.2,0.1
      l2.3,0.1l3.9-0.2l2.9-0.2l0.2-0.3l0.4-0.8l-0.4-0.7l0-0.8l0.5-1.1l1.4-0.9l4.1-0.4l2.4-0.7l0.4-0.9l0.8-0.9l0.7-0.2l1,0.4l1.2,0.8
      l1,0.4l0.6-0.3l2.1-1.3l2.4-1.3l1.7-3.5l0.2-0.6l1.8-0.4l2.6,0.1l1.3,0.5l1,0.2l1.5-0.1l2.2-0.8l0.8,0l0.6,0.5l0.7,0.5l0.5,0.6
      l0.4,0.8l0.2,0.3l0.3,0.4l0.6,0.6l0.5,0.1l4-1L382.1,552.6z"
      />
    </svg>
  );
};

export default EuropeMapSvg;
