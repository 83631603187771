import React from 'react';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { Box, Flex, Image } from 'rebass';
import { theme } from '../../../layouts/main.layout';
import { object, string } from 'yup';
import Dialog from '../../functional/SweetAlert';
import * as Sentry from '@sentry/browser';
import { CustomErrorMessage } from '../../functional/CustomErrorMessage';
import { COUNTRY } from '../../../configs/env';
import { VozziPrimaryButton } from '../_/button.component';
import { Modal } from '../Modal/Modal.component';
import './styles.css';

import { buttonStyle, inputStyle, inputStyleExperience } from './FaqSendQuestionForm.css';

import { useTranslation } from 'react-i18next';

const FaqSendQuestionForm = ({ show, onHide }): React.ReactElement => {
  const { t } = useTranslation();

  const initialValues = {
    question: '',
    email: '',
    country: COUNTRY,
  };

  const validationSchema = object().shape({
    question: string().required(t('validation_Question')),
    email: string().email(t('validation_ValidEmail')).required(t('validation_Email')),
  });

  const handleSubmit = async (data: any) => {
    try {
      const response = await axios.post('/contact-us', data);
      if (response.status === 200 || response.status === 204) {
        Dialog.fire({
          title: t('response_SuccessSendQuestion'),
          icon: 'success',
          confirmButtonColor: theme.colors.vozziVividOrange,
        });
      }
    } catch (err) {
      Sentry.captureException(err);
      Dialog.fire({
        title: t('response_Error'),
        icon: 'error',
        confirmButtonColor: theme.colors.vozziVividOrange,
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Box flex={3} ml={{ _: 0, md: 5 }} mt={{ _: 4, md: 0 }} mx="auto" width={{ _: '93%', md: '100%' }}>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
          {({ isValid }) => (
            <Form className="form-faq-send-question" style={{ backgroundColor: 'white', borderRadius: 22, padding: 20 }}>
              <Flex justifyContent="flex-end" alignItems="center">
                <Box onClick={onHide} style={{ cursor: 'pointer' }}>
                  <Image style={{ height: 20 }} src="/new-images/close.png" />
                </Box>
              </Flex>
              <Flex flexDirection="column" justifyContent="center" mt={{ _: 'initial', md: 'initial' }} width={{ _: '100%', md: '100%' }}>
                <Field
                  css={inputStyleExperience}
                  component="textarea"
                  name="question"
                  placeholder={t('placeholder_AskQuestion')}
                  rows={8}
                />
                <CustomErrorMessage name="question" />

                <Field css={inputStyle} name="email" placeholder={t('placeholder_Email')} />
                <CustomErrorMessage name="email" />

                <VozziPrimaryButton css={buttonStyle} mt={3} type="submit" disabled={!isValid}>
                  {t('btn_Send')}
                </VozziPrimaryButton>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default FaqSendQuestionForm;
