import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { gsap } from 'gsap/all';
import { Img, Text, Wrapper } from '../../../global/new-global-components';
import { useTranslation } from 'react-i18next';

type Point = {
  title: string;
  descriptionFirstRow: string;
  descriptionSecondRow: string;
};

export default function YearSlider() {
  const slider1 = useRef<any>(null);
  const slider2 = useRef(null);
  const [nav1, setNav1] = useState<any>(null);
  const [nav2, setNav2] = useState<any>(null);
  const [currentCountries, setCurrentContries] = useState(null);

  const { t } = useTranslation();

  const points = [
    {
      countries: ['serbia'],
      year: 2018,
      title: t('home_TimelineSection2018Heading'),
      descriptionFirstRow: t('home_TimelineSection2018Country'),
      descriptionSecondRow: t('home_TimelineSection2018Text'),
    },
    {
      countries: ['serbia', 'montenegro', 'north-macedonia', 'greece', 'bosnia-and-herzegovina', 'bulgaria', 'croatia'],
      year: 2019,
      title: t('home_TimelineSection2019Heading'),
      descriptionFirstRow: t('home_TimelineSection2019Countries'),
      descriptionSecondRow: t('home_TimelineSection2019Text'),
    },
    {
      countries: [
        'serbia',
        'montenegro',
        'north-macedonia',
        'greece',
        'bosnia-and-herzegovina',
        'bulgaria',
        'croatia',
        'albania',
        'slovenia',
      ],
      year: 2020,
      title: t('home_TimelineSection2020Heading'),
      descriptionFirstRow: t('home_TimelineSection2020Countries'),
      descriptionSecondRow: t('home_TimelineSection2020Text'),
    },
    {
      countries: [
        'serbia',
        'montenegro',
        'north-macedonia',
        'greece',
        'bosnia-and-herzegovina',
        'bulgaria',
        'croatia',
        'albania',
        'slovenia',
        'romania',
        'hungary',
      ],
      year: 2021,
      title: t('home_TimelineSection2021Heading'),
      descriptionFirstRow: t('home_TimelineSection2021Countries'),
      descriptionSecondRow: t('home_TimelineSection2021Text'),
    },
    {
      countries: [
        'serbia',
        'montenegro',
        'north-macedonia',
        'greece',
        'bosnia-and-herzegovina',
        'bulgaria',
        'croatia',
        'albania',
        'slovenia',
        'romania',
        'hungary',
        'poland',
        'czech-republic',
        'slovakia',
      ],
      year: 2022,
      title: t('home_TimelineSection2022Heading'),
      descriptionFirstRow: t('home_TimelineSection2022Countries'),
      descriptionSecondRow: t('home_TimelineSection2022Text'),
    },
    {
      countries: [
        'serbia',
        'montenegro',
        'north-macedonia',
        'greece',
        'bosnia-and-herzegovina',
        'bulgaria',
        'croatia',
        'albania',
        'slovenia',
        'romania',
        'hungary',
        'poland',
        'czech-republic',
        'slovakia',
        'italy',
        'france',
        'belgium',
        'luxembourg',
        'spain',
        'netherlands',
        'turkey',
        'cyprus',
      ],
      year: 2023,
      title: t('home_TimelineSection2023Heading'),
      descriptionFirstRow: t('home_TimelineSection2023Countries'),
      descriptionSecondRow: t('home_TimelineSection2023Text'),
    },
    {
      countries: [
        'serbia',
        'montenegro',
        'north-macedonia',
        'greece',
        'bosnia-and-herzegovina',
        'bulgaria',
        'croatia',
        'albania',
        'slovenia',
        'romania',
        'hungary',
        'poland',
        'czech-republic',
        'slovakia',
        'italy',
        'switzerland',
        'france',
        'belgium',
        'luxembourg',
        'spain',
        'netherlands',
        'turkey',
        'cyprus',
        'portugal',
        'england',
        'ireland',
        'germany',
        'austria',
      ],
      year: 2024,
      title: t('home_TimelineSection2024Heading'),
      descriptionFirstRow: t('home_TimelineSection2024Countries'),
      descriptionSecondRow: t('home_TimelineSection2024Text'),
    },
    {
      countries: [
        'serbia',
        'montenegro',
        'north-macedonia',
        'greece',
        'bosnia-and-herzegovina',
        'bulgaria',
        'croatia',
        'albania',
        'slovenia',
        'romania',
        'hungary',
        'poland',
        'czech-republic',
        'slovakia',
        'italy',
        'switzerland',
        'france',
        'belgium',
        'luxembourg',
        'spain',
        'netherlands',
        'turkey',
        'cyprus',
        'portugal',
        'england',
        'ireland',
        'germany',
        'austria',
        'ukraine',
        'belarus',
        'lithuania',
        'latvia',
        'estonia',
        'denmark',
        'norway',
        'sweden',
        'finland',
        'moldavia',
      ],
      year: 2025,
      title: t('home_TimelineSection2025Heading'),
      descriptionFirstRow: t('home_TimelineSection2025Countries'),
      descriptionSecondRow: t('home_TimelineSection2025Text'),
    },
  ];

  // reset all filled countries in europe map
  const resetSvgFill = () => {
    document.getElementById('europe-svg').style.transition = 'ease 1s';
    if (window.innerWidth > 768) {
      // document.getElementById('europe-svg').style.transform = 'translateY(0px) ';
    }
    const childPaths = document.getElementById('europe-svg')?.childNodes;
    childPaths?.forEach((path: any) => {
      path.setAttribute('fill', '#333333');
    });
  };

  useEffect(() => {
    gsap.set('.btn-ruler', { xPercent: -50, yPercent: -50 });

    const yearSliderWrapper = document.getElementById('year-slider-wrapper');
    const secondYearSliderMouseCursor = document.getElementById('second-year-slider');
    const btnRuler = document.querySelector('.btn-ruler');
    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    const mouse = { x: pos.x, y: pos.y };
    const speed = 0.2;

    const xSet = gsap.quickSetter(btnRuler, 'x', 'px');
    const ySet = gsap.quickSetter(btnRuler, 'y', 'px');

    const debounce = (func: any, delay: any) => {
      let inDebounce: any;
      return () => {
        const context = this;
        const args = arguments;
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => func.apply(context, args), delay);
      };
    };

    // document.getElementById('serbia')?.setAttribute('fill', '#F57D20');

    secondYearSliderMouseCursor.addEventListener('mousemove', (e) => {
      btnRuler.style.display = 'block';
      mouse.x = e.x;
      mouse.y = e.y;
    });

    secondYearSliderMouseCursor.addEventListener('pointerleave', (e) => {
      btnRuler.style.display = 'none';
    });

    let blocked: any = false;
    let blockTimeout: any = null;
    let prevDeltaY: any = 0;

    secondYearSliderMouseCursor.addEventListener('wheel', (e) => {
      e.preventDefault();
      e.stopPropagation();
      let deltaY: any = e.deltaY;

      clearTimeout(blockTimeout);
      blockTimeout = setTimeout(function () {
        blocked = false;
      }, 160);

      if ((deltaY > 0 && deltaY > prevDeltaY) || (deltaY < 0 && deltaY < prevDeltaY) || !blocked) {
        blocked = true;
        prevDeltaY = deltaY;
        if (e.deltaY < 0) {
          slider2!.current?.slickPrev();
        }
        slider2!.current?.slickNext();
      }
    });

    gsap.ticker.add(() => {
      const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

      pos.x += (mouse.x - pos.x) * dt;
      pos.y += (mouse.y - pos.y) * dt;
      xSet(pos.x);
      ySet(pos.y);
    });
  }, []);

  useEffect(() => {
    ['serbia'].forEach((country: string) => {
      document.getElementById(country).style.transition = 'fill 0.4s ease';
      document.getElementById(country)?.setAttribute('fill', '#F57D20');
    });
  }, []);

  useEffect(() => {
    if (currentCountries && currentCountries.length) {
      resetSvgFill();
      currentCountries.forEach((country: string) => {
        document.getElementById(country).style.transition = 'fill 0.4s ease';
        document.getElementById(country)?.setAttribute('fill', '#F57D20');
      });
      if (window.innerWidth > 768) {
        // document.getElementById('europe-svg').style.transform = 'translateY(0px)';
      }
    }
  }, [currentCountries]);

  return (
    <Wrapper>
      <Wrapper className="first-year-slider">
        <Slider
          afterChange={(currentSlideIndex: any) => setCurrentContries(points[currentSlideIndex].countries)}
          asNavFor={nav2}
          ref={(slider1: any) => setNav1(slider1)}
          swipe={false}
          swipeToSlide={false}
          initialSlide={0}
          touchMove={false}
          touchThreshold={35}
          arrows={false}
          slidesToShow={1}
          slidesToScroll={1}
          infinite={true}
          fade={true}
        >
          {points &&
            points.map((point: Point, index) => (
              <Wrapper key={index}>
                <Wrapper className="point" padding="15px">
                  <Wrapper className="slide-wrapper">
                    <Img className="img" src="/new-images/vozzi-small-logo.png" />
                    <Text className="point-title">{point.title}</Text>
                  </Wrapper>
                  <h4>{point.descriptionFirstRow}</h4>
                  <Text className="point-text">{point.descriptionSecondRow}</Text>
                </Wrapper>
              </Wrapper>
            ))}
        </Slider>
      </Wrapper>

      <Wrapper id="second-year-slider" className="second-year-slider">
        <img className="btn-ruler" src="/new-images/ruler.png" alt="cursor" />

        <Slider
          asNavFor={nav1}
          ref={slider2}
          slidesToShow={7}
          initialSlide={0}
          touchMove={true}
          touchThreshold={35}
          slidesToScroll={1}
          infinite={true}
          variableWidth={false}
          focusOnSelect={false}
          arrows={false}
          responsive={[
            // {
            //   breakpoint: 1025,
            //   settings: {
            //     slidesToShow: 2,
            //     slidesToScroll: 1,
            //   },
            // },
            {
              breakpoint: 769,
              settings: {
                centerMode: true,
                slidesToShow: 4,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {points &&
            points.map((point, index) => (
              <Wrapper key={index} className="year-slide-wrapper" width="150px">
                <Text className="year-slide-inner">{point.year}</Text>
                <Img src="/vectors/ruler.svg" />
              </Wrapper>
            ))}
        </Slider>
      </Wrapper>
    </Wrapper>
  );
}
